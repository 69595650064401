import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { toGraphKey } from "../changeLogConfig";
import formatDate from "utils/format-date";
import Card from "../../card";
import { deleteChangeLogEntry } from "services/apiService";
import {
  ActiveFilter,
  ChangeDetails,
  Changes,
  List,
} from "../changeLog.styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ChangeList = ({
  changes,
  filters,
  onFilterRemove,
  setLoading,
  setEntries,
}) => {
  let [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(0);
  }, [changes]);

  let filteredChanges = changes.filter(
    (change) =>
      filters.length === 0 || filters.includes(toGraphKey(change.created_at))
  );

  let details = filteredChanges.length > 0 ? filteredChanges[selected] : {};

  const findIndexOfEntry = (change) =>
    change.uuid === filteredChanges[selected].uuid;
  const handleDeleteEntry = async () => {
    const deleteEntry = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (deleteEntry) {
      setLoading(true);
      try {
        await deleteChangeLogEntry(filteredChanges[selected].uuid);
        changes.splice(changes.findIndex(findIndexOfEntry), 1);
        setEntries(changes);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };
  return (
    <Card>
      <Card.Heading>
        <Card.SubHeading>
          <Card.Title>
            Showing {filteredChanges.length} Results ({changes.length} Total)
          </Card.Title>
          {filters.map((filter) => (
            <ActiveChangeFilter
              name={filter}
              onRemove={() => onFilterRemove(filter)}
              key={filter}
            />
          ))}
        </Card.SubHeading>
      </Card.Heading>
      <List>
        <Changes>
          {filteredChanges.map((change, index) => (
            <ChangeListItem
              {...change}
              key={change.uuid}
              selected={selected === index}
              onClick={() => setSelected(index)}
            />
          ))}
        </Changes>
        <ChangeDetails>
          <p className="date">{formatDate(details.created_at)}</p>
          <h3 className="name">{details.title}</h3>
          <p className="detail-row">
            <span className="detail-row__heading">Group: </span>
            <span>{details.change_group}</span>
          </p>
          <p className="detail-row">
            <span className="detail-row__heading">KPI: </span>
            <span>{details.kpi_type}</span>
          </p>
          <p className="detail-row">
            <span className="detail-row__heading">Added By: </span>
            <span>{details.owner}</span>
          </p>
          <p className="detail-row">
            <span className="detail-row__heading">Notes — </span>
          </p>
          <p className="detail-row notes">{details.notes}</p>
          <button
            className="btn btn-alert delete-button"
            onClick={handleDeleteEntry}
          >
            Delete Entry
          </button>
        </ChangeDetails>
      </List>
    </Card>
  );
};

const ChangeListItem = ({ created_at, title, selected, onClick }) => (
  <div
    className={`change-list-item ${selected ? "active" : ""}`}
    onClick={onClick}
  >
    <p className="date">{formatDate(created_at)}</p>
    <h4 className="name">{title}</h4>
  </div>
);

const ActiveChangeFilter = ({ name, onRemove }) => {
  return (
    <ActiveFilter>
      {name}
      <FontAwesomeIcon icon={faTimes as IconProp} onClick={onRemove} />
    </ActiveFilter>
  );
};

export default ChangeList;
