import React, { useEffect, useState } from "react";
import { BubbleRowWrapper } from "./styles";
import { DataBubbleRow } from "../../components/dataBubbleRow";
import { useBusinessMappings } from "hooks/useBusinessMappings";

import {
  Dependency,
  serializeDateRange,
} from "../../hooks/globalContext/globalContext";
import { useGlobalAnalytics } from "../../hooks/useGlobalAnalytics";
import { Filters } from "../../hooks/globalContext";
import {
  DateRange,
  DateRangeConfig,
  dateRangeFromConfig,
} from "../../utils/dateRange";
import { DataBubbleProps } from "../../components/dataBubble/dataBubble";
import { useAnalyticsFormatting } from "hooks/useAnalyticsFormatting";
import DataBubbleRowPlaceholder from "components/dataBubbleRow/dataBubbleRowPlaceholder";

export type BubbleRowProps = {
  config: {
    title: string;
    subtitle: string;
    bubbles: {
      analyticType: string;
      backgroundColor: string;
      fontColor: string;
    }[];
    dateRangeConfig: DateRangeConfig;
    filters: Filters;
    shouldInheritPageFilters: boolean;
  };
  loading: boolean;
  pageFilters: Filters;
  key: number;
};
export const BubbleRow: React.FC<BubbleRowProps> = (props) => {
  const [filters, setFilters] = useState(
    props.config.shouldInheritPageFilters
      ? props.pageFilters
      : props.config.filters
  );
  useEffect(() => {
    if (props.config.shouldInheritPageFilters) {
      setFilters(props.pageFilters);
    }
  }, [props.pageFilters, props.config.shouldInheritPageFilters]);

  const [dateRange] = useState<DateRange>(
    dateRangeFromConfig(props.config.dateRangeConfig)
  );

  const allDependencies = getAllDependencies(
    props.config.bubbles,
    dateRange,
    filters
  );
  const {
    loading: loadingDependencies,
    dependencyData: nestedDependencies,
    dependencies,
    setDependencies,
    setCanFetch,
  } = useGlobalAnalytics({
    dependencies: allDependencies,
    dateRange: dateRange,
    filters: props.config.filters,
  });
  const [dependencyData, setDependencyData] = useState(
    nestedDependencies[serializeDateRange(dateRange)] || {}
  );

  useEffect(() => {
    const currentDateRangeKey = serializeDateRange(dateRange);
    if (!(props.loading || loadingDependencies)) {
      setDependencyData(nestedDependencies[currentDateRangeKey] || {});
    }
  }, [nestedDependencies, props.loading, loadingDependencies, dateRange]);

  useEffect(() => {
    if (!props.loading) {
      setCanFetch(true);
    } else {
      setCanFetch(false);
    }
  }, [props.loading, setCanFetch]);

  const mapTerms = useBusinessMappings();
  const format = useAnalyticsFormatting();

  const bubbles: DataBubbleProps[] = props.config.bubbles.map((bubble) => {
    return {
      title: mapTerms(bubble.analyticType),
      value: format(
        Object.values(
          dependencyData[bubble.analyticType] || {}
        )?.[0] as unknown as string,
        bubble.analyticType,
        { showEmptyData: true }
      ),
      backgroundColor: bubble.backgroundColor,
      fontColor: bubble.fontColor,
      diameter: "240px",
    };
  });

  useEffect(() => {
    setDependencies(
      dependencies.map((dependency) => {
        return { ...dependency, filters };
      })
    );
    //eslint-disable-next-line
  }, [filters]);

  return (
    <BubbleRowWrapper>
      {!props.loading && !loadingDependencies ? (
        <DataBubbleRow
          rowTitle={props.config.title}
          subTitle={props.config.subtitle}
          bubbles={bubbles}
        />
      ) : (
        <DataBubbleRowPlaceholder
          rowTitle={props.config.title}
          subTitle={props.config.subtitle}
          numRows={props.config.bubbles.length}
        />
      )}
    </BubbleRowWrapper>
  );
};
/**wrapper for the below generator to clean up the function signature */
const getAllDependencies: (
  bubbles: BubbleRowProps["config"]["bubbles"],
  dateRange: DateRange,
  filters: Filters
) => Dependency[] = (bubbles, dateRange, filters) => {
  return bubbles.map((bubble) => ({
    analyticType: bubble.analyticType,
    dateRange,
    filters,
  }));
};
export const Factory = (props: BubbleRowProps) => {
  return {
    Component: ({ key, pageFilters, loading }) =>
      BubbleRow({ ...props, key, pageFilters, loading }),
    getInitialDependencies: () => {
      return getAllDependencies(
        props.config.bubbles,
        dateRangeFromConfig(props.config.dateRangeConfig),
        props.config.shouldInheritPageFilters
          ? props.pageFilters
          : props.config.filters
      );
    },
  };
};
export default Factory;
