import React from "react";
import { DatePickerWrapper } from "./styles";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createDateRange, DateOption, DateRange } from "../../utils/dateRange";
const now = new Date();
const tomorrow = new Date(now.setUTCDate(now.getUTCDate() + 1));
export type CustomDateRangeProps = {
  onInputChange: (range: DateRange) => void;
};
const CustomDateRange: React.FC<CustomDateRangeProps> = ({ onInputChange }) => {
  const initalState = {
    date: null,
    dateOption: null,
    touched: false,
  };
  const [start, setStart] = useState(initalState);
  const [end, setEnd] = useState(initalState);

  const onStartChange = (date) => {
    setStart({ date, touched: !!date, dateOption: dateToDateOption(date) });
  };
  const onEndChange = (date) => {
    setEnd({ date, touched: !!date, dateOption: dateToDateOption(date) });
  };
  useEffect(() => {
    if (start.touched && end.touched) {
      const range = createDateRange(start.dateOption, end.dateOption, "daily");
      onInputChange(range);
    }
    //eslint-disable-next-line
  }, [start, end]);

  return (
    <DatePickerWrapper className="custom-date-range">
      <DatePicker
        selected={start.date}
        onChange={onStartChange}
        maxDate={end.date || new Date()}
        popperPlacement="bottom"
      />
      <span>to</span>
      <DatePicker
        selected={end.date}
        onChange={onEndChange}
        minDate={start.date}
        maxDate={tomorrow}
        popperPlacement="bottom"
      />
    </DatePickerWrapper>
  );
};

const dateToDateOption = (dateToConvert = new Date()) => {
  const today = new Date().setUTCHours(0, 0, 0, 0);
  const date = new Date(dateToConvert).setUTCHours(0, 0, 0, 0);

  const dateDiff = Math.floor((today - date) / (1000 * 3600 * 24));
  const dateOption: DateOption =
    dateDiff > 0 ? `${dateDiff}-days-ago` : "today";

  return dateOption;
};

export default CustomDateRange;
