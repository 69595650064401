import { useMemo } from "react";
import {
  HostNetworkMapKey,
  HostNetworkMapWrapper,
  MapOption,
  MapOptionsWrapper,
  SidebarTotalWrapper,
} from "./styles";
import InteractiveMap from "../interactive-map";
import { useState } from "react";
import useHostNetworkMapData from "./hooks/useHostNetworkMapData";
import useHostNetworkMapLayers from "./hooks/useHostNetworkMapLayers";
import MapSidebar from "components/mapSidebar";
import { Filters as FiltersType } from "hooks/globalContext";
import HostNetworkMapFilters from "./hostNetworkMapFilters";
import { useAnalyticsFormatting } from "hooks/useAnalyticsFormatting";
import Form from "../form";
import { parseDateOption } from "utils/dateRange";
import { countryFilterOptions } from "components/reportingMap/configData";
export type ReportingDataSource = "all" | "gmo" | "default";

type HostNetworkMapProps = {
  config: {
    title: string;
  };
  pageFilters: FiltersType;
  loading: boolean;
};

const defaultStartDate = parseDateOption("1-month-ago").toISOString();
const defaultEndDate = parseDateOption("0-days-ago").toISOString();

const HostNetworkMap: React.FC<HostNetworkMapProps> = ({
  config,
  pageFilters,
  loading,
}) => {
  const [showConnections, setShowConnections] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [startDate, setStartDate] = useState<string>(defaultStartDate);
  const [endDate, setEndDate] = useState<string>(defaultEndDate);
  const [countryFilter, setCountryFilter] = useState(countryFilterOptions);

  const format = useAnalyticsFormatting();

  const countries = useMemo(() => {
    return Object.entries(countryFilter)
      .map(([country, value]) => (value ? country : null))
      .filter((country) => !!country);
  }, [countryFilter]);

  const mapData = useHostNetworkMapData(
    pageFilters,
    loading,
    startDate,
    endDate,
    countries,
  );
  const layers = useHostNetworkMapLayers(mapData.data, showConnections);

  const resetFilters = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setCountryFilter(countryFilterOptions);
  };

  const renderTooltip = ({ object }) => {
    if (!object || !object.name) {
      return null;
    }

    const { name, isConnected } = object;

    const isSeeker = isConnected !== undefined;
    const typeClass = isSeeker && isConnected ? "connected" : "";

    return {
      html: `<div class="tooltip">
               <h5>${name}</h5>
               ${
                 isSeeker
                   ? `<p class="${typeClass}">${
                       isConnected ? "Digitally Connected" : "Not Connected"
                     }</p>`
                   : ""
               }
             </div>`,
      style: {
        backgroundColor: "transparent",
      },
    };
  };

  return (
    <HostNetworkMapWrapper>
      <InteractiveMap
        title={config.title}
        loading={mapData.loading}
        layers={layers}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        renderTooltip={renderTooltip}
      >
        <MapOptionsWrapper>
          <MapOption>
            Show Connections
            <Form.Checkbox
              checked={showConnections}
              onChange={() => setShowConnections(!showConnections)}
            />
          </MapOption>
        </MapOptionsWrapper>
        <HostNetworkMapKey>
          <div className="map-key-item">Host Connection Radius:</div>
          <div className={`item-color gray`} />

          {showConnections && (
            <>
              <div className="map-key-item">Digitally Connected:</div>
              <div className={`item-color orange`} />

              <div className="map-key-item">Not Connected:</div>
              <div className={`item-color blue`} />
            </>
          )}
        </HostNetworkMapKey>
      </InteractiveMap>
      <MapSidebar
        useCustomFilters
        filterOptions={[]}
        currentFilters={{}}
        onFiltersChange={() => {}}
        onFiltersClear={() => {}}
        dashboardMode={false}
        toggleDashboardMode={() => {}}
      >
        <HostNetworkMapFilters
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          countryFilter={countryFilter}
          setCountryFilter={setCountryFilter}
          resetFilters={resetFilters}
        />
        <SidebarTotalWrapper>
          <h3 className="type">Available Hosts</h3>
          <h1 className="total">{format(mapData.data?.hosts.length)}</h1>
        </SidebarTotalWrapper>
        {showConnections && (
          <>
            <SidebarTotalWrapper>
              <h3 className="type">Connectable Seekers</h3>
              <h1 className="total">{format(mapData.data?.seekers.length)}</h1>
            </SidebarTotalWrapper>
            <SidebarTotalWrapper>
              <h3 className="type">Digitally Connected</h3>
              <h1 className="total">
                {format(
                  mapData.data?.seekers.filter((x) => x.isConnected).length
                )}
              </h1>
            </SidebarTotalWrapper>
          </>
        )}
      </MapSidebar>
    </HostNetworkMapWrapper>
  );
};

export default HostNetworkMap;
