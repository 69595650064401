import Button from "components/button/button";
import React, { useEffect, useState } from "react";
import { ColumnMenu, ColumnMenuWrapper } from "./styles";

type MenuProps = {
    defaultColumnHeaders: {
        title: string,
        label?: string,
        tooltip?: string
    }[],
    hiddenColumnIndexes: number[],
    handleHideShowColumns: (hiddenIndexes: number[]) => void
}
const HideShowColumnsMenu = ({
    defaultColumnHeaders,
    hiddenColumnIndexes,
    handleHideShowColumns
}: MenuProps) => {
    const [hiddenIndexes, setHiddenIndexes] = useState(hiddenColumnIndexes);

    const handleCheckboxChange = (index: number) => {
        if (hiddenIndexes.includes(index)) {
            setHiddenIndexes(hiddenIndexes.filter(item => item !== index));
        } else {
            setHiddenIndexes([...hiddenIndexes, index]);
        }
    };

    const updateShownColumns = (e: React.FormEvent) => {
        e.preventDefault();
        handleHideShowColumns(hiddenIndexes);
    };

    useEffect(() => {
        setHiddenIndexes(hiddenColumnIndexes);
    }, [hiddenColumnIndexes])

    return (
        <ColumnMenuWrapper>
            <ColumnMenu>
                <div className="checkboxes">
                  {defaultColumnHeaders.map((col, index) => (
                    <div className="checkbox-value" key={`columnHeader${index}`}>
                      <input 
                        type="checkbox" 
                        checked={!hiddenIndexes.includes(index)} 
                        onChange={() => handleCheckboxChange(index)}
                      />
                      {col.title}
                    </div>
                  ))}
                </div>
                <div>
                    <Button type="link" onClick={updateShownColumns}>Update Columns</Button>
                </div>
            </ColumnMenu>
        </ColumnMenuWrapper>
    );
};

export default HideShowColumnsMenu;
