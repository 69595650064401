import styled from "styled-components";

export const Wrapper = styled.div``;
export const Dropdowns = styled.div`
  display: flex;
  white-space: nowrap;
  gap: ${({ theme }) => theme.utilities.spacing["space-sm"]};

  margin-bottom: ${({ theme }) => theme.utilities.spacing["space-md"]};
`;
