import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  h1 {
    text-align: center;
    color: black;
    margin-bottom: ${({ theme }) => theme.utilities.spacing["space-sm"]};
  }
`;
