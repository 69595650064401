import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin: auto;
  border-radius: 15px;
`;

export const Dropdown = styled.div`
  position: relative;
  width: fit-content;

  select {
    appearance: none;
    background-color: ${({ theme }) => theme?.colors?.["ui-gray-lighter"]};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem 2.5rem 0.6rem 1.25rem;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    color: ${({ theme }) => theme?.colors?.["text-default"]};
    cursor: pointer;
    font-size: 1.125rem;
    outline: none;
    border-radius: ${({ theme }) =>
      theme?.utilities?.borders?.["border-radius-md"]};
    transition: all 0.2s;
    text-align: center;
    line-height: 1.5;
    border: 1px solid ${({ theme }) => theme?.colors?.["text-default"]};
    height: 100%;
  }

  svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    margin-top: 1px;
  }
`;
