import React, { useEffect } from "react";
import { DateRange } from "../../utils/dateRange";
import CustomDateRange from "../custom-date-range";
import Form from "../form";
import { DateRangeWrapper } from "./styles";
import useDateRangeTotal, { RangeKey } from "./useDateRangeTotal";

export type DateRangeDropDownProps = {
  handleDateRangeChange: (currentRange: DateRange, dateRange: any) => void;
  initialRangeKey: RangeKey;
};

export const DateRangeDropDown: React.FC<DateRangeDropDownProps> = ({
  handleDateRangeChange,
  initialRangeKey,
}) => {
  const { currentRange, ...dateRange } = useDateRangeTotal(
    initialRangeKey ?? "12m"
  );

  useEffect(() => {
    handleDateRangeChange(currentRange, dateRange);
  }, [currentRange, handleDateRangeChange]);

  return (
    <DateRangeWrapper>
      <Form.Dropdown
        options={Object.values(dateRange.options)}
        value={dateRange.options[dateRange.activeRangeKey]}
        onChange={dateRange.rangeKeyUpdateHandler}
        mapper={(s) => s}
      />
      {dateRange.isCustom && (
        <CustomDateRange
          onInputChange={dateRange.handleCustomDateRangeChange}
        />
      )}
    </DateRangeWrapper>
  );
};
