import styled from "styled-components";
import { FilterRow } from "../mapSidebar/mapSidebarFilters/styles";

export const HostNetworkMapWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  .tooltip {
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};

    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-sm"]};
    padding: ${({ theme }) => theme?.utilities.spacing["space-xs"]}
      ${({ theme }) => theme?.utilities.spacing["space-sm"]};
    h5 {
      color: ${({ theme }) => theme?.colors["primary-dark"]};
      white-space: nowrap;
    }
    p {
      font-size: 0.65rem;
      opacity: 0.8;
      color: ${({ theme }) => theme?.colors["primary-dark"]};

      &.connected {
        color: ${({ theme }) => theme?.colors["secondary-default"]};
      }
    }
  }
`;

export const HostNetworkMapKey = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  right: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  row-gap: ${({ theme }) => theme?.utilities.spacing["space-xs"]};
  align-items: center;
  justify-items: center;
  background-color: #00000090;
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-md"]};
  transition: 0.3s ease;

  &.sidebar-open {
    right: calc(${({ theme }) => theme?.utilities.spacing["space-lg"]} + 420px);
  }

  .map-key-item {
    color: white;
    font-weight: 700;
    font-size: 0.85rem;
    width: 100%;
    text-align: left;
  }

  .item-color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid white;
    margin-left: 5px;

    &.blue {
      background-color: ${({ theme }) => theme?.colors["primary-dark"]};
    }

    &.orange {
      background-color: ${({ theme }) => theme?.colors["secondary-default"]};
    }

    &.gray {
      width: 25px;
      height: 25px;
      background-color: #8c92ac95;
    }
  }
`;

export const DateFilterRow = styled(FilterRow)`
  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;
      width: 100%;
      input {
        border-radius: ${({ theme }) =>
          theme?.utilities.borders["border-radius-md"]};
        border: 0;
        background-color: ${({ theme }) => theme?.colors["ui-blue"]};
        box-shadow: inset 0 1px 2px 0px 4px 8px rgba(0, 0, 0, 0.05);
        font-family: inherit;
        font-size: 1rem;
        padding: 0.6rem ${({ theme }) => theme?.utilities.spacing["space-sm"]};
        color: ${({ theme }) => theme?.colors["text-default"]};
        width: 100%;

        background-color: white;
        border: 1px solid ${({ theme }) => theme?.colors["text-default"]};
        font-size: 1.125rem;

        &::placeholder {
          color: ${({ theme }) => theme?.colors["text-default"]};
          opacity: 0.6;
        }
      }
    }
  }
`;

export const SidebarTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
`;

export const MapOptionsWrapper = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  left: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-md"]};
  background-color: #00000090;
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  color: white;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
`;

export const MapOption = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  justify-content: space-between;
  font-weight: bold;
`;
