import { useEffect, useMemo, useState } from "react";
import { FilterOptionCategory, Filters } from "./globalContext";

export type UpdateCategoriesFunction = (updates: CategoryUpdateData[]) => void;
export type CategoryUpdateData = {
  /** the name of the filter category. eg `country`, `language`, `partner` */
  category: string;
  /** a boolean map of values representing whether or not the category has any active filters */
  values: { [key: string]: boolean };
};
export type usePageFiltersConfig = {
  viewFilters?: Filters;
  filterOptionCategories: FilterOptionCategory[];
};
const getImplicitFilters = ({
  viewFilters = {},
  filterOptionCategories,
}: usePageFiltersConfig) => {
  const getNonExistentFilterValuesAtCategoryKey = (
    categoryKey: string,
    filters: Filters,
    categories: FilterOptionCategory[]
  ) => {
    const matchingCategory = categories.find(
      ({ category }) => category === categoryKey
    );
    /** if filters doesnt contain this key, return empty object */
    if (!filters[categoryKey]) return {};
    if (!matchingCategory) {
      /**  if this key does not exist, return the whole object*/

      return { [categoryKey]: filters[categoryKey] };
    } else {
      /** now check for any values that do not exist on a category */
      return {
        [categoryKey]: filters[categoryKey].reduce((acc, value) => {
          if ((matchingCategory.values as string[]).includes(value)) {
            return acc;
          } else {
            return [...acc, value];
          }
        }, []),
      };
    }
  };
  return Object.keys(viewFilters).reduce((acc, key) => {
    return {
      ...acc,
      ...getNonExistentFilterValuesAtCategoryKey(
        key,
        viewFilters,
        filterOptionCategories
      ),
    };
  }, {});
};
const getUserSelectableFilters = ({
  viewFilters = {},
  filterOptionCategories,
}: usePageFiltersConfig): Filters => {
  const userSelectable = filterOptionCategories.reduce((acc, { category }) => {
    if (category in viewFilters) {
      return { ...acc, [category]: viewFilters[category] };
    } else {
      return acc; //{ ...acc, [category]: [] };
    }
  }, {});
  return userSelectable;
};
export const usePageFilters = (config: usePageFiltersConfig) => {
  /** the state for filters that need to be selectable by the user */
  const [userSelectableFilters, setUserSelectableFilters] = useState(() => {
    return getUserSelectableFilters(config);
  });
  /** the non mutating data that includes filters that the user should not need to think about */
  // eslint-disable-next-line
  const implicitFilters = useMemo(() => getImplicitFilters(config), []);
  const [filters, setFilters] = useState<Filters>({});
  /**
   * this effect keeps filters in sync with changes to userselectable filters while merging in additions from implicit filters
   */
  useEffect(() => {
    const mergeFilters: (...filters: Filters[]) => Filters = (...filters) => {
      return filters.reduce((acc, filter) => {
        const thisFilterMergedWithAcc: Filters = Object.entries(filter).reduce(
          (accumulator, [category, values]) => {
            return {
              ...accumulator,
              [category]: mergeFilterValues(
                ...(acc[category] || []),
                ...values
              ),
            };
          },
          {}
        );
        return { ...acc, ...thisFilterMergedWithAcc };
      }, {});
    };
    const newFilters = mergeFilters(userSelectableFilters, implicitFilters);
    setFilters(newFilters);
  }, [userSelectableFilters, implicitFilters]);
  const parseUpdates = (updates: CategoryUpdateData[]) => {
    const newFilters: Filters = updates.reduce((acc, currentUpdate) => {
      /** a filter is active if and only if the values of all options =  */
      const isInactive = Object.values(currentUpdate.values).reduce(
        (allFalse, currentOption) => allFalse && currentOption,
        true
      );
      if (!isInactive) {
        return {
          ...acc,
          [currentUpdate.category]: Object.entries(currentUpdate.values).reduce(
            (acc, curr) => {
              if (curr[1]) {
                return [...acc, curr[0]];
              } else return acc;
            },
            []
          ),
        };
      } else {
        return acc;
      }
    }, {});
    return newFilters;
  };

  const updateCategories: UpdateCategoriesFunction = (updates) => {
    const newFilters = parseUpdates(updates);
    setUserSelectableFilters(newFilters);
  };
  const mergeFilterValues = (...values: string[]): string[] => [
    ...new Set(values),
  ];

  return {
    filters,
    implicitFilters,
    userSelectableFilters,
    updateCategories,
  };
};
