import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider } from "styled-components";
import { Theme } from "../../theme";
import DataTableData from "./dataTableData/dataTableData";
import DataTableFixedColumn from "./dataTableFixedColumn/dataTableFixedColumn";
import { overrideColorTheme, Table } from "./styles";
import { DataTableRow } from "./types/dataTableRow";
import { fixedColumnType } from "./types/fixedColumnType";
import ScrollContainer from "react-indiana-drag-scroll";

export type DataTableColumnHeader = {
  title: string;
  label?: string;
  tooltip?: string;
};

export type RowHeaderHeightType = "single" | "double";

export interface DataTableProps {
  rows: DataTableRow[];
  columnHeaders: DataTableColumnHeader[];
  columnWidth?: string;
  rowHeader: string;
  rowHeaderHeight?: RowHeaderHeightType;
  customColorTheme?: string;
  noScroll?: boolean;
  scrollOpts?: {
    initialScrollPosition?: "right" | "left";
    keepLatestScrollPosition?: boolean;
  };
  hideTotalColumn?: boolean;
  enableColumnSorting?: boolean;
  showTableBorder?: boolean;
  totalPosition?: "bottom" | "right";
}

const DataTable = ({
  rows,
  rowHeader,
  rowHeaderHeight = "single",
  customColorTheme,
  noScroll,
  scrollOpts= {
    initialScrollPosition: "right",
    keepLatestScrollPosition: false,
  },
  columnHeaders,
  columnWidth,
  hideTotalColumn,
  enableColumnSorting,
  showTableBorder,
  totalPosition = "right",
}: DataTableProps) => {
  const [tableRows, setTableRows] = useState([]);
  const scrollContainerRef = useRef<HTMLElement>(null);
  const [isGrabbing, setIsGrabbing] = useState(false);

  useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  useEffect(() => {
    if (scrollContainerRef.current && !noScroll) {
      if (!scrollOpts.keepLatestScrollPosition) {
        scrollContainerRef.current.scrollLeft =
          scrollOpts.initialScrollPosition === "left"
            ? 0
            : scrollContainerRef.current.scrollWidth;
      } else {
        if (
          scrollOpts.initialScrollPosition === "left" &&
          scrollContainerRef.current.scrollLeft === 0
        ) {
          scrollContainerRef.current.scrollLeft = 0;
        }
        //TODO Fix keepLatestScrollPosition when positios is right
        if (scrollOpts.initialScrollPosition === "right") {
          scrollContainerRef.current.scrollLeft =
            scrollContainerRef.current.scrollWidth;
        }
      }
    }

    //eslint-disable-next-line
  }, [scrollContainerRef, rows]);

  return (
    <ThemeProvider
      theme={(theme: Theme) => overrideColorTheme(theme, customColorTheme)}
    >
      <Table
        className={`${noScroll && "no-scroll"} ${
          showTableBorder && "show-border"
        }`}
      >
        <ScrollContainer
        className="scroll-container"
        vertical={false}
        hideScrollbars={false}
        onStartScroll={() => setIsGrabbing(true)}
        onEndScroll={() => setIsGrabbing(false)}
        style={{
          cursor: isGrabbing ? "grabbing" : "grab",
        }}
        innerRef={scrollContainerRef}
      >
        <DataTableFixedColumn
          title={rowHeader}
          rows={tableRows}
          columnType={fixedColumnType.metric}
          totalPosition={totalPosition}
          rowHeaderHeight={rowHeaderHeight}
        />
        <DataTableData
          rows={tableRows}
          noScroll={noScroll}
          scrollOpts={scrollOpts}
          columnHeaders={columnHeaders}
          rowHeaderHeight={rowHeaderHeight}
          columnWidth={columnWidth}
          totalPosition={totalPosition}
          enableColumnSorting={enableColumnSorting}
          setRows={setTableRows}
        />
        {!hideTotalColumn && totalPosition === "right" && (
          <DataTableFixedColumn
            title="Totals"
            rows={tableRows}
            columnType={fixedColumnType.total}
          />
        )}
        </ScrollContainer>
      </Table>
    </ThemeProvider>
  );
};

export default DataTable;
