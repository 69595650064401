import styled from "styled-components";

export const ReportingMapWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  .tooltip {
    position: absolute;
    white-space: nowrap;
    z-index: 1;
    pointer-events: none;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
    transform: translate(25px, -25px);
    color: ${({ theme }) => theme?.colors["primary-dark"]};
    background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};
    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-sm"]};
    padding: ${({ theme }) => theme?.utilities.spacing["space-xs"]}
      ${({ theme }) => theme?.utilities.spacing["space-sm"]};

    .tooltip-breakdown {
      font-size: 0.85rem;
      padding-left: 5px;
    }
  }
`;

export const SidebarTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  gap: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
`;

export const SidebarViewSelector = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const SidebarViewSelectorItem = styled.div`
  width: 25%;
  padding: 0.15rem ${({ theme }) => theme?.utilities.spacing["space-xs"]};
  border: 2px solid ${({ theme }) => theme?.colors["primary-dark"]};
  text-align: center;
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-sm"]};
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;

  &:hover,
  &.active {
    background-color: ${({ theme }) => theme?.colors["primary-dark"]};
    color: white;
  }
`;
