import styled from "styled-components";

export const LoginPage = styled.div`
  grid-area: page;
  width: 100%;
  padding: ${({ theme }) => theme?.utilities.spacing["space-page"]}
    ${({ theme }) => theme?.utilities.spacing["space-md"]}
    ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  position: relative;

  min-height: 100%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme?.utilities.spacing["space-xl"]};
  .login-content {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => theme?.utilities.spacing["space-md"]};

    .logo-wrapper {
      width: 33%;
      margin-bottom: ${({ theme }) => theme?.utilities.spacing["space-md"]};

      &.global-partner {
        width: 66%;
        display: flex;
        align-items: center;

        .ul-logo {
          padding-right: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
          border-right: 1px solid #33333350;
          width: 50%;
        }

        .partner-logo {
          padding-left: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
          width: 45%;
        }
      }
    }

    .input-container {
      margin-bottom: ${({ theme }) => theme?.utilities.spacing["space-md"]};
      &:last-child {
        margin-bottom: 0;
      }
    }

    .login-error {
      color: ${({ theme }) => theme?.colors["ui-alert"]};
      font-weight: bold;
      font-size: 0.85rem;
      margin-top: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
    }
  }
`;
