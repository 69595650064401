import React from "react";
import { FixedColumnCell, SecondaryValue } from "./styles";
import Tooltip from "../tooltip";
interface DataTableFixedCellProps {
  value: string;
  secondaryValue?: string;
  tooltipText?: string;
  showTooltip?: boolean;
  rowHasSubData: boolean;
}

const DataTableFixedCell = ({
  value,
  secondaryValue,
  tooltipText,
  showTooltip,
  rowHasSubData,
}: DataTableFixedCellProps) => {
  const formatValue = (value: any) => {
    const match = String(value)?.match(/- Display -|- OneHope -|- China: Chinese/);
    if (match) {
      return value.substring(0, match?.index);
    } else {
      return value;
    }
  };

  return (
    <FixedColumnCell className={rowHasSubData && "sub-data"}>
      <div className="value-row">
        {formatValue(value)}{" "}
        {showTooltip && tooltipText && <Tooltip text={tooltipText} />}
      </div>

      {secondaryValue && <SecondaryValue>{secondaryValue}</SecondaryValue>}
    </FixedColumnCell>
  );
};

export default DataTableFixedCell;
