import { iconMapping } from "../profileMapConfig";
import { IconLayer } from "@deck.gl/layers";

type LayerCallbackObject = { typeIndex: number; coords: number[] };

const useProfileMapLayers = (profiles: any[], onProfileClick: Function) => {
  const profileLayer = {
    id: "profiles",
    data: profiles,
    pickable: true,
    billboard: false,
    iconAtlas: "reporting-atlas.png",
    iconMapping,
    getIcon: (d: LayerCallbackObject) => d.typeIndex,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    sizeScale: 10,
    getPosition: (d: LayerCallbackObject) => d.coords,
    getSize: 1.5,
    onClick: onProfileClick,
    loadOptions: {
      imagebitmap: {
        premultiplyAlpha: "premultiply",
      },
    },
    LayerClass: IconLayer,
    hoverTypeName: "profile",
  };

  return profileLayer;
};

export default useProfileMapLayers;
