import styled from "styled-components";

export const TestimonyWrapper = styled.div`
  height: 641px;
  padding: ${({ theme }) => theme?.utilities.spacing["space-xl"]} 0;
  padding-left: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  position: relative;
  display: flex;
`;

export const TestimonyBackButton = styled.div`
  font-size: 1rem;
  cursor: pointer;
  font-weight: 700;

  &:hover {
    filter: brightness(0.7);
  }
`;

export const TestimonyContainer = styled.div`
  overflow: auto;
  height: 100%;
  padding: 0 ${({ theme }) => theme?.utilities.spacing["space-md"]};
  margin-right: ${({ theme }) => theme?.utilities.spacing["space-md"]};

  &.video {
    width: 100%;
    border-radius: 2rem;
    overflow: hidden;
  }
`;
