import { Filters } from "hooks/globalContext";
import { useEffect, useState } from "react";
import { getProfiles } from "services/apiService";
import CountryService from "services/country-service";
import { ProfileMapFilters } from "./useProfileMapFilters";

export type ProfileType = "default" | "trinity";

const useProfileMapData = (
  selectedCountry: string,
  filters: ProfileMapFilters,
  inheritedFilters: Filters,
  profileType: ProfileType,
  loadingFilters: boolean
) => {
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);

  useEffect(() => {
    if (!selectedCountry) {
      setFilteredProfiles([...profiles]);
    } else {
      setFilteredProfiles(
        profiles.filter((profile) => profile.country === selectedCountry)
      );
    }
  }, [profiles, selectedCountry]);

  const getRequestFilters = () => [
    filters.currentFilters["Date Range"],
    filters.currentFilters["KPI Type"],
  ];

  const fetchMapData = async () => {
    setLoading(true);
    let requestFilters = getRequestFilters();
    let profiles = (await getProfiles(
      requestFilters[0],
      requestFilters[1],
      profileType ?? "default",
      inheritedFilters ?? {}
    )) as any[];
    setProfiles(CountryService.parseLocationData(profiles));
    setLoading(false);
  };

  useEffect(() => {
    if (!loadingFilters) {
      fetchMapData();
    }
    //eslint-disable-next-line
  }, [filters.currentFilters, loadingFilters]);

  return {
    loading,
    profiles,
    filteredProfiles,
  };
};

export default useProfileMapData;
