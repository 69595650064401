const s3 = process.env.REACT_APP_S3_URL;
const baseUrl = `${s3}partners`;

const partnerImageMapping = {
  gmo: `${baseUrl}/GMO.svg`,
  cru: `${baseUrl}/Cru.png`,
  onehope: `${baseUrl}/OneHope.svg`,
  ghm: `${baseUrl}/umid.png`,
  nameless: `${baseUrl}/Nameless.png`,
  blessed: `${baseUrl}/icons/blessed-media.svg`,
  trinity: `${baseUrl}/Trinity.png`,
};

const usePartnerImage = () => {
  const code = window.location.hostname.split(".")[0];
  return partnerImageMapping[code];
};

export default usePartnerImage;
