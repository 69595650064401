import styled from "styled-components";
export type BubbleProps = {
  backgroundColor: string;
  fontColor: string;
  diameter: string;
};
export const Bubble = styled.div<BubbleProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  width: ${({ diameter }) => diameter};
  height: ${({ diameter }) => diameter};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: ${({ theme }) => theme.utilities.spacing["space-xs"]};
    p {
      margin: 0;
      padding: 0;
    }
  }
  .uppercase {
    text-transform: uppercase;
    width: 80%;
    text-align: center;
    white-space: initial;
  }
  .large {
    font-size: calc(${({ diameter }) => diameter} / 6);
    font-weight: 600;
  }
  .small {
    font-size: calc(${({ diameter }) => diameter} / 14);
    font-weight: 700;
  }
`;
