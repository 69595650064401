import ArcMap from "components/arcMap";
import ReportingMap from "components/reportingMap";
import ProfileMap from "components/profileMap";
import HostNetworkMap from "components/hostNetworkMap";
import YearToDateMap from "components/yearToDateMap";
import { SidebarProps } from "components/reportingMap/hooks/useReportingMapFilters";
import { MapWrapper } from "./styles";
import { Filters } from "hooks/globalContext";

type MapType = "reporting" | "profile" | "ytd" | "arc" | "hostNetwork";

const MapFactory = (mapType: MapType, props: ConfigDrivenMapProps) => {
  switch (mapType) {
    case "reporting":
      return <ReportingMap {...props} {...props.config} />;
    case "profile":
      return <ProfileMap {...props} {...props.config} />;
    case "ytd":
      return <YearToDateMap {...props.config} />;
    case "arc":
      return (
        <ArcMap
          {...props.config}
          loading={props.loading}
          filters={props.pageFilters}
        />
      );
    case "hostNetwork":
      return <HostNetworkMap {...props} />;
    default:
      return <div>ERR: Missing Map Data</div>;
  }
};

type ConfigDrivenMapProps = {
  config: {
    title: string;
    mapType: MapType;
    defaultDropdownType?: "string";
    sidebar: SidebarProps;
    dashboardModeCanBeEnabled: boolean;
    country: string;
  };
  pageFilters: Filters;
  loading: boolean;
  mapType: string;
};

const ConfigDrivenMap: React.FC<ConfigDrivenMapProps> = (props) => {
  return <MapWrapper>{MapFactory(props.config.mapType, props)}</MapWrapper>;
};

export default ConfigDrivenMap;
