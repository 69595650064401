import { useEffect, useState } from "react";
import styled from "styled-components";
import DeckGL from "deck.gl";
import { StaticMap, ViewportProps } from "react-map-gl";
import useYearToDateMapData from "./hooks/useYearToDateMapData";
import useYearToDateMapLayers from "./hooks/useYearToDateMapLayers";
import { MapLoading, MapTitle } from "../interactive-map/styles";
import Spinner from "components/spinner";
import { MapTotals, MapTotalsItem } from "./styles";
import { displayTotals } from "./yearToDateMapConfig";
import { useAnalyticsFormatting } from "hooks/useAnalyticsFormatting";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

type YearToDateMapProps = {
  title: string;
};

const YearToDateMap: React.FC<YearToDateMapProps> = ({ title }) => {
  const [dataInTimerRange, setDataInTimerRange] = useState([]);
  const [viewport, setViewport] = useState<ViewportProps>({
    minZoom: 1.75,
    latitude: 15,
    longitude: 0,
    zoom: 1.75,
  });

  const mapData = useYearToDateMapData();
  const format = useAnalyticsFormatting();

  useEffect(() => {
    setDataInTimerRange(
      mapData.data.filter(
        (d) =>
          d.timestamp <= mapData.timer && d.timestamp >= mapData.timer - 1000
      )
    );
    //eslint-disable-next-line
  }, [mapData.timer]);

  const layers = useYearToDateMapLayers(dataInTimerRange);
  return (
    <Wrapper>
      <DeckGL
        viewState={viewport}
        onViewStateChange={(e) => setViewport(e.viewState)}
        controller={true}
        layers={[layers]}
      >
        <StaticMap
          reuseMaps
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          //mapStyle="mapbox://styles/mapbox/dark-v10"
          mapStyle={process.env.REACT_APP_MAPBOX_YTD_STYLE_URL}
          //mapStyle="mapbox://styles/mapbox/satellite-v9"
          preventStyleDiffing={true}
        />
      </DeckGL>
      <MapTitle>
        <h2>{title}</h2>
      </MapTitle>
      {mapData.loading && (
        <MapLoading>
          <Spinner />
        </MapLoading>
      )}

      <MapTotals>
        <DisplayTotal name="In real time..." color="#FFFFFF" text="THIS YEAR" />
        {displayTotals.map((displayTotal) => (
          <DisplayTotal
            {...displayTotal}
            text={format(mapData.totals[displayTotal.value])}
            key={displayTotal.name}
          />
        ))}
      </MapTotals>
    </Wrapper>
  );
};

const DisplayTotal = ({ name, color, text }) => (
  <MapTotalsItem>
    <h5 style={{ color }}>{name}</h5>
    <h3>{text}</h3>
  </MapTotalsItem>
);

export default YearToDateMap;
