import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SeekerProfile } from "components/seekerProfile/types";
import { useAnalyticsFormatting } from "hooks/useAnalyticsFormatting";
import { useState } from "react";
import {
  List,
  ListHeader,
  ListItem,
  ListItemHeader,
  ListItemIndex,
  ListItemInfoRow,
  ListPageControl,
  ListWrapper,
} from "./styles";

const PAGE_SIZE = 25;

type ProfileListProps = {
  filteredProfiles: any[];
  setSelectedProfile: (profile: SeekerProfile) => void;
};

const ProfileList: React.FC<ProfileListProps> = ({
  filteredProfiles,
  setSelectedProfile,
}) => {
  const [page, setPage] = useState(0);

  const format = useAnalyticsFormatting();

  const pageStart = page * PAGE_SIZE;
  const pageEnd = pageStart + PAGE_SIZE;

  const nextPage = () => {
    if (pageEnd + PAGE_SIZE <= filteredProfiles.length) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  return (
    <ListWrapper>
      <ListHeader>
        <h3>
          Showing {pageStart + 1} - {pageEnd} of{" "}
          {format(filteredProfiles.length)}
        </h3>
        <ListPageControl>
          <div
            className={`page-btn ${page === 0 && "disabled"}`}
            onClick={prevPage}
          >
            <FontAwesomeIcon icon={faChevronLeft as IconProp} />
          </div>
          <div
            className={`page-btn ${
              pageEnd + PAGE_SIZE > filteredProfiles.length && "disabled"
            }`}
            onClick={nextPage}
          >
            <FontAwesomeIcon icon={faChevronRight as IconProp} />
          </div>
        </ListPageControl>
      </ListHeader>

      <List>
        {filteredProfiles.slice(pageStart, pageEnd).map((item, i) => (
          <ListItem key={i}>
            <ListItemHeader>
              <ListItemIndex>{pageStart + i + 1}</ListItemIndex>
              <h4>{item.first_name ?? item.last_name}</h4>
            </ListItemHeader>
            <ListItemInfoRow>
              <span>COUNTRY</span>
              <div className="divider" />
              {item.country}
            </ListItemInfoRow>
            <ListItemInfoRow>
              <span>LANGUAGE</span>
              <div className="divider" />
              {item.language}
            </ListItemInfoRow>
            <ListItemInfoRow>
              <span>IMPACT METRICS</span>
              <div className="divider" />
              {item.totalImpactMetrics}
            </ListItemInfoRow>
            <button
              className="btn btn-primary"
              onClick={() => setSelectedProfile(item)}
            >
              View Profile
            </button>
          </ListItem>
        ))}
      </List>
    </ListWrapper>
  );
};

export default ProfileList;
