import {
  faChevronDown,
  faChevronUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BusinessTermCategory,
  useBusinessMappings,
} from "hooks/useBusinessMappings";
import React, { useState } from "react";
import styled from "styled-components";
import PageFilterMenuItem from "../../pageFilterMenuItem";

const SelectAll = styled.h6`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors?.["primary-light"]};
  cursor: pointer;
  padding-top: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  text-align: left;
  &:hover {
    text-decoration: underline;
  }
`;

const MenuCategory = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 17rem;

  .dropdown {
    background-color: white;
    position: relative;
    /* z-index: 2; */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem 2.5rem 0.6rem 1.25rem;
    font-weight: 700;
    color: var(--text-default);
    font-size: 1.125rem;
    border-radius: var(--border-radius-md) var(--border-radius-md)
      ${(props) => (props.open ? "0 0" : "")};
    text-align: center;
    border: 1px solid var(--text-default);
    position: relative;
    width: 100%;
    text-transform: capitalize;
    > svg {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
    .select {
      border: 1px solid var(--text-default);
      position: absolute;
      border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
      left: -1px;
      right: -1px;
      top: 100%;
      z-index: 3;
      padding: 1.5rem;
      background: white;
      max-height: 25rem;
      overflow-y: scroll;
      input[type="checkbox"] {
        border: 1px solid #999ea4;
        background-color: white;
        &::before {
          background-color: #17357250;
          color: #173572;
        }
        &::after {
          color: #173572;
        }
      }
      .input {
        padding: 0.5rem;
        display: flex;
        gap: 5px;
        border-radius: var(--border-radius-md);
        border: 1px solid gray;
        outline: none;
        input {
          border: none;
          outline: none;
          ::placeholder {
            text-transform: capitalize;
          }
        }
        > svg {
          opacity: 0.4;
        }
      }
    }
    .click {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      /* z-index: 2; */
    }
  }
`;

type PageFilterMenuCategoryProps = {
  category: string;
  values: {
    [key: string]: boolean;
  };
  onChange: (key: string, value: boolean) => void;
  onAllChange: (value: boolean) => void;
};

const DropdownFilterMenuCategory = ({
  category,
  values,
  onChange,
  onAllChange,
}: PageFilterMenuCategoryProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredValues, setFilteredValues] = useState(Object.keys(values));
  const numberOfSelected = Object.values(values).filter((v) => v).length;
  const allSelected = Object.values(values).every((v) => v);
  const mapTerms = useBusinessMappings();
  const castedCategory: BusinessTermCategory = category as BusinessTermCategory;
  return (
    <MenuCategory open={showDropdown}>
      <h4>{mapTerms(category, castedCategory)}</h4>
      <div className="dropdown">
        <div
          className="click"
          onClick={() => setShowDropdown((prevValue) => !prevValue)}
        ></div>
        <p>{allSelected ? "all Selected" : `${numberOfSelected} Selected`}</p>
        <FontAwesomeIcon icon={showDropdown ? faChevronUp : faChevronDown} />
        {showDropdown && (
          <div className="select">
            <label className="input">
              <FontAwesomeIcon icon={faSearch} />
              <input
                placeholder={"search for " + category}
                onChange={({ target }) => {
                  setFilteredValues(
                    Object.keys(values).filter(
                      (key) =>
                        mapTerms(key, castedCategory)
                          .toLowerCase()
                          .includes(target.value.toLowerCase()) ||
                        key.toLowerCase().includes(target.value.toLowerCase())
                    )
                  );
                }}
              ></input>
            </label>
            <SelectAll onClick={() => onAllChange(!allSelected)}>
              {allSelected ? "Deselect All" : "Select All"}
            </SelectAll>

            {Object.entries(values).map(([key, value]) => {
              return (
                filteredValues.includes(key) && (
                  <PageFilterMenuItem
                    key={key}
                    label={mapTerms(key, castedCategory)}
                    checked={value}
                    onChange={() => onChange(key, !value)}
                  />
                )
              );
            })}
          </div>
        )}
      </div>
    </MenuCategory>
  );
};

export default DropdownFilterMenuCategory;
