import styled from "styled-components";

export const LayoutGrid = styled.div`
  min-height: 100vh;
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: 100px 7fr;
  grid-template-rows: 7fr 160px;
  grid-template-areas:
    "sidebar page"
    "footer footer";

  &.hide-footer {
    grid-template-rows: 7fr;
    grid-template-areas: "sidebar page";
  }
`;
