import styled from "styled-components";
import { Placeholder } from "../componentPlaceholder/styles";
import ComponentPlaceholder from "../componentPlaceholder";
import { BubbleRow, DataBubbleRowWrapper, DataBubbleTitle } from "./styles";

type DataBubbleRowPlaceholderProps = {
  rowTitle: string;
  subTitle: string;
  numRows: number;
};

const PlaceholderWrapper = styled(DataBubbleRowWrapper)`
  ${Placeholder} {
    width: 240px;
    border-radius: 50%;
  }
`;

const DataBubbleRowPlaceholder: React.FC<DataBubbleRowPlaceholderProps> = ({
  rowTitle,
  subTitle,
  numRows,
}) => {
  let rows = Array.apply(null, Array(numRows)).map((v: never, i: number) => i);

  return (
    <PlaceholderWrapper>
      <DataBubbleTitle>
        <h1 className="title text">{rowTitle}</h1>
        <h3 className="subtitle text">{subTitle}</h3>
      </DataBubbleTitle>
      <BubbleRow>
        {rows.map((i) => (
          <ComponentPlaceholder height={240} key={i} />
        ))}
      </BubbleRow>
    </PlaceholderWrapper>
  );
};

export default DataBubbleRowPlaceholder;
