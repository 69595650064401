import styled from "styled-components";

export const PageFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: ${({ theme }) =>
    theme.utilities?.spacing?.["space-lg"] +
    " " +
    theme.utilities?.spacing?.["space-page"]};
`;

export const Filter = styled.div`
  position: relative;
`;
