import { iconMapping } from "../hostNetworkMapConfig";
import { HostNetworkData } from "./useHostNetworkMapData";
import { ScatterplotLayer, IconLayer } from "@deck.gl/layers";

const hostRadius = 25 * 1609.344;

const useHostNetworkMapLayers = (
  data: HostNetworkData,
  showConnections: boolean
) => {
  const hostLayer = {
    id: "host-layer",
    data: data?.hosts ?? [],
    pickable: true,
    opacity: 0.8,
    filled: true,
    stroked: true,
    radiusScale: 1,
    lineWidthMinPixels: 1,
    getPosition: (d) => d.coords,
    getRadius: () => hostRadius,
    getFillColor: () => [140, 146, 172, 150],
    getLineColor: () => [175, 175, 175, 255],
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    LayerClass: ScatterplotLayer,
    hoverTypeName: "host",
  };

  const seekerLayer = {
    id: "data",
    data: data?.seekers ?? [],
    pickable: true,
    billboard: false,
    iconAtlas: "reporting-atlas.png",
    iconMapping,
    getIcon: (d) => (d.isConnected ? "connected" : "unconnected"),
    sizeScale: 10,
    getPosition: (d) => d.coords,
    getSize: () => 1.5,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    loadOptions: {
      imagebitmap: {
        premultiplyAlpha: "premultiply",
      },
    },
    LayerClass: IconLayer,
    hoverTypeName: "seeker",
  };

  return showConnections ? [hostLayer, seekerLayer] : [hostLayer];
};

export default useHostNetworkMapLayers;
