import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdditionalProfileInfo, Testimony } from "../types";
import {
  BackButton,
  Container,
  Wrapper,
} from "./styles";

type SeekerAdditionalInfoProps = {
  info: AdditionalProfileInfo;
  handleBack: () => void;
};

const SeekerAdditionalInfo: React.FC<SeekerAdditionalInfoProps> = ({
  info,
  handleBack,
}) => {
  console.log(info);
  return (
    <Wrapper>
      <BackButton>
        <FontAwesomeIcon
          onClick={handleBack}
          icon={faArrowLeft as IconProp}
          size="lg"
        />
      </BackButton>
      <Container>
        <h3>More Info</h3>
        <ul>
            <li>Campaign: {info.campaignName}</li>
        </ul>
      </Container>
    </Wrapper>
  );
};

export default SeekerAdditionalInfo;
