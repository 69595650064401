import React from "react";
import Card from "./styles";
import Metric from "../metric";
export type TotalCardProps = {
  /**
   * The heading to display above the Total Card
   */
  heading?: string;
  /**
   * an array of prop objects for the metric rows to create
   */
  rows: Omit<Parameters<typeof Metric>[0], "onClose">[];
};
export const TotalCard = ({ heading, rows }: TotalCardProps) => {
  return (
    <Card title={heading}>
      {rows.map((row) => {
        return (
          <Metric
            icon={row.icon}
            title={row.title}
            value={row.value}
            color={row.color}
            secondaryValue={row.secondaryValue}
            key={row.title}
          />
        );
      })}
    </Card>
  );
};
