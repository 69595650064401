const iconMapping = {
  0: { x: 0, y: 0, width: 256, height: 256, mask: false },
  1: { x: 256, y: 0, width: 256, height: 256, mask: false },
  2: { x: 512, y: 0, width: 256, height: 256, mask: false },
};

const keyMapping = {
  default: ["Story", "Baptized", "Discipled"],
  trinity: ["Membership", "Growth Track Completed", "Form Completed"],
  prayerWall: ["Prayer Request Submitted"],
};

const keyColorMapping = ["green", "orange", "blue"];

export { iconMapping, keyMapping, keyColorMapping };
