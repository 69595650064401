import styled from "styled-components";

export const ColumnMenuWrapper = styled.div`
  position: relative;
`;

export const ColumnMenu = styled.div`
    background-color: rgb(247, 250, 255);
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
    border-radius: 1rem;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: calc(100% + 10px);
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .checkboxes {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
    }

    .checkbox-value {
      display: flex;
      gap: 1rem;
    }

    button {
        width: 100%;
        padding: 0.6rem 1.25rem;
    }
`;
