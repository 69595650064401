import React from "react";
import { SpinnerWrapper } from "./styles";

const Spinner = () => {
  return (
    <SpinnerWrapper>
      <div className="dot first"></div>
      <div className="dot second"></div>
      <div className="dot third"></div>
      <div className="dot fourth"></div>
      <div className="line vertical"></div>
      <div className="line horizontal"></div>
      <div className="line diagonal"></div>
    </SpinnerWrapper>
  );
};

export default Spinner;
