import styled from "styled-components";

export const Metrics = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  padding: ${({ theme }) => theme?.utilities.spacing["space-lg"]} 0;
  align-items: center;
  justify-items: center;

  h2 {
    text-transform: uppercase;
  }
`;

export const MetricGroup = styled.div`
  width: 100%;
  h3 {
    width: 100%;
    text-align: center;
    font-style: italic;
    font-size: 1.05rem;
  }
`;

export const SingleMetricItem = styled.h3`
  color: ${({ theme }) => theme?.colors["metric"]};
`;

export const RecurringMetricItem = styled.h3`
  color: ${({ theme }) => theme?.colors["secondary-default"]};
`;

export const MetricItem = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]}
    ${({ theme }) => theme?.utilities.spacing["space-md"]};
  padding-left: calc(
    ${({ theme }) => theme?.utilities.spacing["space-md"]} + 20px
  );
`;

export const MetricIconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(calc(-50% - 1px), -50%);
  .metric-icon {
    grid-area: icon;
    position: relative;
    justify-self: center;

    &.incomplete {
      filter: brightness(0.75);
    }
    > svg {
      display: block;

      &:first-of-type {
        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.25));
      }
    }
    > svg.svg-inline--fa {
      width: 18px;
      height: 18px;
      color: ${({ theme }) => theme?.colors["ui-gray"]};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      box-shadow: none;
    }
  }
`;

export const MetricItemTitle = styled.div`
  display: flex;
  align-items: center;

  .tooltip-text {
    width: 200px;
  }
`;

export const MetricItemValue = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-right: ${({ theme }) => theme?.utilities.spacing["space-xs"]};
    cursor: default;
  }
  p {
    font-size: 0.85rem;
    font-weight: 700;
  }
`;

export const MetricItemValueRecurring = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .recurring-option {
    width: 40px;
    padding: 2.5px 0;
    border-radius: 5px;
    background-color: ${({ theme }) => theme?.colors["ui-gray-darker"]};
    color: #aaa;
    text-align: center;
    font-size: 0.75rem;
    &.active {
      background-color: ${({ theme }) => theme?.colors["primary-dark"]};
      color: white;
    }
  }
`;
