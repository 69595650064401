/**
 * Formats a date as a string
 * @param {Date} date The date to format
 */
const formatDate = (date) => {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }

  let year = date.getFullYear();
  let month = (1 + date.getUTCMonth()).toString().padStart(2, "0");
  let day = date.getUTCDate().toString().padStart(2, "0");

  return month + "/" + day + "/" + year;
};

export default formatDate;
