import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faChartBar,
  faChartLine,
  faCross,
  faFileAlt,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";

const hexColors = [
  [
    [205, 239, 255],
    [153, 196, 224],
    [107, 154, 194],
    [66, 112, 164],
    [18, 45, 69],
    [0, 12, 29],
  ],
  [
    [253, 165, 51],
    [218, 125, 46],
    [180, 88, 40],
    [140, 55, 32],
    [98, 25, 21],
    [58, 0, 0],
  ],
  [
    [32, 208, 144],
    [38, 167, 110],
    [34, 129, 79],
    [26, 92, 52],
    [15, 58, 28],
    [0, 28, 0],
  ],
  [
    [255, 232, 56],
    [217, 194, 18],
    [179, 156, 0],
    [140, 117, 0],
    [102, 79, 0],
    [64, 41, 0],
  ],
  [
    [253, 165, 51],
    [218, 125, 46],
    [180, 88, 40],
    [140, 55, 32],
    [98, 25, 21],
    [58, 0, 0],
  ],
];

const displayData = {
  gospelPresentations: {
    icon: faCross,
    text: "Gospel Presentations",
    hexagonColors: hexColors[0],
  },
  scriptureEngagements: {
    icon: faCross,
    text: "Scripture Engagements",
    hexagonColors: hexColors[0],
  },
  formCompletions: {
    icon: faFileAlt,
    text: "Form Submissions",
    hexagonColors: hexColors[0],
  },
  facebookReach: {
    icon: faFileAlt,
    text: "Digital Reach",
    hexagonColors: hexColors[0],
  },
  seekersAdded: {
    icon: faChartBar,
    text: "New Contacts",
    hexagonColors: hexColors[0],
  },
  hostsAdded: {
    icon: faChartBar,
    text: "New Hosts",
    hexagonColors: hexColors[0],
  },
  confirmedConnected: {
    icon: faChartBar,
    text: "Confirmed Connected",
    hexagonColors: hexColors[0],
  },
  inPerson: {
    icon: faChartBar,
    text: "In Person Connected",
    hexagonColors: hexColors[0],
  },
  trinityGiving: {
    icon: faChartBar,
    text: "Trinity Giving",
    hexagonColors: hexColors[0],
  },
  indicatedDecisions: {
    icon: faChartBar,
    text: "Indicated Decisions",
    hexagonColors: hexColors[1],
  },
  discipleshipActivity: {
    icon: faChartLine,
    text: "Discipleship Activity",
    hexagonColors: hexColors[2],
  },
  trinityFormsCompleted: {
    icon: faFileAlt,
    text: "Trinity Forms Completed",
    hexagonColors: hexColors[0],
  },
  trinityNextSteps: {
    icon: faFileImport,
    text: "Trinity Growth Track",
    hexagonColors: hexColors[1],
  },
  trinityMembership: {
    icon: faChartBar,
    text: "Trinity Membership",
    hexagonColors: hexColors[2],
  },
  facebookCustomDailyPageReachCity: {
    icon: faFacebookF,
    text: "Reach By City",
    hexagonColors: hexColors[4],
  },
  facebookCustomDailyPageFansCity: {
    icon: faFacebookF,
    text: "Fans By City",
    hexagonColors: hexColors[3],
  },
  facebookCustomDailyPageFollowsCity: {
    icon: faFacebookF,
    text: "Followers By City",
    hexagonColors: hexColors[3],
  },
};

const masks = [
  { x: 0, y: 0, width: 256, height: 256, mask: false },
  { x: 256, y: 0, width: 256, height: 256, mask: false },
  { x: 512, y: 0, width: 256, height: 256, mask: false },
];

const iconMapping = {
  gospelPresentations: masks[0],
  scriptureEngagements: masks[0],
  formCompletions: masks[0],
  facebookReach: masks[0],
  seekersAdded: masks[0],
  hostsAdded: masks[0],
  confirmedConnected: masks[0],
  inPerson: masks[0],
  trinityGiving: masks[0],
  indicatedDecisions: masks[1],
  discipleshipActivity: masks[2],
  trinityFormsCompleted: masks[0],
  trinityNextSteps: masks[1],
  trinityMembership: masks[2],
  facebookCustomDailyPageReachCity: masks[0],
  facebookCustomDailyPageFansCity: masks[1],
  facebookCustomDailyPageFollowsCity: masks[2],
};

export type MapView = "default" | "hex" | "hex3d";

const mapViews = [
  {
    name: "Default",
    value: "default" as MapView,
  },
  {
    name: "Hex",
    value: "hex" as MapView,
  },
  {
    name: "Hex 3D",
    value: "hex3d" as MapView,
  },
];

export { displayData, iconMapping, mapViews };
