import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "components/dataTable/tooltip";
import { recurringOptionsConfig } from "../seekerProfileConfig";
import { SeekerProfile } from "../types";
import {
  MetricIconWrapper,
  MetricItem,
  MetricItemTitle,
  MetricItemValue,
  MetricItemValueRecurring,
} from "./styles";

export type SeekerProfileMetricItemProps = {
  profile: SeekerProfile;
  icon: any;
  title: string;
  type: "single" | "recurring";
  value: any;
  helpText: string;
};

const SeekerProfileMetricItem: React.FC<SeekerProfileMetricItemProps> = ({
  profile,
  icon,
  title,
  type,
  value,
  helpText,
}) => {
  const recurringOptions = recurringOptionsConfig[profile.profileType];

  return (
    <MetricItem>
      <MetricIconWrapper>
        <div className={`metric-icon ${!value.length ? "incomplete" : ""}`}>
          <svg
            width="44"
            height="50"
            viewBox="0 0 44 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill={type === "single" ? "#2957CC" : "#FDA533"}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 0L43.6506 12.5V37.5L22 50L0.349365 37.5V12.5L22 0Z"
            />
          </svg>
          <FontAwesomeIcon icon={icon} />
        </div>
      </MetricIconWrapper>

      <MetricItemTitle>
        <h4>{title}</h4>
        <Tooltip text={helpText} />
      </MetricItemTitle>

      {type === "single" ? (
        <MetricItemValue>
          <input type="checkbox" checked={!!value.length} readOnly />
          {/* {!!value && (
          <p>
            {new Date(value).toLocaleString("default", {
              month: "short",
              year: "numeric",
            })}
          </p>
        )} */}
        </MetricItemValue>
      ) : (
        <MetricItemValue>
          <MetricItemValueRecurring>
            {recurringOptions.map((text) => (
              <RecurringOption
                options={recurringOptions}
                text={text}
                value={value}
                profileType={profile.profileType}
                key={text}
              />
            ))}
          </MetricItemValueRecurring>
        </MetricItemValue>
      )}
    </MetricItem>
  );
};

type RecurringOptionProps = {
  options: string[];
  text: string;
  value: string;
  profileType: string;
};
const RecurringOption: React.FC<RecurringOptionProps> = ({
  options,
  text,
  value,
  profileType,
}) => {
  let active: boolean;
  if (profileType === "trinity") {
    active = value && value.length ? !!value?.split(",").includes(text) : false;
  } else {
    active = value?.toLowerCase() === text?.toLowerCase();
  }

  return (
    <div className={`recurring-option ${active ? "active" : ""}`}>{text}</div>
  );
};

export default SeekerProfileMetricItem;
