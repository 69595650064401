import styled from "styled-components";
export const CardWrapper = styled.div`
  &.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme?.utilities?.spacing?.["space-md"]};
    &h2 {
      white-space: nowrap;
    }
  }
  .body {
    display: grid;
    grid-column-gap: ${({ theme }) => theme?.utilities?.spacing?.["space-sm"]};
    padding: ${({ theme }) => theme?.utilities?.spacing?.["space-md"]};
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill, 1fr);
    height: auto;
    width: 100%;
    background-color: ${({ theme }) => theme?.colors?.["ui-gray-lighter"]};
    border-radius: ${({ theme }) =>
      theme?.utilities?.borders?.["border-radius-lg"]};
    @media screen and (min-width: 2400px) {
      grid-template-columns: 1fr 1fr;
      & > :nth-last-child(1):nth-child(odd) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
`;
export type CardProps = {
  title: string;
};
export const Card: React.FC<CardProps> = (props) => {
  return (
    <CardWrapper>
      <div className="heading">
        <h2>{props.title}</h2>
      </div>
      <div className="body">{props.children}</div>
    </CardWrapper>
  );
};
export default Card;
