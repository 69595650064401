import {
  Analytics,
  DataByAnalyticType,
  DataByAnalyticTypeWithTotal,
  DataByBin,
} from "./types";

/**
 *
 * @param data an array of data by analyticType to find the total for
 * @returns the same array, but with totals for each analytic type
 */
export const getTotalsFromDataByAnalyticType: (
  data: DataByAnalyticType[]
) => DataByAnalyticTypeWithTotal[] = (data) => {
  return data.map((row) => {
    return {
      ...row,
      /** iterate through the elements in data and add them up */
      total: row.data.reduce((curr, acc) => curr + acc),
    };
  });
};

/** a function that converts the input data in a single date range from Analytics to DataByAnalyticType[] */
export const toDataByAnalyticTypeArray = (AEData: Analytics) => {
  if (!AEData) return [];
  const dataByAnalyticTypeArray: DataByAnalyticType[] = Object.entries(
    AEData
  ).reduce((acc, [analyticType, valuesKeyedByBinName]) => {
    const binNames = Object.keys(valuesKeyedByBinName);
    const data = Object.values(valuesKeyedByBinName);
    return [...acc, { analyticType, binNames, data }];
  }, []);
  return dataByAnalyticTypeArray;
};
/** a function that converts input data in a single date range from Analytics to DataByAnalyticType */
export const toDataByBinArray = (AEData: Analytics) => {
  if (!AEData || Object.keys(AEData).length === 0) {
    return [];
  }
  /** this assumes all entries in AE data have the same bins */
  const binNames = Object.keys(Object.values(AEData)[0]);
  /** the accumulator object and an inline helper type definition */
  const dataKeyedByBin: {
    [key: DataByBin["binName"]]: {
      analyticType: DataByAnalyticType["analyticType"];
      value: number | string;
    }[];
  } = {};
  /**
   * for each analytic type,
   *  iterate through each bin width,
   *    extract data for the current bin
   *    and add it to the accumulator object
   */
  Object.entries(AEData).forEach(([analyticType, valuesKeyedByBinName]) => {
    binNames.forEach((binName) => {
      dataKeyedByBin[binName] = [
        /** default to empty array if null-ish to avoid breaking the spread operator */
        ...(dataKeyedByBin[binName] || []),
        { analyticType, value: valuesKeyedByBinName[binName] },
      ];
    });
  });
  /**
   * convert the object into an array of objects to easily be mapped over
   */
  const dataByBinArray: DataByBin[] = Object.entries(dataKeyedByBin).reduce(
    (accumulator, [binName, values]) => {
      return [
        ...accumulator,
        {
          binName,
          data: values.reduce(
            (acc, { analyticType, value }) => ({
              ...acc,
              [analyticType]: value,
            }),
            {}
          ),
        },
      ];
    },
    []
  );
  /** return the array */
  return dataByBinArray;
};
