import React from "react";
import Button from "../../button/button";
import Card from "../../card";
import {
  Menu,
  MenuBody,
  MenuCollapseButton,
  MenuFooter,
  MenuHeader,
} from "./styles";
import PageFilterMenuCategory from "../pageFilterMenuCategory";
import { FilterOptionCategory } from "hooks/globalContext";
import DropdownFilterMenuCategory from "./dropdownMenuFilterCategory/dropdownMenuFilterCategory";

type PageFilterMenuProps = {
  filters: {
    [category: string]: {
      [key: string]: boolean;
    };
  };
  onChange: (category: string, key: string, value: boolean) => void;
  onAllChange: (category: string, value: boolean) => void;
  active: boolean;
  onCollapse: () => void;
  onUpdateClick: () => void;
  categories: FilterOptionCategory[];
};

const PageFilterMenu = ({
  filters,
  onChange,
  onAllChange,
  active,
  onCollapse,
  onUpdateClick,
  categories,
}: PageFilterMenuProps) => {
  const categoriesMap = categories.reduce((res, current) => {
    return {
      ...res,
      [current.category]: current,
    };
  }, {});

  return (
    <Menu className={active && "active"}>
      <Card.Body>
        <MenuHeader>
          <h3>Filters</h3>
          <MenuCollapseButton onClick={onCollapse}>
            <div className="menu-icon__bar menu-icon__bar--1"></div>
            <div className="menu-icon__bar menu-icon__bar--3"></div>
          </MenuCollapseButton>
        </MenuHeader>

        <MenuBody>
          {Object.entries(filters).map(([name, values]) =>
            categoriesMap[name].filterComponent === "DropdownCheckbox" ? (
              <DropdownFilterMenuCategory
                key={name}
                category={name}
                values={values}
                onChange={(key, value) => onChange(name, key, value)}
                onAllChange={(value) => onAllChange(name, value)}
              />
            ) : (
              <PageFilterMenuCategory
                key={name}
                category={name}
                values={values}
                onChange={(key, value) => onChange(name, key, value)}
                onAllChange={(value) => onAllChange(name, value)}
              />
            )
          )}
        </MenuBody>

        <MenuFooter>
          <Button type="secondary" onClick={onUpdateClick}>
            Update Data
          </Button>
        </MenuFooter>
      </Card.Body>
    </Menu>
  );
};

export default PageFilterMenu;
