import React from "react";
import {
  SwitcherItem,
  SwitcherItems,
  SwitcherItemSpacer,
  SwitcherItemsWrapper,
} from "./styles";
import { ViewConfig } from "../../hooks/globalContext";

type ViewSwitcherItemsProps = {
  switcherOpen: boolean;
  viewConfig: ViewConfig;
  views: ViewConfig[];
  onClick: (title: string) => void;
};

const ViewSwitcherItems = ({
  switcherOpen,
  viewConfig,
  views,
  onClick,
}: ViewSwitcherItemsProps) => {
  const filteredViews = views.filter(({ title }) => viewConfig.title !== title);

  return (
    <SwitcherItemsWrapper>
      <SwitcherItems className={!switcherOpen ? "hidden" : ""}>
        {filteredViews.map(({ title, iconUrl }) => (
          <React.Fragment key={title}>
            <SwitcherItem key={title} onClick={() => onClick(title)}>
              <img src={iconUrl} alt="" /> {title}
            </SwitcherItem>
            <SwitcherItemSpacer />
          </React.Fragment>
        ))}
      </SwitcherItems>
    </SwitcherItemsWrapper>
  );
};

export default ViewSwitcherItems;
