import React, { useEffect, useRef, useState } from "react";
import { Dropdowns, Wrapper } from "./styles";
import AreaGraph from "../../components/areaGraph";
import DataTable from "../../components/dataTable";
import Form from "../../components/form";
import {
  serializeDateRange,
  toDataByAnalyticTypeArray,
  toDataByBinArray,
} from "../../hooks/globalContext";

import { useGlobalAnalytics } from "../../hooks/useGlobalAnalytics";
import { DataByAnalyticType, Filters } from "../../hooks/globalContext";
import {
  DateRange,
  DateRangeBinWidthKey,
  DateRangesAreEqual,
} from "../../utils/dateRange";
import { DataTableRow } from "../../components/dataTable/types/dataTableRow";
import DateRangeDropDown from "../../components/date-range";
import {
  dateRangePresets,
  RangeKey,
} from "../../components/date-range/useDateRange";
import { useBusinessMappings } from "hooks/useBusinessMappings";
import Card from "../../components/card";
import {
  AnalyticsFormatFunction,
  FormatType,
  useAnalyticsFormatting,
} from "hooks/useAnalyticsFormatting";
import AreaGraphPlaceholder from "components/areaGraph/areaGraphPlaceholder";
import DataTablePlaceholder from "components/dataTable/dataTablePlaceholder";

type DataTableRowConfig = {
  value: {
    dataKey: string;
  };
  tooltipText: string;
  secondaryValue?: DataTableRowConfig["value"];
  title?: string;
  formatType?: FormatType;
};

export type AreaGraphWithDataTableProps = {
  loading: boolean;
  config: {
    table: DataTableRowConfig[];
    graph: {
      defaultAnalyticKey: string;
    };
    initialRangeKey: RangeKey;
    filters: Filters;
    title: string;
    shouldInheritPageFilters: boolean;
    hideTableTotalColumn?: boolean;
  };
  pageFilters: Filters;

  key?: string;
};
export const AreaGraphWithDataTable: React.FC<AreaGraphWithDataTableProps> = (
  props
) => {
  const [dateRange, setDateRange] = useState<DateRange>(
    dateRangePresets[props.config.initialRangeKey].getDateRange
  );
  const [filters, setFilters] = useState(
    props.config.shouldInheritPageFilters
      ? props.pageFilters
      : props.config.filters
  );
  useEffect(() => {
    if (props.config.shouldInheritPageFilters) {
      setFilters(props.pageFilters);
    }
  }, [props.pageFilters, props.config.shouldInheritPageFilters]);

  const allDependencies = getAllDependencies(props.config.table, dateRange);
  const {
    loading: loadingDependencies,
    dependencyData,
    dependencies,
    setDependencies,
    setCanFetch,
  } = useGlobalAnalytics({
    dependencies: allDependencies,
    dateRange,
    filters,
  });

  const [tableData, setTableData] = useState(toDataByAnalyticTypeArray({}));
  const [graphData, setGraphData] = useState(toDataByBinArray({}));
  const [totals, setTotals] = useState<{ [key: string]: number | string }>({});
  const containerRef = useRef<HTMLDivElement>();
  const [containerWidth, setContainerWidth] = useState(900);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
    //eslint-disable-next-line
  }, [containerRef.current]);

  useEffect(() => {
    const currentDateRangeKey = serializeDateRange(dateRange);
    if (!(props.loading || loadingDependencies)) {
      setTotals(extractTotalsFromDependencies(dependencyData));
      setGraphData(toDataByBinArray(dependencyData[currentDateRangeKey]));
      setTableData(
        toDataByAnalyticTypeArray(dependencyData[currentDateRangeKey])
      );
    }
  }, [dependencyData, props.loading, loadingDependencies, dateRange]);
  useEffect(() => {
    if (!props.loading) {
      setCanFetch(true);
    } else {
      setCanFetch(false);
    }
  }, [props.loading, setCanFetch]);
  /**
   * for this component, we'll want to update the date range for
   * all dependencies unilaterally when the date range changes
   */
  useEffect(() => {
    if (
      dateRange &&
      !dependencies.every(
        (dependency) =>
          DateRangesAreEqual(dateRange, dependency.dateRange) ||
          dependency.dateRange.binWidth === "total"
      )
    ) {
      setDependencies(
        dependencies.map((dependency) => {
          if (dependency.dateRange.binWidth === "total") {
            return {
              ...dependency,
              dateRange: { ...dateRange, binWidth: "total" },
            };
          } else {
            return { ...dependency, dateRange: dateRange };
          }
        })
      );
    }
    //eslint-disable-next-line
  }, [dateRange, dependencies]);

  useEffect(() => {
    setDependencies(
      dependencies.map((dependency) => {
        return { ...dependency, filters };
      })
    );
    //eslint-disable-next-line
  }, [filters]);
  /**
   * the state to hold the current selected analytic
   */
  const [selectedDataKey, setSelectedDataKey] = useState(
    props.config.graph.defaultAnalyticKey
  );
  const data = graphData.map(({ binName, data }) => ({
    ...data,
    key: binName,
  }));
  /** get headers -- headers are the bin names for this table */
  const mapTerms = useBusinessMappings();
  const format = useAnalyticsFormatting();
  const dataTableColumnHeaders =
    tableData[0]?.binNames.map((binName) => {
      return { title: mapTerms(binName) };
    }) || [];

  /**  */
  const dataTableRows = formatRows(
    tableData,
    props.config.table,
    mapTerms,
    format,
    totals
  );

  const loading = props.loading || loadingDependencies;

  return (
    <Card.BasicCard title={props.config.title} key={props.key}>
      <Wrapper ref={containerRef}>
        <Dropdowns>
          {!loading && (
            <Form.Dropdown
              options={Object.keys(graphData[0]?.data || [])}
              value={selectedDataKey}
              onChange={(newOption: string) => {
                setSelectedDataKey(newOption);
              }}
              mapper={mapTerms}
            />
          )}
          <DateRangeDropDown
            initialRangeKey={props.config.initialRangeKey}
            handleDateRangeChange={(range) => {
              if (range) setDateRange(range);
            }}
          />
        </Dropdowns>
        {!loading ? (
          <AreaGraph
            data={data}
            activeDataKey={selectedDataKey}
            tooltipLabel={mapTerms(selectedDataKey)}
            formatTooltipValue={(value) =>
              format(value, selectedDataKey, { showEmptyData: true })
            }
            graphWidthScale={80}
            width={containerWidth}
            height={400}
          />
        ) : (
          <AreaGraphPlaceholder height={400} />
        )}
        {!loading ? (
          <DataTable
            rows={dataTableRows}
            rowHeader="SOURCE"
            columnHeaders={dataTableColumnHeaders}
            hideTotalColumn={props.config?.hideTableTotalColumn}
          />
        ) : (
          <DataTablePlaceholder numRows={props.config.table.length} />
        )}
      </Wrapper>
    </Card.BasicCard>
  );
};

/**prepare the  */
const formatRows = (
  data: DataByAnalyticType[],
  rowConfigs: DataTableRowConfig[],
  mapper: (string: string) => string = (s) => s,
  format: AnalyticsFormatFunction,
  totals: { [key: string]: number | string }
) => {
  const rowProps = rowConfigs.map((rowConfig) => {
    /** find this rows primary analytic data */
    const values = data.find(
      (row) => row.analyticType === rowConfig.value.dataKey
    );
    /** find this rows secondary analytic type if exists */
    const secondaryValues = data.find(
      (row) => row.analyticType === rowConfig?.secondaryValue?.dataKey
    );
    const rowData = (values?.data || []).map((dataPoint, i) => {
      /**calculate this cells value and secondary value if exists */
      return secondaryValues?.data?.[i]
        ? { value: dataPoint, secondaryValue: secondaryValues.data[i] }
        : {
            value: dataPoint,
          };
    });

    const formattedData = rowData.map((row) => {
      let res = {
        value: format(row.value, values?.analyticType),
        secondaryValue: null,
      };
      if (rowConfig.secondaryValue) {
        let formatted = format(
          row.secondaryValue,
          rowConfig.secondaryValue.dataKey,
          {
            formatType: "percentage",
          }
        );
        res.secondaryValue = formatted === "---" ? null : formatted;
      }

      return res;
    });
    /** construct props for data table row component */
    const rowProps: DataTableRow = {
      //@ts-ignore
      title: mapper(rowConfig.value.dataKey),
      data: formattedData,
      total: getRowTotals(totals, rowConfig, format),
      tooltipText: rowConfig.tooltipText,
    };
    return rowProps;
  });
  return rowProps;
};

const getRowTotals = (
  totals: { [key: string]: number | string },
  rowConfig: DataTableRowConfig,
  format: AnalyticsFormatFunction
) => {
  return {
    value: format(totals[rowConfig.value.dataKey], rowConfig.value.dataKey, {
      showEmptyData: true,
    }),
    secondaryValue: !!rowConfig.secondaryValue
      ? format(
          totals[rowConfig.secondaryValue.dataKey],
          rowConfig.secondaryValue.dataKey,
          {
            showEmptyData: true,
            formatType: "percentage",
          }
        )
      : null,
  };
};

/**wrapper for the below generator to clean up the function signature */
const getAllDependencies = (
  rows: DataTableRowConfig[],
  dateRange: DateRange
) => {
  return [...getAllDependenciesGenerator(rows, dateRange)];
};
/**using a generator to get a nice flat data structure */
function* getAllDependenciesGenerator(
  rows: DataTableRowConfig[],
  dateRange: DateRange
) {
  for (const dependency of rows) {
    yield {
      analyticType: dependency.value.dataKey,
      dateRange: dateRange,
    };
    yield {
      analyticType: dependency.value.dataKey,
      dateRange: { ...dateRange, binWidth: "total" as DateRangeBinWidthKey },
    };
    if (dependency.secondaryValue) {
      yield {
        analyticType: dependency.secondaryValue.dataKey,
        dateRange: dateRange,
      };
      yield {
        analyticType: dependency.secondaryValue.dataKey,
        dateRange: { ...dateRange, binWidth: "total" as DateRangeBinWidthKey },
      };
    }
  }
}
const extractTotalsFromDependencies = (dependencies) => {
  const totalKey = Object.keys(dependencies).find((key) => key.match("total"));
  const total = dependencies[totalKey];
  if (!total) return {};
  return Object.fromEntries(
    Object.entries(total).map(([key, value]) => {
      return [key, Object.values(value)?.[0] || 0];
    })
  );
};
const Factory = (props: AreaGraphWithDataTableProps) => ({
  Component: ({ key, loading, pageFilters }) =>
    AreaGraphWithDataTable({ ...props, key, pageFilters, loading }),
  getInitialDependencies: () => {
    return getAllDependencies(
      props.config.table,
      dateRangePresets[props.config.initialRangeKey].getDateRange()
    );
  },
});

export default Factory;
