import React, { createContext, useContext, useState } from "react";
import { login as apiLogin, setToken } from "services/apiService";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router";
//create the context
const authContext = createContext(null);
//create the provider function component that wraps the app
const AuthContext = (props) => (
  <authContext.Provider value={useAuthContext()}>
    {props.children}
  </authContext.Provider>
);
//create the public useAuth hook/
export const useAuth = () => useContext(authContext);

type LoginResponse = {
  email: string;
  name: {
    first: string;
    last: string;
  };
  bearer: string;
};

type CurrentUser = LoginResponse & {
  bearerData: any;
};

// create the useProvideAuth provider hook that is used by the public useAuth hook
const useAuthContext = () => {
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [loginError, setLoginError] = useState(null);

  const history = useHistory();

  const login = async (email: string, password: string, partnerUUID = null) => {
    // check localStorage first;
    let result = (await apiLogin(email, password).catch(() => {
      localStorage.removeItem("ul-pto-user");
      setLoginError("Invalid email/password");
    })) as LoginResponse;
    let bearer = jwtDecode(result.bearer);
    setToken(result.bearer);
    setCurrentUser({ ...result, bearerData: bearer });
    localStorage.setItem(
      "ul-pto-user",
      JSON.stringify({ ...result, bearerData: bearer })
    );
    setLoginError(null);
  };

  const logout = () => {
    history.push("/");
    setCurrentUser(undefined);
    localStorage.removeItem("ul-pto-user");
    return;
  };

  return {
    login,
    logout,
    currentUser,
    setCurrentUser,
    loginError,
  };
};

export default AuthContext;
