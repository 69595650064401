import { Filters, useGlobal } from "hooks/globalContext";
import { useEffect, useState } from "react";
import { getArcData } from "services/apiService";
import CountryService from "services/country-service";
import { ArcMapFilters } from "./useArcMapFilters";

type Arc = {
  profile: any;
  arc: {
    uuid: string;
    coords: number[];
  };
};

type ArcMapData = {
  profiles: any[];
  arcs: Arc[];
};

const useArcMapData = (
  filters: ArcMapFilters,
  inheritedFilters: Filters,
  loadingFilters: boolean
) => {
  const [data, setData] = useState<ArcMapData>({
    profiles: [],
    arcs: [],
  });
  const [loading, setLoading] = useState(true);

  const { partner } = useGlobal();

  const getRequestPartnerName = () => {
    return partner?.name === "unitelife" ? "all" : partner.name;
  };

  const fetchMapData = async () => {
    setLoading(true);
    let mapData = (await getArcData(
      filters.currentFilters["Date Range"],
      "prayerWall",
      getRequestPartnerName(),
      inheritedFilters
    )) as any[];
    setData(parseArcData(CountryService.parseLocationData(mapData)));
    setLoading(false);
  };

  const parseArcData = (data: any[]): { profiles: any[]; arcs: Arc[] } => {
    let profiles = [];
    let arcData = [];

    for (let row of data) {
      const { arcs, ...profile } = row;
      profiles.push(profile);
      arcData.push(...arcs.map((arc: Arc) => checkArcDataCoords(profile, arc)));
    }

    return {
      profiles,
      arcs: arcData,
    };
  };

  const checkArcDataCoords = (profile, arc) => {
    if (
      profile.coords[0] === arc.coords[0] &&
      profile.coords[1] === arc.coords[1]
    ) {
      arc.coords[0] += Math.random() - 0.5;
      arc.coords[1] += Math.random() - 0.5;
    }

    return {
      profile,
      arc,
    };
  };

  useEffect(() => {
    if (!loadingFilters) {
      fetchMapData();
    }
    //eslint-disable-next-line
  }, [filters.currentFilters, loadingFilters]);

  return {
    data,
    loading,
  };
};

export default useArcMapData;
