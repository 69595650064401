import { Filters } from "hooks/globalContext";
import { useEffect, useState } from "react";
import { getReportingData, getReportingTotal } from "services/apiService";
import CountryService from "services/country-service";
import { ReportingDataSource } from "../reportingMap";
import { ReportingMapFilters } from "./useReportingMapFilters";

const API_LIMIT = 250000;

const useReportingMapData = (
  selectedCountry: string,
  filters: ReportingMapFilters,
  inheritedFilters: Filters,
  dataSource: ReportingDataSource,
  loadingFilters: boolean,
  skipDataScattering?: boolean
) => {
  const [loading, setLoading] = useState(false);
  const [continueLoading, setContinueLoading] = useState(false);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [nextRequestOffset, setNextRequestOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const [nextData, setNextData] = useState([]);
  const [ignoreNextResult, setIgnoreNextResult] = useState(false);

  useEffect(() => {
    if (!selectedCountry) {
      setFilteredData([...data]);
    } else {
      setFilteredData(
        data.filter((reporting) => reporting.country === selectedCountry)
      );
    }
    //eslint-disable-next-line
  }, [data, selectedCountry]);

  const resetData = () => {
    setData([]);
    setTotal(0);
  };

  const getRequestFilters = () => [
    filters.currentFilters["Date Range"],
    filters.currentFilters["KPI Type"],
  ];

  const fetchMapTotal = async () => {
    let requestFilters = getRequestFilters();
    let result = (await getReportingTotal(
      requestFilters[0],
      requestFilters[1],
      dataSource,
      inheritedFilters
    )) as number;
    setTotal(result);
  };

  const fetchMapData = async () => {
    setLoading(true);
    //API request goes here
    let requestFilters = getRequestFilters();
    let newData = (await getReportingData(
      requestFilters[0],
      requestFilters[1],
      dataSource,
      0,
      inheritedFilters
    )) as any[];
    setData(CountryService.parseLocationData(newData, skipDataScattering));
    setLoading(false);
    if (newData.length === API_LIMIT) {
      setNextRequestOffset(1);
    }
  };

  const continueFetchMapData = async (offset = 1) => {
    let requestFilters = getRequestFilters();
    let newData = (await getReportingData(
      requestFilters[0],
      requestFilters[1],
      dataSource,
      offset,
      inheritedFilters
    )) as any[];
    setNextData(newData);
  };

  useEffect(() => {
    if (nextData.length > 0) {
      if (!ignoreNextResult) {
        setData([
          ...data,
          ...CountryService.parseLocationData(nextData, skipDataScattering),
        ]);
        if (nextData.length === API_LIMIT) {
          setNextRequestOffset(nextRequestOffset + 1);
        } else {
          setNextRequestOffset(0);
        }
      } else {
        setIgnoreNextResult(false);
        fetchMapTotal();
        fetchMapData();
      }
    }
    //eslint-disable-next-line
  }, [nextData]);

  //Get more data if needed
  useEffect(() => {
    if (nextRequestOffset > 0) {
      setContinueLoading(true);
      continueFetchMapData(nextRequestOffset);
    } else {
      setContinueLoading(false);
    }
    //eslint-disable-next-line
  }, [nextRequestOffset]);

  //Reset when filters change
  useEffect(() => {
    const countriesSelected = Object.values(filters.countryFilter).some(value => value === true);
    if (!loadingFilters && countriesSelected) {
      resetData();
      if (!loading && !continueLoading) {
        setNextRequestOffset(0);
        fetchMapData();
        fetchMapTotal();
      } else {
        setIgnoreNextResult(true);
        setLoading(true);
        setContinueLoading(false);
      }
    }
    //eslint-disable-next-line
  }, [filters.currentFilters, loadingFilters, filters.countryFilter]);

  return {
    data,
    filteredData,
    total,
    loading,
    continueLoading,
  };
};

export default useReportingMapData;
