import { useEffect, useRef, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid } from "recharts";
import Form from "../../form";
import { Graph, GraphScrollWrapper } from "../changeLog.styles";
import { graphOptions } from "../changeLogConfig";
import ScrollContainer from "react-indiana-drag-scroll";

const ChangeLogGraph = ({
  data,
  filterColumn,
  graphType,
  setGraphType,
  graphDate,
  setGraphDate,
  onItemClick,
  changeListFilters,
}) => {
  let { column, name } = filterColumn;

  const containerRef = useRef<HTMLDivElement>();
  const [containerWidth, setContainerWidth] = useState(900);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
    //eslint-disable-next-line
  }, [containerRef.current]);

  /**
   * the requested width is the scale times the number of data points to display
   */
  const requestedAreaChartWidth = 80 * data.length;
  /** however we do not want data width less than it's wrapper */
  const minimumAreaChartWidth = containerWidth - 60;
  //so fallback to minimum if requested does not fill this criteria
  const areaChartWidth =
    requestedAreaChartWidth >= minimumAreaChartWidth
      ? requestedAreaChartWidth
      : minimumAreaChartWidth;
  const graphDateOptions = ["Day", "Week", "Month"];
  return (
    <Graph>
      <div className="select-container">
        <div className="kpi-select">
          <h3>KPI View:</h3>
          <Form.Dropdown
            value={graphType}
            options={graphOptions}
            onChange={setGraphType}
            mapper={(s) => s}
          />
        </div>
        <div className="kpi-select">
          <h3>Date View:</h3>
          <Form.Dropdown
            value={graphDate}
            options={graphDateOptions}
            onChange={setGraphDate}
            mapper={(s) => s}
          />
        </div>
      </div>
      <GraphScrollWrapper height={400}>
        <ScrollContainer className="scroll-container" hideScrollbars={false}>
          <AreaChart
            width={areaChartWidth}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid />
            <XAxis
              dataKey="key"
              interval="preserveStart"
              tickCount={10}
              height={50}
              //@ts-ignore
              tick={<AxisTick />}
            />
            <YAxis domain={["auto", "auto"]} tickCount={4} />
            <Area
              type="linear"
              dataKey={column}
              label={name}
              stroke="#2957CC"
              strokeWidth={2}
              fill="#2957CC25"
              dot={(props) => (
                <ChangeLogDot
                  {...props}
                  onClick={onItemClick}
                  active={changeListFilters.includes(props.payload.key)}
                />
              )}
            />
          </AreaChart>
        </ScrollContainer>
      </GraphScrollWrapper>
    </Graph>
  );
};

const AxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y + 10})`}>
    <text x={0} y={0} dy={16} textAnchor="middle" fill="#1f356e">
      {payload.value}
    </text>
  </g>
);

const ChangeLogDot = (props) => {
  const { cx, cy, payload, onClick, active } = props;
  let { changes } = payload;

  if (changes > 0) {
    return (
      <svg
        x={cx - 15}
        y={cy - 15}
        width={30}
        height={30}
        viewBox="0 0 100 100"
        className={`changes-dot ${active ? "active" : ""}`}
        onClick={() => onClick(payload)}
      >
        <circle
          r="45"
          cx="50%"
          cy="50%"
          strokeWidth="10px"
          stroke={active ? "#1f356e" : "#F29A27"}
        />
        <text
          x="50%"
          y="50%"
          className="changes-number"
          textAnchor="middle"
          fontSize="3.5rem"
          fontWeight="700"
          dy=".345em"
        >
          {changes}
        </text>
      </svg>
    );
  } else {
    return null;
  }
};

export default ChangeLogGraph;
