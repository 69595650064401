import React from "react";
import styled from "styled-components";

const Checkbox = styled.input`
  width: 24px;
  height: 24px;
  margin-top: 0.25rem;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-sm"]};
  background-color: ${({ theme }) => theme.colors?.["ui-blue"]};
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.08);
  &:hover {
    cursor: pointer;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.["primary-dark"]};
    border-radius: ${({ theme }) =>
      theme.utilities?.borders?.["border-radius-sm"]};
    transform: scale(0.95);
    opacity: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  &::after {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: "\\2713";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.25);
    color: white;
    opacity: 0;
  }
  &:checked::before,
  &:checked::after {
    opacity: 1;
  }
`;

type FormCheckboxProps = {
  value?: string;
  checked: boolean;
  onChange: React.ChangeEventHandler;
  name?: string;
  id?: string;
  disabled?: boolean;
};

const FormCheckbox = (props: FormCheckboxProps) => {
  return <Checkbox type="checkbox" {...props} />;
};

export default FormCheckbox;
