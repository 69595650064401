import React from "react";
import { TableTotalRow } from "./styles";
import DataTableDataCell from "./dataTableDataCell";
import { DataTableRow } from "../types/dataTableRow";

interface DataTableDataRowProps {
  row: DataTableRow;
  columnWidth?: string;
}

const DataTableDataRow = ({ row, columnWidth }: DataTableDataRowProps) => (
  <TableTotalRow
    className={`${!!row?.total?.secondaryValue && "sub-data"}`}
    columnWidth={columnWidth}
  >
    {row?.data.map((data, i) => (
      <DataTableDataCell key={i} {...data} />
    ))}
  </TableTotalRow>
);

export default DataTableDataRow;
