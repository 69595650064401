import styled from "styled-components";
import { Profile } from "../styles";

export const PrayerWallProfile = styled(Profile)`
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 650px;
  left: calc(50% - (650px / 2));
`;

export const ProfileInfo = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme?.utilities.spacing["space-lg"]}
    ${({ theme }) => theme?.utilities.spacing["space-xl"]};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme?.utilities.spacing["space-md"]};
`;

export const CreatedAt = styled.p`
  width: 100%;
  text-align: right;
  opacity: 0.8;
  font-size: 0.85rem;
`;

export const MapKey = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  right: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  row-gap: ${({ theme }) => theme?.utilities.spacing["space-xs"]};
  align-items: center;
  justify-items: center;
  background-color: #00000090;
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-md"]};
  transition: 0.3s ease;

  &.sidebar-open {
    right: calc(${({ theme }) => theme?.utilities.spacing["space-lg"]} + 420px);
  }

  .map-key-item {
    color: white;
    font-weight: 700;
    font-size: 0.85rem;
    width: 100%;
    text-align: left;
  }

  .item-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid white;
    margin-left: 5px;

    &.white {
      background-color: white;
    }

    &.orange {
      background-color: ${({ theme }) => theme?.colors["secondary-default"]};
    }
  }
`;
