import Form from "components/form/legacy";
import styled from "styled-components";

export const FiltersWrapper = styled.div``;

export const FiltersHeader = styled.div`
  display: flex;
  align-items: center;
  padding: var(--space-md);
  border-bottom: 1px solid #1f356e20;
`;

export const FiltersHeaderToggle = styled.div`
  background-color: var(--secondary-default);
  margin-left: var(--space-xs);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
  border: 2px solid #f29a27;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }

  &.open {
    padding-left: 0;
    padding-top: 2px;
  }

  svg {
    width: 50%;
    height: 50%;
  }
`;

export const Filters = styled.div`
  padding: var(--space-sm) var(--space-md) var(--space-md);
  background-color: var(--ui-gray);
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
`;

export const FilterRow = styled(Form.InputContainer)`
  margin-bottom: var(--space-sm);

  .filter-dropdown,
  select {
    width: 100%;
  }
`;

export const FiltersButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--space-md);

  .btn {
    font-size: 1rem;
    width: 80%;

    &.btn-dashboard-mode {
      border: 2px solid var(--primary-dark);
      &.active {
        background-color: var(--primary-dark);
        color: white;
      }
    }

    &.btn-clear {
      color: white;
      border: 2px solid #c90c00;
      background-color: #c91b10;
      margin-bottom: var(--space-sm);
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
