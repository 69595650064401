import styled from "styled-components";
import Card from "components/card";

export const FormModal = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

export const FormWrapper = styled(Card.Body)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "title title title"
    "notes notes notes"
    "change_group kpi_type created_at"
    "btn btn btn";

  gap: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};

  .change-dropdown,
  select {
    width: 100%;
  }

  .title {
    grid-area: title;
  }
  .notes {
    grid-area: notes;
  }
  .owner {
    grid-area: owner;
  }
  .change-group {
    grid-area: change_group;
  }
  .change-type {
    grid-area: change_type;
  }
  .kpi-type {
    grid-area: kpi_type;
  }
  .created-at {
    grid-area: created_at;
  }
`;

export const ButtonWrapper = styled.div`
  grid-area: btn;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  button {
    margin-left: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;
