import styled from "styled-components";
export type GraphWrapperProps = {
  width?: number;
  height?: number;
};
export const GraphWrapper = styled.div<GraphWrapperProps>`
  width: ${(props: GraphWrapperProps) => props.width ?? 300}px;
  height: fit-content;
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.colors["ui-gray"]};
  margin-bottom: ${({ theme }) => theme.utilities.spacing["space-md"]};
  border-radius: ${({ theme }) => theme.utilities.borders["border-radius-lg"]};

  padding: ${({ theme }) => theme.utilities.spacing["space-md"]} 30px;

  .scroll-container {
    height: fit-content;
    width: 100%;
    overflow-x: auto;
    padding-bottom: ${({ theme }) => theme.utilities.spacing["space-sm"]};
    &::-webkit-scrollbar {
      height: 8px;
    }
    cursor: grab;

    &::-webkit-scrollbar-track {
      border-radius: ${({ theme }) =>
        theme.utilities.borders["border-radius-md"]};
      background: ${({ theme }) => theme.colors["ui-gray-dark"]};
      opacity: 0.4;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${({ theme }) =>
        theme.utilities.borders["border-radius-md"]};
      background: ${({ theme }) => theme.colors["scrollbar"]};
      &:hover {
        background: ${({ theme }) => theme.colors["table-line"]};
      }
    }
  }
`;
