import getApiUrl from "utils/getApiUrl";

const ulServerApiUrl = getApiUrl(
  process.env.REACT_APP_UL_SERVER_API,
  "http://localhost:80/api/v1",
  false
);

const ulApiUrl = getApiUrl(
  process.env.REACT_APP_UL_API_URL,
  "http://localhost:92/api",
  true
);
console.log(ulApiUrl);

const API = ulServerApiUrl + "/pto";
const AUTH_API = ulServerApiUrl + "/auth";

var token = null;

export const getToken = () => token;
export const setToken = (value: string) => (token = value);

export const login = async (email: string, password: string) => {
  return new Promise((resolve, reject) => {
    fetch(AUTH_API + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    }).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((result) => {
            resolve(result);
          });
        } else {
          reject();
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
};

export const getChangelog = async (filters: object) => {
  Object.keys(filters).forEach((key) => {
    if (!filters[key]) {
      delete filters[key];
    }
  });
  return makeRequest("GET", "/change-log", filters);
};

export const getSeekerTestimony = async (seekerUUID: string) =>
  makeRequest("GET", `/profile-map/testimony/${seekerUUID}`, null, true);

export const getSeekerCampaignId = async (seekerUUID: string) =>
  makeRequest("GET", `/profile-map/campaignId/${seekerUUID}`, null, true);

export const getAllSeekerTestimonies = async () =>
  makeRequest("GET", "/seeker-testimony");

export const createChangeLogEntry = async (data: any) =>
  makeRequest("POST", "/change-log", data);

export const deleteChangeLogEntry = async (entryUUID: string) =>
  makeRequest("DELETE", `/change-log/${entryUUID}`, {});

export const getProfiles = async (
  dateRange: string,
  type: string,
  profileType: string,
  filters: any
) =>
  makeRequest(
    "POST",
    "/profile-map",
    {
      dateRange,
      type,
      profileType,
      filters,
    },
    true
  );

export const getReportingData = async (
  dateRange: string,
  type: string,
  dataSource: string,
  offset = 0,
  filters = {}
) =>
  makeRequest("POST", "/reporting", {
    dateRange,
    type,
    dataSource,
    offset,
    filters,
  });

export const getHostNetworkData = async (
  startDate: string,
  endDate: string,
  filters: any
) =>
  makeRequest("POST", "/host-network", {
    startDate,
    endDate,
    filters,
  });

export const getReportingTotal = async (
  dateRange: string,
  type: string,
  dataSource: string,
  filters = {}
) =>
  makeRequest("POST", "/reporting-total", {
    dateRange,
    type,
    dataSource,
    filters,
  });

export const getYTDReporting = async (time: number, partner: string) =>
  makeRequest("POST", "/ytd-reporting", {
    time,
    partner,
  });

export const getYTDTotals = async (partner: string) =>
  makeRequest("POST", "/ytd-totals", {
    partner,
  });

export const getArcData = async (
  dateRange: string,
  type: string,
  partner: string,
  filters = {}
) =>
  makeRequest("POST", "/arcs", {
    dateRange,
    type,
    partner,
    filters,
  });

const makeRequest = async (
  method: string,
  path: string,
  params: any = null,
  useNewApi?: boolean
) => {
  return new Promise((resolve, reject) => {
    const url = useNewApi ? ulApiUrl : API;
    if (method === "GET") {
      let endpoint = url + path;
      if (params) {
        let queryString = "?";
        Object.keys(params).forEach((key) => {
          queryString += `${key}=${params[key]}&`;
        });
        endpoint = (endpoint + queryString).slice(0, -1);
      }
      fetch(endpoint, {
        headers: { Authorization: `Bearer ${getToken()}` },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            resolve(result);
          });
        } else {
          reject();
        }
      });
    } else {
      fetch(url + path, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(params),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((result) => {
            resolve(result);
          });
        } else {
          reject();
        }
      });
    }
  });
};
