import { GeoJsonLayer } from "@deck.gl/layers";
import CountryService from "services/country-service";
import {
  CountryClickFunction,
  GetCountryFillColorFunction,
  ItemHoverFunction,
} from "../types";

const useCountryLayer = (
  selectedCountry: string,
  highlightedCountry: string,
  canSelectCountry: boolean,
  getCountryFillColor: GetCountryFillColorFunction,
  onItemHover: ItemHoverFunction,
  onCountryClick: CountryClickFunction
) => {
  return new GeoJsonLayer({
    id: "countries",
    data: CountryService.geoJSON,
    pickable: canSelectCountry,
    filled: true,
    getFillColor: getCountryFillColor,
    onHover: ({ object }) => canSelectCountry && onItemHover(object, "country"),
    highlightColor: canSelectCountry ? [41, 87, 204, 51] : null,
    onClick: onCountryClick,
    updateTriggers: {
      getFillColor: [selectedCountry, highlightedCountry],
    },
  });
};

export default useCountryLayer;
