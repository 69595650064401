import React from "react";
import { Footer as FooterDiv, FooterContent } from "./styles";

const LOGO_URL =
  "https://ul-front-end-assets.s3.us-east-2.amazonaws.com/logo/logo_white.png";

const Footer = () => (
  <FooterDiv>
    <FooterContent>
      <p>Powered By</p>
      <img src={LOGO_URL} alt="Unite Life Logo" />
    </FooterContent>
  </FooterDiv>
);

export default Footer;
