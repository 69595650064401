import { useState } from "react";
import { ModalBackground, ProfileWrapper } from "./styles";
import {
  SeekerProfile as SeekerProfileType,
  SeekerTestimonyType,
} from "./types";
import useAdditionalProfileInfo from "./useAdditionalProfileInfo";
import DefaultSeekerProfile from "./defaultSeekerProfile";
import PrayerWallSeekerProfile from "./prayerWallSeekerProfile";
import { PrayerWallEntry } from "./prayerWallSeekerProfile/prayerWallSeekerProfile";

type SeekerProfileProps = {
  dashboardMode: boolean;
  setDashboardMode: (val: boolean) => void;
  leaving: boolean;
  setLeaving: (val: boolean) => void;
  selectedProfile: SeekerProfileType;
  setSelectedProfile: (profile: any) => void;
};

const SeekerProfile: React.FC<SeekerProfileProps> = ({
  dashboardMode,
  setDashboardMode,
  leaving,
  setLeaving,
  selectedProfile,
  setSelectedProfile,
}) => {
  const [seekerTestimonyType, setSeekerTestimonyType] =
    useState<SeekerTestimonyType>();
  const [showMoreSeekerInfo, setShowMoreSeekerInfo] = useState<boolean>(false);
  const additionalProfileInfo = useAdditionalProfileInfo(selectedProfile);

  const handleLeave = () => {
    setLeaving(true);
    dashboardMode && setDashboardMode(false);
    setTimeout(() => {
      setSelectedProfile(null);
      setLeaving(false);
    }, 350);
  };

  return (
    <>
      <ModalBackground onClick={handleLeave} />

      {selectedProfile.profileType === "prayerWall" ? (
        <PrayerWallSeekerProfile
          selectedProfile={selectedProfile as unknown as PrayerWallEntry}
          dashboardMode={dashboardMode}
          setDashboardMode={setDashboardMode}
          leaving={leaving}
          handleLeave={handleLeave}
        />
      ) : (
        <ProfileWrapper className={leaving && "leaving"}>
          <DefaultSeekerProfile
            dashboardMode={dashboardMode}
            setDashboardMode={setDashboardMode}
            selectedProfile={selectedProfile}
            showMoreSeekerInfo={showMoreSeekerInfo}
            seekerTestimonyType={seekerTestimonyType}
            additionalProfileInfo={additionalProfileInfo}
            handleLeave={handleLeave}
            setShowMoreSeekerInfo={setShowMoreSeekerInfo}
            setSeekerTestimonyType={setSeekerTestimonyType}
          />
        </ProfileWrapper>
      )}
    </>
  );
};

export default SeekerProfile;
