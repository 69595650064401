import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  padding: 6rem 0 0;
  background: white;
  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
`;
