import { AnalyticsFormatFunction } from "hooks/useAnalyticsFormatting";
import { displayData } from "./reportingMapConfig";

export type TooltipConfig = {
  showHexBreakdown?: boolean;
  hexBreakdownKey?: string;
};

type TooltipRenderObject = {
  count?: number;
  type?: string;
  country?: string;
  city?: string;
  points?: { source: TooltipRenderObject }[];
};

const renderReportingMapTooltip = (
  object: TooltipRenderObject,
  tooltipConfig: TooltipConfig,
  typeFilter: string,
  format: AnalyticsFormatFunction
) => {
  if (!object || (!object.points && !object.count)) {
    return null;
  }
  const type = !!object.points ? "hex" : "default";

  let count: number;
  if (type === "hex") {
    count = object.points.reduce(
      (total, { source }) => total + source.count,
      0
    );
  } else {
    count = object.count;
  }

  const headerText =
    type === "hex"
      ? displayData[typeFilter].text
      : displayData[object.type].text;

  const generateHexBreakdown = () => {
    let breakdownMap = object.points.reduce(
      (res, { source }) => ({
        ...res,
        [source[tooltipConfig.hexBreakdownKey]]: true,
      }),
      {}
    );

    return `
      <div class="tooltip-breakdown">
        ${Object.keys(breakdownMap)
          .map((value) => `<li>${value.split(",")?.[0] ?? value}</li>`)
          .join("")}
      </div>
    `;
  };

  const body =
    type === "hex" && tooltipConfig?.showHexBreakdown
      ? generateHexBreakdown()
      : "";

  return {
    html: `
      <div class="tooltip">
        <h5>${headerText}: ${format(count)}</h5>
        ${body}
      </div>
    `,
    style: {
      backgroundColor: "transparent",
    },
  };
};

export default renderReportingMapTooltip;
