import React from "react";
import styled from "styled-components";
import Form from "../../form";

const MenuItemCheckbox = styled(Form.Checkbox)`
  margin-top: 0;
  margin-right: 12px;
  padding: 0;
  flex-shrink: 0;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  &:not(&:first-child) {
    margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  }

  &.highlighted ${MenuItemCheckbox} {
    box-shadow: 0px 0px 8px
      ${({ theme }) => theme.colors?.["secondary-default"]};
  }

  &.disabled {
    opacity: 0.5;
    ${MenuItemCheckbox} {
      cursor: default;
    }
  }
`;

type PageFilterMenuItemProps = {
  label: string;
  checked: boolean;
  onChange: React.ChangeEventHandler;
  disabled?: boolean;
};

const PageFilterMenuItem = ({
  label,
  ...checkboxProps
}: PageFilterMenuItemProps) => {
  return (
    <MenuItem>
      <MenuItemCheckbox {...checkboxProps} />
      {label}
    </MenuItem>
  );
};

export default PageFilterMenuItem;
