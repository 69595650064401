import SeekerProfileInfo from "../seekerProfileInfo";
import SeekerProfileMetrics from "../seekerProfileMetrics";
import SeekerTestimony from "../seekerTestimony";
import SeekerAdditionalInfo from "../seekerAdditionalInfo";
import {
  Profile,
  ProfileClose,
  ProfileHeader,
  ProfileHeaderText,
} from "../styles";

const DefaultSeekerProfile = ({
  dashboardMode,
  setDashboardMode,
  selectedProfile,
  showMoreSeekerInfo,
  seekerTestimonyType,
  additionalProfileInfo,
  handleLeave,
  setShowMoreSeekerInfo,
  setSeekerTestimonyType,
}) => {
  const handleBack = () => {
    setSeekerTestimonyType(null);
    setShowMoreSeekerInfo(false)
  };
  return (
    <Profile
      className={`${!!seekerTestimonyType && "show-testimony"}`}
      onClick={() => {
        dashboardMode && setDashboardMode(false);
      }}
    >
      <ProfileClose style={{ zIndex: 1 }} onClick={handleLeave}>
        Close
      </ProfileClose>
      <ProfileHeader>
        <ProfileHeaderText>Seeker Impact Profile</ProfileHeaderText>
      </ProfileHeader>
      <SeekerProfileInfo
        profile={selectedProfile}
        {...additionalProfileInfo}
        handleShowMoreInfo={setShowMoreSeekerInfo}
        handleShowTestimony={setSeekerTestimonyType}
      />
      {seekerTestimonyType ? (
        <SeekerTestimony
          testimonyType={seekerTestimonyType}
          testimony={additionalProfileInfo?.testimony}
          handleBack={handleBack}
        />
      ) : showMoreSeekerInfo ? (
        <SeekerAdditionalInfo info={additionalProfileInfo} handleBack={handleBack} />
      ) : (
        <SeekerProfileMetrics profile={selectedProfile} />
      )}
    </Profile>
  );
};

export default DefaultSeekerProfile;
