import getApiUrl from "utils/getApiUrl";
import {
  Dependency,
  serializeDateRange,
  serializeFilters,
} from "./globalContext";
import {
  AnalyticsEngineRequestBody,
  AnalyticsEngineRequestDefinition,
  AnalyticsEngineReturnType,
} from "./types";

const apiServerAddress = getApiUrl(
  process.env.REACT_APP_ANALYTICS_API,
  "http://localhost:5001/api/v1/",
  true
);
//#TODO revert this

const getAnalytics = async (range, types, filters) => {
  const analytics = await makePostRequest<AnalyticsEngineReturnType>(
    "analytics",
    {
      types,
      range,
      filters,
    }
  );
  return analytics;
};
const makePostRequest: <T>(
  resource: string,
  body: { [key: string]: any }
) => Promise<T> = async (resource, body) => {
  const init: RequestInit = {
    method: "post",
    mode: "cors",
    cache: "no-cache",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(body),
  };
  const url = new URL(apiServerAddress + resource);
  const response = await fetch(url.toString(), init);
  return await response.json();
};

export const getDependencies = async (dependencies: Dependency[]) => {
  /** map the dependencies to AnalyticsEngineRequestDefinition-s */
  const requestDefinitions = dependencies.map(dependencyToRequestDefinition);
  const aggregatedDefinitions =
    combineAnalyticsRequestDefinitions(requestDefinitions);
  const requestBodies = aggregatedDefinitions.map(
    requestDefinitionToRequestBody
  );
  return (
    await Promise.all(
      requestBodies.map((body) =>
        getAnalytics(body.range, body.types, body.filters)
      )
    )
  ).map((request, i) => ({
    ...request,
    range: requestBodies[i].range,
  }));
};

/**
 *
 * @param requests the requests to combine into the fewest requests possible to optimize fetching times
 */
const combineAnalyticsRequestDefinitions = (
  requests: AnalyticsEngineRequestDefinition[]
) => {
  const groupedByFiltersAndRange = requests.reduce((acc, curr) => {
    /** lets stringify these so we can use them as keys to auto bundle up similar requests by their values */
    const filters = serializeFilters(curr.filters);
    const dateRange = serializeDateRange(curr.range);
    const previousRequests = acc?.[filters]?.[dateRange] || [];
    return {
      ...acc,
      [filters]: {
        ...acc[filters],
        [dateRange]: [...previousRequests, curr],
      },
    };
  }, {});

  /** for each grouping of filters, lets get all the ranges */
  return Object.entries(groupedByFiltersAndRange).flatMap(function ([
    filterKey,
    filterGroupGroupedByRange,
  ]) {
    /** for each grouping of ranges, lets get all the requests and then aggregate their types into one large request */
    return Object.entries(filterGroupGroupedByRange).map(function ([
      rangeKey,
      requests,
    ]) {
      /**
       *  since at this point all the current requests have the same filters and range,
       *  we'll just take the first element and destructure it
       */
      const { filters, range } = requests[0];
      const requestAggregate = requests.reduce(
        (acc, current) => {
          return {
            ...acc,
            /**aggregate the types for all requests */
            types: [...(acc.types || []), ...current.types],
          };
        },
        { filters, range }
      );
      /** return the result */
      return requestAggregate;
    });
  });
};
/**
 *
 * @param dependency the dependency to convert into a requestDefinition
 * @returns a request definition for the dependency passed in. usually will be aggregated with `combineAnalyticsRequestDefinitions()`
 */
const dependencyToRequestDefinition: (
  dependency: Dependency
) => AnalyticsEngineRequestDefinition = (dependency) => ({
  types: [dependency.analyticType],
  range: dependency.dateRange,
  filters: dependency.filters,
});

const requestDefinitionToRequestBody: (
  definition: AnalyticsEngineRequestDefinition
) => AnalyticsEngineRequestBody = (definition) => {
  return {
    filters: definition.filters,
    types: definition.types,
    range: {
      startDate: definition.range.startDate.date,
      endDate: definition.range.endDate.date,
      binWidth: definition.range.binWidth,
    },
  };
};
