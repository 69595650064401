import getApiUrl from "utils/getApiUrl";
import { Partner, PartnerConfig } from "./types";

const apiServerAddress = getApiUrl(
  process.env.REACT_APP_CONFIG_API,
  "http://pt.api.pathwayinitiative.com/api/v1",
  true
);

export const getViewsForPartner = async (partnerId: string) => {
  const partnerViews = await makeGetRequest<PartnerConfig>(
    `/partners/${partnerId}/views`
  );
  return partnerViews;
};
export const getAllPartners = async () => {
  const partners = await makeGetRequest("/partners");
  return partners;
};
export const getSpecificPartner = async (nameOrUUID: string) => {
  const UUIDTest =
    /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
  const isUUID = nameOrUUID.match(UUIDTest);
  if (isUUID) {
    return await getSpecificPartnerByUUID(nameOrUUID);
  } else {
    return await getSpecificPartnerByName(nameOrUUID);
  }
};
export const getSpecificPartnerByUUID = async (uuid: string) => {
  const partner = await makeGetRequest<Partner>(`/partners/${uuid}`);
  return partner;
};
export const getSpecificPartnerByName = async (name: string) => {
  const partners = await makeGetRequest<Partner[]>("/partners", {
    queryParams: { name },
  });
  const partner = partners[0];
  if (partner.name.toLowerCase() === name.toLowerCase()) {
    return partner;
  } else {
    throw new Error("partner not found");
  }
};

type GetRequestOptionalParams = {
  queryParams?: { [key: string]: string };
};
const makeGetRequest: <T>(
  resource: string,
  options?: GetRequestOptionalParams
) => Promise<T> = async (resource, options) => {
  const init: RequestInit = {
    method: "get",
    mode: "cors",
    cache: "no-cache",
  };
  const url = new URL(apiServerAddress + resource);
  if (options?.queryParams) {
    Object.entries(options.queryParams).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  }
  const response = await fetch(url.toString(), init);
  return await response.json();
};
