import React, { useState } from "react";
import ActiveSwitcherView from "./activeSwitcherView";
import { SwitcherWrapper } from "./styles";
import { ViewConfig } from "../../hooks/globalContext";
import ViewSwitcherItems from "./viewSwitcherItems";

// on change function params
// it will take one parameter, the index of the newly selected view
type OnChangeFunction = (index: number) => void;
type ViewComponentProps = {
  views: ViewConfig[];
  viewConfig: ViewConfig;
  onChange: OnChangeFunction;
};

export const LargeViewSwitcher = ({
  views,
  viewConfig,
  onChange,
}: ViewComponentProps) => {
  const [switcherOpen, setSwitcherOpen] = useState(false);

  //If there's only one view, this component is not necessary
  if (views?.length === 1 || !views) {
    return null;
  }

  const toggleSwitcher = () => {
    setSwitcherOpen(!switcherOpen);
  };

  const onItemClick = (title: string) => {
    const index = views.findIndex((view) => view.title === title);
    setSwitcherOpen(false);
    onChange(index);
  };

  return (
    <SwitcherWrapper isOpen={switcherOpen}>
      <ViewSwitcherItems
        viewConfig={viewConfig}
        views={views}
        onClick={onItemClick}
      />
      <ActiveSwitcherView
        viewConfig={viewConfig}
        toggleSwitcher={toggleSwitcher}
        switcherOpen={switcherOpen}
      />
    </SwitcherWrapper>
  );
};
export default LargeViewSwitcher;
