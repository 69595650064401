import styled from "styled-components";
export type DataBubbleRowWrapperProps = {
  fontColor?: string;
};
export const DataBubbleRowWrapper = styled.div<DataBubbleRowWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.utilities.spacing["space-md"]};
`;

export const DataBubbleTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    text-align: center;
    text-transform: uppercase;
    color: black;
  }

  .title {
    font-size: 3em;
  }
`;

export const BubbleRow = styled.div`
  display: flex;
  justify-content: center;
  white-space: nowrap;
  gap: ${({ theme }) => theme.utilities.spacing["space-xl"]};
`;
