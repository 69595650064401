import { dateRangePresets, RangeKey } from "components/date-range/useDateRange";
import { Filters } from "hooks/globalContext";
import { DateRange } from "utils/dateRange";
import React, { useEffect, useMemo, useState } from "react";
import ScrollingDataTableContainer from "../../components/dataTable/scrollingDataTableContainer";
import { Dependency } from "hooks/globalContext";
import { Dropdowns } from "../areaGraphWithDataTable/styles";
import DateRangeDropDown from "components/date-range";

import { DataTableProps } from "components/dataTable/dataTable";
import DataTablePlaceholder from "components/dataTable/dataTablePlaceholder";
import Card from "components/card";
import { DataTableRow } from "components/dataTable/types/dataTableRow";
import { QueryObserverResult, useQueries } from "react-query";
import { getDependencies } from "hooks/globalContext/analyticsClient";
export type ConfigDrivenDataTableListConfig = {
  initialRangeKey: RangeKey;
  shouldInheritPageFilters: boolean;
  filters: Filters;
};
export type ConfigDrivenDataTableListProps = {
  pageFilters: Filters;
  config: ConfigDrivenDataTableListConfig;
  loading: boolean;
};
const rowMeta: {
  name: string;
  type: Metric;
  tooltipText: string;
  subType?: string;
}[] = [
  { name: "Surveys Sent", type: "surveysSent", tooltipText: "" },
  {
    name: "Surveys Delivered",
    type: "surveysDelivered",
    subType:
      "surveysDeliveredRate" /**********************************#############################********************************** */,
    tooltipText: "",
  },
  {
    name: "Individuals Impacted",
    type: "uniqueImpactMetrics",
    subType:
      "uniqueImpactMetricsRate" /**********************************#############################********************************** */,
    tooltipText: "",
  },
  { name: "In Person", type: "inPerson", tooltipText: "" },
  { name: "Salvations", type: "salvations", tooltipText: "" },
  { name: "Baptized", type: "baptized", tooltipText: "" },
  { name: "Reading Bible", type: "readingBible", tooltipText: "" },
  { name: "Attending Church", type: "attending", tooltipText: "" },
  { name: "Sharing Faith", type: "multiplying", tooltipText: "" },
  { name: "Impact Metrics", type: "impactMetrics", tooltipText: "" },
];
const columns = [
  {
    name: "3 Day",
    rows: [
      { analyticType: "surveysSent3D" },
      { analyticType: "surveysDelivered3D", subType: "surveysDeliveredRate3D" },
      { analyticType: null },
      { analyticType: "inPerson3D" },
      { analyticType: "salvations3D" },
      { analyticType: "baptized3D" },
      { analyticType: "readingBible3D" },
      { analyticType: "attending3D" },
      { analyticType: "multiplying3D" },
      { analyticType: "impactMetrics3D" },
    ],
  },
  {
    name: "2 Week",

    rows: [
      { analyticType: "surveysSent2W" },
      { analyticType: "surveysDelivered2W", subType: "surveysDeliveredRate2W" },
      { analyticType: null },
      { analyticType: "inPerson2W" },
      { analyticType: "salvations2W" },
      { analyticType: "baptized2W" },
      { analyticType: "readingBible2W" },
      { analyticType: "attending2W" },
      { analyticType: "multiplying2W" },
      { analyticType: "impactMetrics2W" },
    ],
  },
  {
    name: "2 Month",
    rows: [
      { analyticType: "surveysSent2M" },
      { analyticType: "surveysDelivered2M", subType: "surveysDeliveredRate2M" },
      { analyticType: null },
      { analyticType: "inPerson2M" },
      { analyticType: "salvations2M" },
      { analyticType: "baptized2M" },
      { analyticType: "readingBible2M" },
      { analyticType: "attending2M" },
      { analyticType: "multiplying2M" },
      { analyticType: "impactMetrics2M" },
    ],
  },
  {
    name: "6 Month",
    rows: [
      { analyticType: "surveysSent6M" },
      { analyticType: "surveysDelivered6M", subType: "surveysDeliveredRate6M" },
      { analyticType: null },
      { analyticType: "inPerson6M" },
      { analyticType: "salvations6M" },
      { analyticType: "baptized6M" },
      { analyticType: "readingBible6M" },
      { analyticType: "attending6M" },
      { analyticType: "multiplying6M" },
      { analyticType: "impactMetrics6M" },
    ],
  },
  {
    name: "12 Month",
    rows: [
      { analyticType: "surveysSent12M" },
      {
        analyticType: "surveysDelivered12M",
        subType: "surveysDeliveredRate12M",
      },
      { analyticType: null },
      { analyticType: "inPerson12M" },
      { analyticType: "salvations12M" },
      { analyticType: "baptized12M" },
      { analyticType: "readingBible12M" },
      { analyticType: "attending12M" },
      { analyticType: "multiplying12M" },
      { analyticType: "impactMetrics12M" },
    ],
  },
  {
    name: "Total Survey",
    rows: [
      { analyticType: "surveysSentTotal" },
      {
        analyticType: "surveysDeliveredTotal",
        subType: "surveysDeliveredRate",
      },
      { analyticType: null },
      { analyticType: "inPersonSurvey" },
      { analyticType: "salvationsSurvey" },
      { analyticType: "baptizedSurvey" },
      { analyticType: "readingBibleSurvey" },
      { analyticType: "attendingSurvey" },
      { analyticType: "multiplyingSurvey" },
      { analyticType: "impactMetricsSurvey" },
    ],
  },
  {
    name: "Total Manual",
    rows: [
      { analyticType: null },
      { analyticType: null },
      { analyticType: null },
      { analyticType: "inPersonManual" },
      { analyticType: "salvationsManual" },
      { analyticType: "baptizedManual" },
      { analyticType: "readingBibleManual" },
      { analyticType: "attendingManual" },
      { analyticType: "multiplyingManual" },
      { analyticType: "impactMetricsManual" },
    ],
  },
  {
    name: "Total",
    rows: [
      { analyticType: "surveysSentTotal" },
      {
        analyticType: "surveysDeliveredTotal",
        subType: "surveysDeliveredRate",
      },
      {
        analyticType: "uniqueImpactMetrics",
        subType: "uniqueImpactMetricsRate",
      },
      { analyticType: "inPersonTotal" },
      { analyticType: "salvationsTotal" },
      { analyticType: "baptizedTotal" },
      { analyticType: "readingBibleTotal" },
      { analyticType: "attendingTotal" },
      { analyticType: "multiplyingTotal" },
      { analyticType: "impactMetricsTotal" },
    ],
  },
].map((column) => ({ ...column, rowMeta }));

type Metric =
  | "baptized"
  | "inPerson"
  | "attending"
  | "salvations"
  | "multiplying"
  | "readingBible"
  | "impactMetrics"
  | "surveysSent"
  | "surveysDelivered"
  | "uniqueImpactMetrics";

// type Source = "3D" | "2W" | "2M" | "6M" | "12M" | "Survey" | "Manual" | "Total";
type SourceType = {
  title: string;
};
const allSources: SourceType[] = [
  "3D",
  "2W",
  "2M",
  "6M",
  "12M",
  "Survey",
  "Manual",
].map((type) => {
  return { title: type };
});
const generateDependencies: (
  filters: Filters,
  dateRange: DateRange
) => Dependency[] = (filters, dateRange) => {
  const dependencies = columns.flatMap((column) => {
    return column.rows
      .filter((row) => !!row.analyticType)
      .flatMap(({ analyticType, subType = null }) => [
        { analyticType, filters, dateRange },
        ...(subType ? [{ analyticType: subType, filters, dateRange }] : []),
      ]);
  });
  return dependencies;
};

export const ConfigDrivenDataTableList: React.FC<
  ConfigDrivenDataTableListProps
> = (props) => {
  const [dateRange, setDateRange] = useState<DateRange>(
    dateRangePresets[props.config.initialRangeKey].getDateRange
  );

  const [filters, setFilters] = useState(
    props.config.shouldInheritPageFilters
      ? props.pageFilters
      : props.config.filters
  );

  useEffect(() => {
    if (props.config.shouldInheritPageFilters) {
      setFilters(props.pageFilters);
    }
  }, [props.pageFilters, props.config.shouldInheritPageFilters]);
  /** get initial dependencies */

  const [dependencies, setDependencies] = useState(() =>
    generateDependencies(filters, dateRange)
  );
  /**
   * for this component, we'll want to update the date range for
   * all dependencies unilaterally when the date range changes
   */
  useEffect(() => {
    if (dateRange) {
      setDependencies(
        dependencies.map((dependency) => {
          return { ...dependency, dateRange: dateRange };
        })
      );
    }
    //eslint-disable-next-line
  }, [dateRange]);
  useEffect(() => {
    setDependencies(
      dependencies.map((dependency) => {
        return { ...dependency, filters };
      })
    );
    //eslint-disable-next-line
  }, [filters]);

  const queryOptions = useMemo(() => {
    return dependencies.map((dependency) => {
      return {
        queryKey: ["analytic", dependency],
        queryFn: async () => {
          return await getDependencies([dependency]);
        },
      };
    });
  }, [dependencies]);
  const queries = useQueries(queryOptions);

  return (
    <Card.Wrapper>
      <Card.Body>
        <Dropdowns>
          <DateRangeDropDown
            initialRangeKey={props.config.initialRangeKey}
            handleDateRangeChange={(range) => setDateRange(range)}
          />
        </Dropdowns>
        {!queries.every(({ isFetched }) => isFetched) ? (
          DataTablePlaceholder({ numRows: columns[0].rows.length + 1 })
        ) : (
          <ScrollingDataTableContainer
            data={dependencyDataToTableData(queries)}
          ></ScrollingDataTableContainer>
        )}
      </Card.Body>
    </Card.Wrapper>
  );
};

const dependencyDataToTableData: (queries: QueryObserverResult[]) => {
  //SurveyReturnType) => {
  [key: string]: DataTableProps;
} = (queries) => {
  const data = queries.reduce((acc, result) => {
    const data = result?.data?.[0];
    return { ...acc, ...data.analytics };
  }, {});
  const getRowData: (
    bin: string,
    rowMetaData: typeof rowMeta[0]
  ) => DataTableRow["data"] = (bin, rowMetaData) => {
    return columns.reduce((acc, { rowMeta, rows }) => {
      const validRowIndex = rowMeta.findIndex(
        ({ type }) => type === rowMetaData.type
      );
      const nextCell: DataTableRow["data"][0] = {
        value: data?.[rows[validRowIndex].analyticType]?.[bin] || "--",
      };
      if (!!rowMetaData.subType) {
        nextCell.secondaryValue =
          data?.[rows[validRowIndex].subType]?.[bin] || "--";
      }
      return [...acc, nextCell];
    }, [] as DataTableRow["data"]);
  };
  const getRow: (metric: Metric, bin: string) => DataTableRow = (
    metric,
    bin
  ) => {
    const rowMetaData = rowMeta.find(({ type }) => type === metric);
    const rowData = getRowData(bin, rowMetaData);
    const total = rowData[rowData.length - 1];
    const row: DataTableRow = {
      data: rowData.slice(0, -1),
      title: rowMetaData.name,
      total,
      tooltipText: rowMetaData.tooltipText,
    };
    return row;
  };
  const getDataTablePropsForBin: (bin: string) => DataTableProps = (bin) => {
    const rows: DataTableProps["rows"] = rowMeta.reduce((acc, metric) => {
      return [...acc, getRow(metric.type, bin)];
    }, [] as DataTableRow[]);
    return {
      columnHeaders: allSources,
      rowHeader: "METRIC",
      rows,
      noScroll: true,
    };
  };

  const tableData: { [key: string]: DataTableProps } = Object.keys(
    Object.values(data || {})?.[0] || {}
  )
    .reverse()
    .reduce((acc, bin) => {
      return { ...acc, [bin]: getDataTablePropsForBin(bin) };
    }, {});
  return tableData;
};
const Factory = (props) => ({
  Component: ({ key, pageFilters, loading }) =>
    ConfigDrivenDataTableList({ ...props, key, pageFilters }),
  getInitialDependencies: () => [],
});
export default Factory;
