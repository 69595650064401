import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "components/form/legacy";
import DropdownFilterMenuCategory from "components/pageFilter/pageFilterMenu/dropdownMenuFilterCategory/dropdownMenuFilterCategory";
import { useBusinessMappings } from "hooks/useBusinessMappings";
import { useState } from "react";
import {
  FilterRow,
  Filters,
  FiltersButtons,
  FiltersHeader,
  FiltersHeaderToggle,
  FiltersWrapper,
} from "./styles";

export type MapFilterTitle = "Date Range" | "KPI Type" | "country";

export type FilterOptionProps = {
  title: MapFilterTitle;
  defaultDropdownOption: string;
  dropdownOptions: string[];
  filterComponent?: "DropdownCheckbox";
};

export type MapSidebarFiltersProps = {
  filterOptions: FilterOptionProps[];
  currentFilters: { [title: string]: string };
  onFiltersChange: (title: string, value: string) => void;
  onFiltersClear: () => void;
  dashboardMode: boolean;
  toggleDashboardMode: () => void;
  dashboardModeCanBeEnabled?: boolean;
  countryFilter?: {
    [key: string]: boolean;
  };
  setCountryFilter?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
};

const MapSidebarFilters: React.FC<MapSidebarFiltersProps> = ({
  filterOptions,
  currentFilters,
  onFiltersChange,
  onFiltersClear,
  dashboardMode,
  dashboardModeCanBeEnabled = true,
  toggleDashboardMode,
  countryFilter,
  setCountryFilter,
}) => {
  const mapTerms = useBusinessMappings();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <FiltersWrapper>
      <FiltersHeader>
        <h3>Filters</h3>
        <FiltersHeaderToggle
          className={`indicator ${isOpen ? "open" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon
            icon={(isOpen ? faChevronDown : faChevronRight) as IconProp}
          />
        </FiltersHeaderToggle>
      </FiltersHeader>

      {isOpen && (
        <Filters>
          {filterOptions.map((option) => (
            <FilterRow key={option.title}>
              <Form.Label>{option.title}</Form.Label>
              <Form.Dropdown
                dropdownClassName="filter-dropdown"
                options={option.dropdownOptions}
                category={option.title}
                value={currentFilters[option.title]}
                onChange={(value: string) =>
                  onFiltersChange(option.title, value)
                }
                mapper={mapTerms}
              />
            </FilterRow>
          ))}
          {countryFilter && (
            <DropdownFilterMenuCategory
              values={countryFilter}
              category={"country"}
              onChange={(key, value: boolean) => {
                setCountryFilter((prevFilters) => ({
                  ...prevFilters,
                  [key]: value,
                }));
              }}
              onAllChange={(value: boolean) => {
                setCountryFilter({
                  ...Object.entries(countryFilter).reduce(
                    (acc, [k, v]) => ({ ...acc, [k]: value }),
                    {}
                  ),
                })
              }}
            />
          )}

          <FiltersButtons>
            <button className="btn btn-clear" onClick={onFiltersClear}>
              Clear Filters
            </button>
            {dashboardModeCanBeEnabled && (
              <button
                className={`btn btn-dashboard-mode`}
                onClick={toggleDashboardMode}
              >
                {dashboardMode ? "Stop" : "Start"} Dashboard Mode
              </button>
            )}
          </FiltersButtons>
        </Filters>
      )}
    </FiltersWrapper>
  );
};

export default MapSidebarFilters;
