import { ProfileClose, ProfileHeader, ProfileHeaderText } from "../styles";
import {
  CreatedAt,
  PrayerWallProfile,
  ProfileInfo,
} from "./prayerWallSeekerProfile.styles";

type SharingOption = "everyone" | "anonymous" | "private";

export type PrayerWallEntry = {
  uuid: string;
  name?: string;
  email: string;
  phone?: string;
  sharing_option: SharingOption;
  prayer_request: string;
  send_email_updates?: boolean;
  prayed_for_count: number;
  coords?: [number, number];
  created_at: string;
  nickname: string;
};

export type PrayerWallSeekerProfileProps = {
  selectedProfile: PrayerWallEntry;
  leaving: boolean;
  dashboardMode: boolean;
  setDashboardMode: (val: boolean) => void;
  handleLeave: () => void;
};

const PrayerWallSeekerProfile: React.FC<PrayerWallSeekerProfileProps> = ({
  selectedProfile,
  leaving,
  dashboardMode,
  setDashboardMode,
  handleLeave,
}) => {
  return (
    <PrayerWallProfile
      className={`${leaving && "leaving"}`}
      onClick={() => {
        dashboardMode && setDashboardMode(false);
      }}
    >
      <ProfileClose style={{ zIndex: 1 }} onClick={handleLeave}>
        Close
      </ProfileClose>
      <ProfileHeader>
        <ProfileHeaderText>Prayer Request</ProfileHeaderText>
      </ProfileHeader>
      <ProfileInfo>
        <h3>
          {selectedProfile.nickname ?? selectedProfile.name ?? "Anonymous"}
        </h3>
        <p>{selectedProfile.prayer_request}</p>
        <CreatedAt>{formatCreatedAt(selectedProfile.created_at)}</CreatedAt>
      </ProfileInfo>
    </PrayerWallProfile>
  );
};

const formatCreatedAt = (createdAt: string) => {
  let date = new Date(createdAt);

  let month = date.toLocaleString("default", { month: "long" });

  return `${month} ${date.getUTCDate()}, ${date.getFullYear()}`;
};

export default PrayerWallSeekerProfile;
