import ComponentPlaceholder from "../componentPlaceholder";

type DataTablePlaceholderProps = {
  numRows: number;
};

const DataTablePlaceholder: React.FC<DataTablePlaceholderProps> = ({
  numRows,
}) => {
  let rows = Array.apply(null, Array(numRows)).map((v: never, i: number) => i);
  let height = (rows.length + 1) * 50;

  return <ComponentPlaceholder height={height} />;
};

export default DataTablePlaceholder;
