import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Container, Dropdown } from "./styles/form";
import FormCheckbox from "./checkbox";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export default function Form({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

type DropdownProps = {
  options: string[];
  value: any;
  onChange: any;
  mapper: (string: string) => string;
};
const FormDropdown: React.FC<DropdownProps> = (props) => (
  <Dropdown>
    <select
      value={props.value}
      onChange={(e) => !!e && props.onChange(e.target.value)}
    >
      {props.options.map((option) => (
        <option value={option} key={option}>
          {props.mapper(option)}
        </option>
      ))}
    </select>
    <FontAwesomeIcon icon={faChevronDown as IconProp} />
  </Dropdown>
);

Form.Dropdown = FormDropdown;
Form.Checkbox = FormCheckbox;
