import React from "react";
import {
  ConfigDrivenTotalCard,
  TotalCardConfig,
  getAllDependencies,
} from "../totalCard/configDrivenTotalCard";
import { DateRangeConfig, dateRangeFromConfig } from "../../utils/dateRange";
import { TotalCardRowWrapper } from "./styles";
import { Filters } from "hooks/globalContext";

export type TotalCardRowConfig = Omit<TotalCardConfig, "dateRange"> & {
  /** the date ranges to get totals for */
  dateRanges: {
    dateRangeConfig: DateRangeConfig;
    cardTitle: string;
  }[];
};

export type TotalCardRowProps = {
  config: TotalCardRowConfig;
  key: number;
  pageFilters: Filters;
  loading: boolean;
};
export const TotalCardRow: React.FC<TotalCardRowProps> = (props) => {
  /** an array of entries which correspond to one total card each */

  return (
    <TotalCardRowWrapper key={props.key}>
      {props.config.dateRanges.map((range, i) => (
        <ConfigDrivenTotalCard
          config={{
            ...props.config,
            dateRange: dateRangeFromConfig(range.dateRangeConfig),
            title: range.cardTitle,
          }}
          key={i}
          loading={props.loading}
          pageFilters={props.pageFilters}
        ></ConfigDrivenTotalCard>
      ))}
    </TotalCardRowWrapper>
  );
};
const Factory = (props: TotalCardRowProps) => {
  return {
    Component: ({ pageFilters, key, loading }) =>
      TotalCardRow({ ...props, key, pageFilters }),
    getInitialDependencies: () =>
      props.config.dateRanges.flatMap((range) => {
        return getAllDependencies(
          {
            ...props.config,
            dateRange: dateRangeFromConfig(range.dateRangeConfig),
            title: range.cardTitle,
          },
          props.config.shouldInheritPageFilters
            ? props.pageFilters
            : props.config.filters
            ? props.config.filters
            : {}
        );
      }),
  };
};
export default Factory;
