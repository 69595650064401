import {
  BusinessTermCategory,
  useBusinessMappings,
} from "hooks/useBusinessMappings";
import React from "react";
import styled from "styled-components";
import PageFilterMenuItem from "../pageFilterMenuItem";
const MenuCategory = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 17rem;

  h4 {
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    padding-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
    margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
    border-bottom: 1px solid ${({ theme }) => theme.colors?.["table-line"]};
  }
`;

const SelectAll = styled.h6`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors?.["primary-light"]};
  cursor: pointer;
  padding-top: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  border-top: 1px solid ${({ theme }) => theme.colors?.["table-line"]};

  &:hover {
    text-decoration: underline;
  }
`;

type PageFilterMenuCategoryProps = {
  category: string;
  values: {
    [key: string]: boolean;
  };
  onChange: (key: string, value: boolean) => void;
  onAllChange: (value: boolean) => void;
};

const PageFilterMenuCategory = ({
  category,
  values,
  onChange,
  onAllChange,
}: PageFilterMenuCategoryProps) => {
  const allSelected = Object.values(values).every((v) => v);
  const mapTerms = useBusinessMappings();
  const castedCategory: BusinessTermCategory = category as BusinessTermCategory;

  return (
    <MenuCategory>
      <h4>{mapTerms(category, castedCategory)}</h4>
      {Object.entries(values).map(([key, value]) => (
        <PageFilterMenuItem
          key={key}
          label={mapTerms(key, castedCategory)}
          checked={value}
          onChange={() => onChange(key, !value)}
        />
      ))}

      <SelectAll onClick={() => onAllChange(!allSelected)}>
        {allSelected ? "Deselect All" : "Select All"}
      </SelectAll>
    </MenuCategory>
  );
};

export default PageFilterMenuCategory;
