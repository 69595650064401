import { MetricConfig } from "configDrivenComponents/totalCard/configDrivenTotalCard";
import styled from "styled-components";
import Card from "../card";
import ComponentPlaceholder from "../componentPlaceholder";
import { Placeholder } from "../componentPlaceholder/styles";

export type TotalCardPlaceholderProps = {
  heading?: string;
  metrics: MetricConfig[];
};

const Wrapper = styled.div`
  ${Placeholder} {
    margin-bottom: ${({ theme }) => theme.utilities?.spacing["space-sm"]};
    border-radius: ${({ theme }) =>
      theme?.utilities?.borders?.["border-radius-md"]};

    &:last-child {
      margin: 0;
    }
  }
`;

const TotalCardPlaceholder = ({
  heading,
  metrics,
}: TotalCardPlaceholderProps) => {
  return (
    <Wrapper>
      <Card.BasicCard title={heading}>
        {metrics.map((metric, i: number) => (
          <ComponentPlaceholder
            height={metric.secondaryAnalytic ? 105 : 85}
            key={i}
          />
        ))}
      </Card.BasicCard>
    </Wrapper>
  );
};

export default TotalCardPlaceholder;
