const masks = [
  { x: 0, y: 0, width: 256, height: 256, mask: false },
  { x: 256, y: 0, width: 256, height: 256, mask: false },
  { x: 512, y: 0, width: 256, height: 256, mask: false },
];

const iconMapping = {
  connected: masks[1],
  unconnected: masks[0],
};

export { iconMapping };
