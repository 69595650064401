import getApiUrl from "utils/getApiUrl";

const API = getApiUrl(
  process.env.REACT_APP_ANALYTICS_API,
  "http://localhost:5000/api/v1/",
  true
);

export const getChangeLogAnalytics = async (
  range: string | object,
  types: string[],
  filters: object
) => {
  let rangeVal;
  if (typeof range === "string") {
    if (range === "Last 30 Days" || range === "30D") {
      rangeVal = "30D";
    } else if (range === "Last 12 Weeks" || range === "12W") {
      rangeVal = "12W";
    } else {
      rangeVal = "12M";
    }
  } else {
    rangeVal = range;
  }

  return makeRequest("POST", "analytics", {
    range: rangeVal,
    types,
    filters,
  });
};

const makeRequest = async (
  method: string,
  path: string,
  params: object = null
) => {
  return new Promise((resolve, reject) => {
    if (method === "GET") {
      let endpoint = API + path;
      if (params) {
        let queryString = "?";
        Object.keys(params).forEach((key) => {
          queryString += `${key}=${params[key]}&`;
        });
        endpoint = (endpoint + queryString).slice(0, -1);
      }
      fetch(endpoint).then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            resolve(result);
          });
        } else {
          reject(response.status);
        }
      });
    } else {
      fetch(API + path, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((result) => {
            resolve(result);
          });
        } else {
          reject(response.status);
        }
      });
    }
  });
};
