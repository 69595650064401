import styled from "styled-components";

const switcherHeight = "50px";

export const SwitcherWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 5;
`;

export const ActiveView = styled.div`
  margin-left: calc(
    ${({ theme }) => theme.utilities?.spacing?.["space-page"]} * 1.5
  );
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]}
    ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  background-color: ${({ theme }) => theme.colors?.["primary-light"]};
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-sm"]};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: 0.2s;
  overflow: hidden;
  cursor: pointer;
  color: white;
  width: fit-content;

  display: grid;
  grid-template-columns: 35px 1fr;
  grid-template-rows: auto auto;
  column-gap: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  grid-template-areas:
    "logo label arrow"
    "logo title arrow";
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

  img {
    grid-area: logo;
    filter: brightness(0) invert(1);
    width: 100%;
    justify-self: center;
    align-self: center;
  }

  .label {
    grid-area: label;
    font-size: 0.65rem;
    align-self: end;
    opacity: 0.8;
    font-weight: normal;
  }

  .title {
    grid-area: title;
    font-size: 1rem;
    align-self: start;
  }

  svg {
    grid-area: arrow;
    justify-self: center;
    align-self: center;
    transition: 0.2s;

    &.reversed {
      transform: rotate(180deg);
    }
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

export const SwitcherItemsWrapper = styled.div`
  height: calc(${switcherHeight} + 8px);
  padding-right: 8px;
  padding-bottom: 8px;
  overflow: hidden;
`;

export const SwitcherItems = styled.div`
  height: ${switcherHeight};
  background-color: ${({ theme }) => theme.colors?.["ui-gray-lighter"]};
  border-bottom-right-radius: ${({ theme }) =>
    theme?.utilities.borders["border-radius-sm"]};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  overflow: hidden;
  transform: scaleX(1);
  transform-origin: left center;
  transition: 0.3s ease;

  &.hidden {
    transform: translateX(-110%);
  }
`;

export const SwitcherItem = styled.div`
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  transition: 0.2s;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors?.["ui-gray-lighter"]};

  &:hover {
    background-color: ${({ theme }) => theme.colors?.["ui-gray"]};
  }

  img {
    width: 20px;
    margin-right: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  }
`;

export const SwitcherItemSpacer = styled.div`
  width: 1px;
  opacity: 0.25;
  height: 65%;
  background-color: ${({ theme }) => theme.colors?.["primary-darker"]};

  &:last-of-type {
    display: none;
  }
`;
