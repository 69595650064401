import { Placeholder, PlaceholderActivity } from "./styles";

type ComponentPlaceholderProps = {
  height: number | string;
};

const ComponentPlaceholder: React.FC<ComponentPlaceholderProps> = ({
  height,
}) => (
  <Placeholder style={{ height }}>
    <PlaceholderActivity />
  </Placeholder>
);

export default ComponentPlaceholder;
