import React, { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";
import DataTable, { DataTableProps } from "../dataTable";

const ScrollingContainer = styled(ScrollContainer)`
  width: calc(
    100vw - 100px - ${({ theme }) => theme.utilities.spacing["space-xl"]} -
      ${({ theme }) => theme.utilities.spacing["space-xl"]} -
      ${({ theme }) => theme.utilities.spacing["space-md"]} -
      ${({ theme }) => theme.utilities.spacing["space-md"]}
  );
  overflow-x: auto;
  display: flex;
  margin-top: ${({ theme }) => theme.utilities.spacing["space-md"]};
  background-color: ${({ theme }) => theme.colors?.["ui-gray-lighter"]};
  cursor: grab;

  &.grabbing {
    cursor: grabbing;
  }
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) =>
      theme.utilities.borders["border-radius-md"]};
    background: ${({ theme }) => theme.colors?.["ui-gray-dark"]};
    opacity: 0.4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) =>
      theme.utilities.borders["border-radius-md"]};
    background: ${({ theme }) => theme.colors?.["scrollbar"]};

    &:hover {
      background: ${({ theme }) => theme.colors?.["table-line"]};
    }
  }
`;

const TableWrapper = styled.div`
  margin-right: ${({ theme }) => theme.utilities.spacing["space-lg"]};
  padding-bottom: ${({ theme }) => theme.utilities.spacing["space-md"]};
  &:last-of-type {
    margin-right: 0;
  }
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.utilities.spacing["space-sm"]};

  h2 {
    position: sticky;
    left: 0;
    top: 0;
    white-space: nowrap;
    padding-right: ${({ theme }) => theme.utilities?.spacing["space-sm"]};
    background-color: ${({ theme }) => theme.colors?.["ui-gray-lighter"]};
  }
  .table-container__header-decoration {
    flex-grow: 1;
    background-color: ${({ theme }) => theme.colors?.["table-line"]};
    height: 1px;
  }
`;

type ScrollingDataTableContainerProps = {
  data: {
    [key: string]: DataTableProps;
  };
};

const ScrollingDataTableContainer = ({
  data,
}: ScrollingDataTableContainerProps) => {
  const [isGrabbing, setIsGrabbing] = useState(false);
  return (
    <ScrollingContainer
      hideScrollbars={false}
      className={`table-list-container ${isGrabbing && "grabbing"}`}
      onStartScroll={() => setIsGrabbing(true)}
      onEndScroll={() => setIsGrabbing(false)}
    >
      {Object.entries(data).map(([key, tableProps]) => {
        return (
          <TableWrapper key={key}>
            <TableHeader>
              <h2>{key}</h2>
              <div className="table-container__header-decoration" />
            </TableHeader>
            <DataTable {...tableProps} noScroll />
          </TableWrapper>
        );
      })}
    </ScrollingContainer>
  );
};

export default ScrollingDataTableContainer;
