import MapSidebar from "../mapSidebar";
import {
  ReportingMapWrapper,
  SidebarTotalWrapper,
  SidebarViewSelector,
  SidebarViewSelectorItem,
} from "./styles";
import useReportingMapFilters, {
  SidebarProps,
} from "./hooks/useReportingMapFilters";
import InteractiveMap from "../interactive-map";
import { useState } from "react";
import useDashboardMode from "./hooks/useDashboardMode";
import useReportingMapData from "./hooks/useReportingMapData";
import useReportingMapLayers from "./hooks/useReportingMapLayers";
import { displayData, MapView, mapViews } from "./reportingMapConfig";
import Metric from "components/metric";
import { useAnalyticsFormatting } from "hooks/useAnalyticsFormatting";
import { Filters } from "hooks/globalContext";
import { ViewportProps } from "react-map-gl";
import renderReportingMapTooltip, {
  TooltipConfig,
} from "./reportingMapTooltip";

export type ReportingDataSource = "all" | "gmo" | "default";

type ReportingMapProps = {
  config: {
    title: string;
    sidebar: SidebarProps;
    availableMapViews?: MapView[];
    dataSource?: ReportingDataSource;
    hexSize?: number;
    useHexScaling?: boolean;
    skipDataScattering?: boolean;
    tooltip?: TooltipConfig;
  };
  pageFilters: Filters;
  loading: boolean;
  dashboardModeCanBeEnabled: boolean;
};

const ReportingMap: React.FC<ReportingMapProps> = ({
  config,
  pageFilters,
  loading,
  dashboardModeCanBeEnabled,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [mapView, setMapView] = useState(
    config.availableMapViews?.[0] ?? "default"
  );
  const [zoomLevel, setZoomLevel] = useState(1);

  const format = useAnalyticsFormatting();

  const filters = useReportingMapFilters(config.sidebar);
  const inheritedFilters = {
    ...pageFilters,
    country: Object.entries(filters.countryFilter)
      .map(([country, value]) => (value ? country : null))
      .filter((country) => !!country),
  };

  const mapData = useReportingMapData(
    selectedCountry,
    filters,
    inheritedFilters,
    config.dataSource ?? "default",
    loading,
    config.skipDataScattering
  );
  const { dashboardMode, toggleDashboardMode } = useDashboardMode(
    mapData.data,
    selectedCountry,
    setSelectedCountry
  );

  const typeFilter = filters.currentFilters["KPI Type"];
  const renderTooltip = ({ object }) =>
    renderReportingMapTooltip(object, config.tooltip, typeFilter, format);

  const layers = useReportingMapLayers(
    mapData.data,
    mapView,
    filters.currentFilters["KPI Type"],
    config.useHexScaling,
    zoomLevel
  );

  const countrySelected = !!selectedCountry;

  const calculateLoadedPercent = () => {
    let dataTotal = mapData.data.reduce((total, { count }) => total + count, 0);

    let stillLoading = mapData.loading || mapData.continueLoading;

    if (stillLoading) {
      if (dataTotal === 0) {
        return "0% Loaded";
      } else {
        return `${((dataTotal / mapData.total) * 100).toFixed(1)}% Loaded`;
      }
    } else {
      return "";
    }
  };

  const calculateFilteredTotal = () => {
    return mapData?.filteredData.reduce((total, obj) => {
      return total + obj.count;
    }, 0);
  };

  const displayMapViews = config.availableMapViews
    ? mapViews.filter(({ value }) => config.availableMapViews.includes(value))
    : mapViews;

  const onViewStateChange = (viewState: ViewportProps) => {
    let newZoom = Math.floor(viewState.zoom);
    if (newZoom !== zoomLevel) {
      setZoomLevel(newZoom);
    }
  };

  return (
    <ReportingMapWrapper>
      <InteractiveMap
        title={config.title}
        loading={mapData.loading}
        continueLoading={mapData.continueLoading}
        layers={[layers]}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        renderTooltip={renderTooltip}
        mapView={mapView}
        onViewStateChange={onViewStateChange}
      >
        <Metric
          icon={displayData[typeFilter].icon}
          title={`${!countrySelected ? "World" : selectedCountry}: ${
            displayData[typeFilter].text
          }`}
          value={countrySelected ? calculateFilteredTotal() : mapData.total}
          secondaryValue={calculateLoadedPercent()}
          onClose={countrySelected ? () => setSelectedCountry(null) : null}
        />
      </InteractiveMap>
      <MapSidebar
        {...filters}
        dashboardMode={dashboardMode}
        toggleDashboardMode={toggleDashboardMode}
        dashboardModeCanBeEnabled={dashboardModeCanBeEnabled}
      >
        <SidebarTotalWrapper>
          <h3 className="type">Total {displayData[typeFilter].text}</h3>
          <h1 className="total">{format(mapData.total)}</h1>
        </SidebarTotalWrapper>
        <div style={{ flexGrow: 1 }} />
        <SidebarViewSelector>
          {displayMapViews.map(({ name, value }) => (
            <SidebarViewSelectorItem
              className={mapView === value && "active"}
              onClick={() => setMapView(value)}
              key={value}
            >
              {name}
            </SidebarViewSelectorItem>
          ))}
        </SidebarViewSelector>
      </MapSidebar>
    </ReportingMapWrapper>
  );
};

export default ReportingMap;
