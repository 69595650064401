import { PropsWithChildren } from "react";
import Spinner from "../spinner/spinner";
import {
  Container,
  Body,
  BodyHeader,
  BodyContent,
  BodyFooter,
  Title,
  TitleDecoration,
  SubTitle,
  Text,
  Heading,
  SubHeading,
  // ViewLink,
  IconWrapper,
  InnerCard,
  Label,
  Image,
  Wrapper,
  Button,
  SpinnerWrapper,
  MetricsWrapper,
  WrapperProps,
  BodyProps,
  ContianerProps,
} from "./styles/Card";
type CardProps = PropsWithChildren<ContianerProps>;
export default function Card({ children, ...restProps }: CardProps) {
  return <Container {...restProps}>{children}</Container>;
}

Card.Heading = function CardHeading({ children, ...restProps }) {
  return <Heading {...restProps}>{children}</Heading>;
};

Card.SubHeading = function CardSubHeading({ children, ...restProps }) {
  return <SubHeading {...restProps}>{children}</SubHeading>;
};

Card.Title = function CardTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};
Card.TitleDecoration = function CardTitleDecoration({
  children,
  ...restProps
}) {
  return <TitleDecoration {...restProps}>{children}</TitleDecoration>;
};

Card.SubTitle = function CardSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>;
};

Card.Text = function CardText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

Card.Label = function CardLabel({ children, ...restProps }) {
  return <Label {...restProps}>{children}</Label>;
};

Card.ViewLink = function CardLink({ children, ...restProps }) {
  // return <ViewLink {...restProps}>{children}</ViewLink>;
};

Card.IconWrapper = function CardIconWrapper({ children, ...restProps }) {
  return <IconWrapper {...restProps}>{children}</IconWrapper>;
};
export type CardBodyProps = PropsWithChildren<BodyProps>;
Card.Body = function CardBody({ children, ...restProps }: CardBodyProps) {
  return <Body {...restProps}>{children}</Body>;
};

Card.MetricsWrapper = function CardMetricsWrapper({ children, ...restProps }) {
  return <MetricsWrapper {...restProps}>{children}</MetricsWrapper>;
};

Card.BodyHeader = function CardBodyHeader({ children, ...restProps }) {
  return <BodyHeader {...restProps}>{children}</BodyHeader>;
};

Card.BodyContent = function CardBodyContent({ children, ...restProps }) {
  return <BodyContent {...restProps}>{children}</BodyContent>;
};

Card.BodyFooter = function CardBodyFooter({ children, ...restProps }) {
  return <BodyFooter {...restProps}>{children}</BodyFooter>;
};

Card.InnerCard = function CardInnerCard({ children, ...restProps }) {
  return <InnerCard {...restProps}>{children}</InnerCard>;
};

Card.Image = function CardImage({ ...restProps }) {
  return <Image {...restProps} />;
};

type CardWrapperProps = PropsWithChildren<WrapperProps>;
Card.Wrapper = function CardWrapper({
  children,
  ...wrapperProps
}: CardWrapperProps) {
  return <Wrapper {...wrapperProps}>{children}</Wrapper>;
};
Card.Button = function CardButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
};

Card.Spinner = function CardSpinner({ text = "Loading...", ...restProps }) {
  return (
    <SpinnerWrapper {...restProps}>
      <Spinner />
    </SpinnerWrapper>
  );
};

Card.BasicCard = ({ title, children }) => {
  return (
    <Card>
      <Card.Heading>
        <Card.SubHeading>
          <Card.Title>{title}</Card.Title>
        </Card.SubHeading>
      </Card.Heading>
      <Card.MetricsWrapper>{children}</Card.MetricsWrapper>
    </Card>
  );
};
