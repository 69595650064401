import styled from "styled-components";

export const MapWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #172655;
`;

export const MapTotals = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000090;
`;

export const MapTotalsItem = styled.div`
  margin: 0 ${({ theme }) => theme?.utilities.spacing["space-xl"]};
  text-align: center;
  h5 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
  }
  h3 {
    color: white;
    font-size: 2rem;
  }
`;
