import styled from "styled-components";
export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  padding: ${({ theme }) => theme?.utilities.spacing["space-xl"]};
  gap: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
`;

export const PageWrapperNoStyles = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
`;
