import { useEffect, useState } from "react";
import CountryService from "services/country-service";

const useDashboardMode = (
  data: any[],
  selectedCountry: string,
  setSelectedCountry: (country: string) => void
) => {
  const [dashboardMode, setDashboardMode] = useState(false);
  const [dashboardTimeout, setDashboardTimeout] =
    useState<NodeJS.Timeout>(null);

  useEffect(() => {
    if (dashboardMode && !selectedCountry) {
      setDashboardMode(false);
      clearTimeout(dashboardTimeout);
    }
    //eslint-disable-next-line
  }, [selectedCountry]);

  const toggleDashboardMode = () => {
    setDashboardMode(!dashboardMode);
    if (!dashboardMode) {
      getNextCountry(selectedCountry);
    } else {
      clearTimeout(dashboardTimeout);
      setSelectedCountry(null);
    }
  };

  const getNextCountry = (previous) => {
    let current = CountryService.getRandomCountry(data, previous);
    setSelectedCountry(current);
    setDashboardTimeout(
      setTimeout(() => {
        getNextCountry(current);
      }, 15000)
    );
  };

  if (!dashboardMode && dashboardTimeout) {
    clearTimeout(dashboardTimeout);
  }

  return {
    dashboardMode,
    setDashboardMode,
    toggleDashboardMode,
  };
};

export default useDashboardMode;
