export const graphColumns = [
  {
    name: "Confirmed Connected",
    column: "confirmedConnected",
  },
  {
    name: "Auto Connection Rate",
    column: "autoConnectedRate",
  },
  {
    name: "Total Impact Metrics",
    column: "totalImpactMetrics",
  },
  {
    name: "Ad Click ",
    column: "adClicks",
  },
  {
    name: "Cost per Presentation",
    column: "costPerGospelPresentation",
  },
  {
    name: "Forms Completed",
    column: "formCompletions",
  },
  {
    name: "New Hosts Added",
    column: "hostsAdded",
  },
  {
    name: "Presentation Rate",
    column: "gospelPresentationRate",
  },
  {
    name: "Vetting Texts Rate",
    column: "vettingTextRate",
  },
  {
    name: "Manual Connected Rate",
    column: "manualConnectionRate",
  },
  {
    name: "Profiles Created Rate",
    column: "profileCreatedRate",
  },
  {
    name: "Digitally Connected Rate",
    column: "digitallyConnectedRate",
  },
];

export const graphTypes = graphColumns.map((x) => x.column);
export const graphOptions = graphColumns.map((x) => x.name);

export const changeGroupFilterItems = [
  "Showing All",
  "Marketing",
  "Organization",
  "Connect Teams",
  "Tech",
];

export const changeTypeFilterItems = [
  "Showing All",
  "Connect Team - Call Process",
  "Connect Team - Email Process",
  "Ad Spend - Google",
  "Ad Spend - Social",
  "UL Platform - Geo Location",
  "UL Platform - Code Change",
  "Pathway - Code Change",
  "Regional/Country Issue",
  "UL Reporting Dashboard",
];

export const kpiTypeFilterItems = [
  "Showing All",
  "Total Connections",
  "People Impacted",
  "Cost per Connection",
];

export const filterItems = [
  {
    label: "Change Group",
    value: "change_group",
    options: changeGroupFilterItems,
    type: "default",
  },
  {
    label: "KPI Type",
    value: "kpi_type",
    options: kpiTypeFilterItems,
    type: "default",
  },
  {
    label: "Date Select",
    values: ["start_date", "end_date"],
    valueLabels: ["Start Date", "End Date"],
    type: "date",
  },
  {
    label: "Keyword Search",
    value: "search",
    type: "search",
  },
];

export const toGraphKey = (date) => {
  if (typeof date === "string") {
    date = new Date(date);
  }

  return `${(date.getUTCMonth() + 1).toString().padStart(2, "0")}/${date
    .getUTCDate()
    .toString()
    .padStart(2, "0")}`;
};
