import { useState } from "react";
import {
  FilterOptionProps,
  MapFilterTitle,
  MapSidebarFiltersProps,
} from "../../mapSidebar/mapSidebarFilters/mapSidebarFilters";

export type ArcMapFilters = Omit<
  MapSidebarFiltersProps,
  "dashboardMode" | "toggleDashboardMode"
>;
type ProfileMapFiltersHook = () => ArcMapFilters;

const useArcMapFilters: ProfileMapFiltersHook = () => {
  const filterOptions = generateFilterOptions();

  const [currentFilters, setCurrentFilters] = useState(
    generateDefaultFiltersFromFilterOptions(filterOptions)
  );

  const onFiltersChange = (title: MapFilterTitle, value: string) => {
    setCurrentFilters({
      ...currentFilters,
      [title]: value,
    });
  };

  const onFiltersClear = () => {
    setCurrentFilters(generateDefaultFiltersFromFilterOptions(filterOptions));
  };

  return {
    filterOptions,
    currentFilters,
    onFiltersChange,
    onFiltersClear,
  };
};

export default useArcMapFilters;

const generateFilterOptions = (): FilterOptionProps[] => {
  let result = [] as FilterOptionProps[];
  result = [generateDateRangeFilterOptions()];
  return result;
};

const generateDateRangeFilterOptions = (): FilterOptionProps => {
  return {
    title: "Date Range",
    defaultDropdownOption: "All Time",
    dropdownOptions: [
      "Last 7 Days",
      "Last 30 Days",
      "Last 12 Weeks",
      "Last 12 Months",
      "Year To Date",
      "All Time",
    ],
  };
};

const generateDefaultFiltersFromFilterOptions = (
  filterOptions: FilterOptionProps[]
) => {
  let filters = {} as MapSidebarFiltersProps["currentFilters"];
  for (let option of filterOptions) {
    filters[option.title] = option.defaultDropdownOption;
  }
  return filters;
};
