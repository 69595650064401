import { useEffect, useState } from "react";

let dashboardModeIndex = 0;

const useDashboardMode = (
  profiles: any[],
  selectedCountry: string,
  setSelectedProfile: Function
) => {
  const [dashboardMode, setDashboardMode] = useState(false);
  const [dashboardTimeout, setDashboardTimeout] = useState(null);
  const [dashboardTimeoutStep2, setDashboardTimeoutStep2] = useState(null);
  const [dashboardTimeoutStep3, setDashboardTimeoutStep3] = useState(null);
  const [leaving, setLeaving] = useState(false);

  const testimonyTypeIndex = 2;
  let profilesWithTestimony = [];

  useEffect(() => {
    if (dashboardMode && !selectedCountry) {
      setDashboardMode(false);
      clearTimeout(dashboardTimeout);
    }
    //eslint-disable-next-line
  }, [selectedCountry]);

  const toggleDashboardMode = () => {
    setDashboardMode(!dashboardMode);
    if (!dashboardMode) {
      dashboardModeIndex = 0;
      setProfilesWithTestimony();
      getNextProfile();
    } else {
      clearTimeout(dashboardTimeout);
      clearTimeout(dashboardTimeoutStep2);
      clearTimeout(dashboardTimeoutStep3);
      setLeaving(false);
    }
  };

  const sortFunction = (a, b) => {
    if (a.position === null && b.position !== null) {
      return 1;
    }
    if (b.position === null && a.position !== null) {
      return -1;
    }
    if (a.position === null && b.position === null) {
      return 0;
    }
    return a.position - b.position;
  };

  const setProfilesWithTestimony = () => {
    profilesWithTestimony = profiles
      .filter((x) => x.typeIndex === testimonyTypeIndex)
      .sort(sortFunction);
  };

  const nextDashboardModeIndex = () =>
    dashboardModeIndex >= profilesWithTestimony.length - 1
      ? 0
      : dashboardModeIndex + 1;

  const getNextProfile = () => {
    if (profilesWithTestimony.length > 0) {
      setLeaving(false);
      let current = profilesWithTestimony[dashboardModeIndex];
      dashboardModeIndex = nextDashboardModeIndex();
      setSelectedProfile(current);
      setDashboardTimeout(setTimeout(firstStepAnimation, 10000));
    } else {
      setDashboardMode(false);
    }
  };

  const firstStepAnimation = () => {
    setLeaving(true);
    setDashboardTimeoutStep2(setTimeout(secondStepAnimation, 350));
  };

  const secondStepAnimation = () => {
    setSelectedProfile(null);
    setDashboardTimeoutStep3(setTimeout(thirdStepAnimation, 2000));
  };

  const thirdStepAnimation = () => {
    getNextProfile();
  };
  if (
    !dashboardMode &&
    (dashboardTimeout || dashboardTimeoutStep2 || dashboardTimeoutStep3)
  ) {
    clearTimeout(dashboardTimeout);
    clearTimeout(dashboardTimeoutStep2);
    clearTimeout(dashboardTimeoutStep3);
  }

  return {
    dashboardMode,
    setDashboardMode,
    toggleDashboardMode,
    leaving,
    setLeaving,
  };
};

export default useDashboardMode;
