import styled from "styled-components";
export const DatePickerWrapper = styled.div`
  margin-right: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * + * {
    margin-left: ${({ theme }) => theme?.utilities?.spacing?.["space-xs"]};
  }
  margin-left: ${({ theme }) => theme?.utilities?.spacing?.["space-sm"]};
  height: 100%;
  width: 150px;
  .react-datepicker__input-container {
    height: 100%;
    width: 150px;
    input {
      border-radius: ${({ theme }) =>
        theme?.utilities?.borders?.["border-radius-md"]};
      border: 0;
      background-color: ${({ theme }) => theme?.colors?.["ui-blue"]};
      box-shadow: inset 0 1px 2px var(--box-shadow);
      font-family: inherit;
      font-size: 1rem;
      padding: 0.6rem ${({ theme }) => theme?.utilities?.spacing?.["space-xs"]};
      color: ${({ theme }) => theme?.colors?.["text-default"]};
      width: 100%;

      background-color: white;
      border: 1px solid ${({ theme }) => theme?.colors?.["text-default"]};
      font-size: 1.125rem;

      &::placeholder {
        color: ${({ theme }) => theme?.colors?.["text-default"]};
        opacity: 0.6;
      }
    }
  }
`;
