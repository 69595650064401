import React from "react";
import { FixedColumn, FixedColumnCell, FixedColumnTotalCell } from "./styles";
import { DataTableRow } from "../types/dataTableRow";
import { fixedColumnType } from "../types/fixedColumnType";
import DataTableFixedCell from "./dataTableFixedCell";
import { RowHeaderHeightType } from "../dataTable";
interface DataTableFixedColumnProps {
  title: string;
  rows: DataTableRow[];
  columnType: fixedColumnType;
  rowHeaderHeight?: RowHeaderHeightType;
  totalPosition?: "bottom" | "right";
}

const fixedColumnTypeProps: {
  [key: string]: {
    valueAccessor: string;
    showTooltip: boolean;
  };
} = {
  metric: {
    valueAccessor: "title",
    showTooltip: true,
  },
  total: {
    valueAccessor: "total",
    showTooltip: false,
  },
};

const parseValues = (
  row: DataTableRow,
  valueAccessor: string
): { value: string; secondaryValue?: string } => {
  let initialValue = row[valueAccessor];
  if (initialValue?.value) {
    return initialValue;
  } else
    return {
      value: initialValue,
    };
};

const DataTableFixedColumn = ({
  title,
  rows,
  rowHeaderHeight,
  columnType,
  totalPosition,
}: DataTableFixedColumnProps) => (
  <FixedColumn columnType={columnType}>
    <FixedColumnCell rowHeaderHeight={rowHeaderHeight}>{title}</FixedColumnCell>
    {rows
      ?.filter((r) => r.title !== "Totals")
      .map((row, i) => {
        let otherProps = fixedColumnTypeProps[columnType];

        return (
          <DataTableFixedCell
            key={i}
            {...parseValues(row, otherProps.valueAccessor)}
            tooltipText={row.tooltipText}
            showTooltip={otherProps.showTooltip}
            rowHasSubData={!!row?.total?.secondaryValue}
          />
        );
      })}
    {totalPosition === "bottom" && (
      <FixedColumnTotalCell>{"Totals"}</FixedColumnTotalCell>
    )}
  </FixedColumn>
);

export default DataTableFixedColumn;
