import styled, { keyframes } from "styled-components";

export const PlaceholderAnimation = keyframes`
    0%{
        left: -45%;
    }
    100%{
        left: 100%;
    }
`;

export const Placeholder = styled.div`
  width: 100%;
  border-radius: ${({ theme }) =>
    theme?.utilities?.borders?.["border-radius-lg"]};
  position: relative;
  background-color: #d3d3d3;
  overflow: hidden;
  opacity: 0.3;
`;

export const PlaceholderActivity = styled.div`
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  background-image: -moz-linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  background-image: -webkit-linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  animation: ${PlaceholderAnimation} 1s infinite;
  z-index: 45;
`;
