import { useState } from "react";
import Form from "components/form/legacy";
import DatePicker from "react-datepicker";
import { changeGroupFilterItems, kpiTypeFilterItems } from "../changeLogConfig";
import { createChangeLogEntry } from "services/apiService";
import { useNotifications } from "context/notificationContext";
import { ButtonWrapper, FormModal, FormWrapper } from "./changeLogForm.styles";

const defaultEntry = {
  title: "",
  notes: "",
  change_group: "",

  kpi_type: "",
  created_at: new Date(),
};

type ChangeLogFormProps = {
  onClose: (entry: any) => void;
};

const ChangeLogForm: React.FC<ChangeLogFormProps> = ({ onClose }) => {
  let [entry, setEntry] = useState(defaultEntry);

  const { pushNotification } = useNotifications();

  const updateEntry = (name, value) => {
    if (value === "Select") {
      value = "";
    }
    setEntry({ ...entry, [name]: value });
  };

  const trimFilterOptions = (options) => {
    return options.map((option) =>
      option === "Showing All" ? "Select" : option
    );
  };

  const checkSubmitEnabled = () => {
    let { title, notes, change_group, kpi_type } = entry;
    return (
      title.length > 1 &&
      notes.length > 1 &&
      //owner !== "Select" &&
      change_group !== "" &&
      kpi_type !== ""
    );
  };

  const handleSubmit = async () => {
    let result = await createChangeLogEntry(entry);
    pushNotification("Change Log Entry Created.");
    onClose(result);
  };

  let submitDisabled = !checkSubmitEnabled();

  return (
    <FormModal>
      <div className="page__section">
        <FormWrapper>
          <Form.InputContainer className="title">
            <Form.Label>Action Taken</Form.Label>
            <Form.Input
              value={entry.title}
              onChange={(e) => {
                updateEntry("title", e.target.value);
              }}
            />
          </Form.InputContainer>
          <Form.InputContainer className="notes">
            <Form.Label>Notes</Form.Label>
            <Form.TextArea
              value={entry.notes}
              onChange={(e) => {
                updateEntry("notes", e.target.value);
              }}
            />
          </Form.InputContainer>
          <Form.InputContainer className="change-group">
            <Form.Label>Change Group</Form.Label>
            <Form.Dropdown
              dropdownClassName="change-dropdown"
              value={entry.change_group}
              options={trimFilterOptions(changeGroupFilterItems)}
              onChange={(value) => {
                updateEntry("change_group", value);
              }}
            />
          </Form.InputContainer>
          <Form.InputContainer className="kpi-type">
            <Form.Label>KPI Type</Form.Label>
            <Form.Dropdown
              dropdownClassName="change-dropdown"
              value={entry.kpi_type}
              options={trimFilterOptions(kpiTypeFilterItems)}
              onChange={(value) => {
                updateEntry("kpi_type", value);
              }}
            />
          </Form.InputContainer>

          <Form.InputContainer className="created-at">
            <Form.Label>Date Changed</Form.Label>
            <DatePicker
              selected={entry.created_at}
              onChange={(date) => updateEntry("created_at", date)}
              popperPlacement="top"
            />
          </Form.InputContainer>
          <ButtonWrapper>
            <button
              className={`btn btn-primary ${submitDisabled ? "disabled" : ""}`}
              disabled={submitDisabled}
              onClick={handleSubmit}
            >
              Create Entry
            </button>
            <button className="btn btn-outline" onClick={() => onClose(null)}>
              Cancel
            </button>
          </ButtonWrapper>
        </FormWrapper>
      </div>
    </FormModal>
  );
};

export default ChangeLogForm;
