import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  h1 {
    flex: 1;
  }

  h3 {
    margin-right: ${({ theme }) => theme?.utilities.spacing["space-md"]};
    span {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: ${({ theme }) => theme?.colors["primary-light"]};
      }
    }
  }
`;
