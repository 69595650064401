import { DateFilterRow } from "./styles";
import {
  Filters,
  FiltersButtons,
  FiltersHeader,
  FiltersHeaderToggle,
  FiltersWrapper,
} from "../mapSidebar/mapSidebarFilters/styles";
import Form from "components/form/legacy";
import ReactDatePicker from "react-datepicker";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import DropdownFilterMenuCategory from "components/pageFilter/pageFilterMenu/dropdownMenuFilterCategory/dropdownMenuFilterCategory";

type HostNetworkMapFiltersProps = {
  startDate: string;
  endDate: string;
  setStartDate: (value: string) => void;
  setEndDate: (value: string) => void;
  countryFilter?: {
    [key: string]: boolean;
  };
  setCountryFilter?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  resetFilters: () => void;
};

const HostNetworkMapFilters: React.FC<HostNetworkMapFiltersProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  countryFilter,
  setCountryFilter,
  resetFilters,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <FiltersWrapper>
      <FiltersHeader>
        <h3>Filters</h3>
        <FiltersHeaderToggle
          className={`indicator ${isOpen ? "open" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon
            icon={(isOpen ? faChevronDown : faChevronRight) as IconProp}
          />
        </FiltersHeaderToggle>
      </FiltersHeader>
      {isOpen && (
        <Filters>
          <DateFilterRow>
            <Form.Label>Start Date</Form.Label>
            <ReactDatePicker
              selected={new Date(startDate)}
              onChange={(date) => setStartDate((date as Date).toISOString())}
              popperPlacement="bottom"
            />
          </DateFilterRow>

          <DateFilterRow>
            <Form.Label>End Date</Form.Label>
            <ReactDatePicker
              selected={new Date(endDate)}
              onChange={(date) => setEndDate((date as Date).toISOString())}
              popperPlacement="bottom"
            />
          </DateFilterRow>

          {countryFilter && (
            <DropdownFilterMenuCategory
              values={countryFilter}
              category={"country"}
              onChange={(key, value: boolean) => {
                setCountryFilter((prevFilters) => ({
                  ...prevFilters,
                  [key]: value,
                }));
              }}
              onAllChange={(value: boolean) => {
                setCountryFilter({
                  ...Object.entries(countryFilter).reduce(
                    (acc, [k, v]) => ({ ...acc, [k]: value }),
                    {}
                  ),
                })
              }}
            />
          )}

          <FiltersButtons>
            <button className="btn btn-clear" onClick={resetFilters}>
              Clear Filters
            </button>
          </FiltersButtons>
        </Filters>
      )}
    </FiltersWrapper>
  );
};

export default HostNetworkMapFilters;
