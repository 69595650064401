import styled from "styled-components";

export const MetricWrapper = styled.div`
  padding: ${({ theme }) => theme?.utilities?.spacing?.["space-sm"]};
  position: relative;
  display: grid;
  grid-template-columns: 50px 1fr auto;
  grid-template-areas:
    "icon title close"
    "icon value close"
    "icon secondary close";
  align-items: center;
  column-gap: ${({ theme }) => theme?.utilities?.spacing?.["space-sm"]};
  background-color: ${({ theme }) => theme.colors?.["ui-blue"]};
  margin-bottom: ${({ theme }) => theme?.utilities?.spacing?.["space-sm"]};
  border-radius: ${({ theme }) =>
    theme?.utilities?.borders?.["border-radius-md"]};
  box-shadow: 0px 2px 4px rgba(22, 38, 77, 0.2);
  overflow-wrap: break-all;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  &.no-secondary {
    /* prettier-ignore */
    padding-top: calc(${({ theme }) =>
      theme?.utilities?.spacing?.["space-md"]} * 0.8);
    /* prettier-ignore */
    padding-bottom: calc(${({ theme }) =>
      theme?.utilities?.spacing?.["space-md"]} * 0.8);
  }

  .metric-icon {
    grid-area: icon;
    position: relative;
    justify-self: center;
    > svg {
      display: block;

      &:first-of-type {
        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.25));
      }
    }
    > svg.svg-inline--fa {
      width: 18px;
      height: 18px;
      color: ${({ theme }) => theme?.colors?.["ui-gray"]};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      box-shadow: none;
    }
  }

  .title {
    grid-area: title;
    color: ${({ theme }) => theme?.colors?.["text-dark"]};
    opacity: 0.6;
    word-wrap: break-word;
    white-space: normal;
    margin-right: auto;
  }

  .value {
    grid-area: value;
    @media screen and (min-width: 2400px) {
      font-size: 2.5rem;
    }
    word-wrap: break-word;
  }

  .secondary-value {
    grid-area: secondary;
    opacity: 0.7;
    font-size: 0.75rem;
    word-wrap: break-word;
    white-space: normal;
  }

  .close-btn {
    grid-area: close;
    cursor: pointer;

    &:hover {
      filter: brightness(0.6);
    }
  }
`;
