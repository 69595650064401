import styled from "styled-components";

const sidebarWidth = "420px";

export const SidebarWrapper = styled.div`
  position: absolute;
  z-index: 5;
  height: 100%;
  top: 0;
  right: -${sidebarWidth};
  transition: 0.3s ease;

  &.open {
    right: 0;
  }
`;

export const SidebarToggle = styled.button`
  position: absolute;
  top: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  width: 4rem;
  height: 3rem;
  left: -4rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 5;
`;

export const Sidebar = styled.div`
  width: ${sidebarWidth};
  height: 100%;
  background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 5;
  display: flex;
  flex-direction: column;

  > * {
    transition: 0.3s ease;
    &.seeker-selected {
      transform: translateX(-100%);
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-md"]};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: ${({ theme }) => theme?.colors?.["ui-gray-dark"]};
    opacity: 0.4;
    box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0) inset;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-md"]};
    background: ${({ theme }) => theme?.colors?.["scrollbar"]};

    &:hover {
      background: ${({ theme }) => theme?.colors?.["table-line"]};
    }
  }
`;
