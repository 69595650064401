export const iconMapping = {
  gospelPresentations: { x: 0, y: 0, width: 256, height: 256, mask: false },
  indicatedDecisions: { x: 256, y: 0, width: 256, height: 256, mask: false },
};

export const displayTotals = [
  {
    name: "Gospel Visits",
    color: "#2957CC",
    value: "gospelPresentations",
  },
  {
    name: "Indicated Decisions",
    color: "#FDA533",
    value: "indicatedDecisions",
  },
];
