import { usePartner } from "hooks/globalContext";
import { useEffect, useState } from "react";
import { getYTDTotals, getYTDReporting } from "services/apiService";
import CountryService from "services/country-service";

type TotalsObject = {
  gospelPresentations: number;
  indicatedDecisions: number;
};

type YearToDateMapData = {
  loading: boolean;
  data: any[];
  setData: Function;
  timer: number;
  totals: TotalsObject;
  pastTotals: TotalsObject;
};

// adding more time to offset, fixes bug with fetching GMO data
// fetching at the present time (Date.now()) returns 0 data
const numberOfMillisecondsInADay = 86400000;
const INTERVAL = 1000 * 60 * 60 * 6 + numberOfMillisecondsInADay;
const getStartTime = () => Date.now() - INTERVAL;

const getEmptyDataRefreshTime = () => Date.now() - INTERVAL / 2;
const TYPES = {
  gospelPresentations: "gospelPresentations",
  indicatedDecisions: "indicatedDecisions",
};

const useYearToDateMapData = (): YearToDateMapData => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [timer, setTimer] = useState(0);
  const [nextUpdate, setNextUpdate] = useState(0);
  const [totals, setTotals] = useState({
    gospelPresentations: 0,
    indicatedDecisions: 0,
  });
  const [pastTotals, setPastTotals] = useState({
    gospelPresentations: 0,
    indicatedDecisions: 0,
  });

  const { partner } = usePartner();

  const getRequestPartnerName = () =>
    partner?.name === "unitelife" ? "all" : partner.name;

  const loadStartingData = async () => {
    let startTime = getStartTime();
    let [currentTotals, newData] = await Promise.all([
      getYTDTotals(getRequestPartnerName()) as Promise<TotalsObject>,
      getYTDReporting(startTime, getRequestPartnerName()) as Promise<any[]>,
    ]);
    currentTotals = {
      gospelPresentations:
        Math.abs(currentTotals.gospelPresentations +
        newData.filter((x) => x.type === TYPES.gospelPresentations).length),
      indicatedDecisions:
        Math.abs(currentTotals.indicatedDecisions +
        newData.filter((x) => x.type === TYPES.indicatedDecisions).length),
    };
    setPastTotals(currentTotals);
    setData(CountryService.parseLocationData(newData));
    //First update will use median timestamp
    setNextUpdate(
      newData[Math.floor(newData.length / 2)]?.timestamp ??
        getEmptyDataRefreshTime()
    );
    moveTimer();
    setLoading(false);
  };

  const loadNewData = async (timer: number) => {
    const newData = (await getYTDReporting(
      timer,
      getRequestPartnerName()
    )) as any[];
    let removedData = data.filter((d) => d.timestamp < timer);

    setData(CountryService.parseLocationData(newData));
    setPastTotals({
      gospelPresentations:
        pastTotals.gospelPresentations +
        removedData.filter((d) => d.type === TYPES.gospelPresentations).length,
      indicatedDecisions:
        pastTotals.indicatedDecisions +
        removedData.filter((d) => d.type === TYPES.indicatedDecisions).length,
    });
  };

  useEffect(() => {
    loadStartingData();
    //eslint-disable-next-line
  }, []);

  const moveTimer = () => {
    const renderInterval = 100;
    setTimer(Date.now() - INTERVAL);
    setTimeout(() => {
      moveTimer();
    }, renderInterval);
  };

  useEffect(() => {
    //Calculate Totals
    const allDisplayed = data.filter((d) => d.timestamp <= timer);
    setTotals({
      gospelPresentations:
        pastTotals.gospelPresentations +
        allDisplayed.filter((d) => d.type === TYPES.gospelPresentations).length,
      indicatedDecisions:
        pastTotals.indicatedDecisions +
        allDisplayed.filter((d) => d.type === TYPES.indicatedDecisions).length,
    });

    //Potentially update data
    if (timer > nextUpdate && nextUpdate !== 0) {
      //Consecutive updates will use the last timestamp currently available
      setNextUpdate(
        data[data.length - 1]?.timestamp ?? getEmptyDataRefreshTime()
      );
      loadNewData(timer);
    }
    //eslint-disable-next-line
  }, [timer]);

  return {
    loading,
    data,
    setData,
    timer,
    totals,
    pastTotals,
  };
};

export default useYearToDateMapData;
