import MapSidebar from "../mapSidebar";
import { ArcMapWrapper, PrayerRequestWrapper } from "./styles";
import InteractiveMap from "../interactive-map";
import React, { useEffect, useState } from "react";
import { Filters } from "hooks/globalContext";
import useArcMapLayers from "./hooks/useArcMapLayers";
import useArcMapFilters from "./hooks/useArcMapFilters";
import PrayerWallSeekerProfile from "../seekerProfile/prayerWallSeekerProfile";
import useArcMapData from "./hooks/useArcMapData";
import { PrayerWallEntry } from "../seekerProfile/prayerWallSeekerProfile/prayerWallSeekerProfile";
import { MapKey } from "../seekerProfile/prayerWallSeekerProfile/prayerWallSeekerProfile.styles";

type ArcMapProps = {
  title: string;
  filters: Filters;
  loading: boolean;
  dashboardModeCanBeEnabled: boolean;
};

const ArcMap: React.FC<ArcMapProps> = ({
  title,
  filters: inheritedFilters,
  loading,
  dashboardModeCanBeEnabled,
}) => {
  const [filteredArcs, setFilteredArcs] = useState([]);
  const [arcAnimationCoef, setArcAnimationCoef] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [leaving, setLeaving] = useState(false);

  const filters = useArcMapFilters();
  const mapData = useArcMapData(filters, inheritedFilters, loading);

  const renderTooltip = ({ object }) => {
    if (!object || !object.uuid) {
      return null;
    }
    const { name } = object;
    return {
      html: `<div class="profile-tooltip">
               <h5>${name ?? "Anonymous"}</h5>
             </div>`,
      style: {
        backgroundColor: "transparent",
      },
    };
  };

  useEffect(() => {
    if (selectedItem) {
      let filtered = mapData.data.arcs.filter(
        (c) => c.arc.uuid === selectedItem.uuid
      );
      setFilteredArcs(filtered);
      setArcAnimationCoef(0.0001);
      setTimeout(() => {
        startArcAnimation();
      }, 500);
    } else {
      setFilteredArcs([]);
    }
    //eslint-disable-next-line
  }, [selectedItem, mapData.data]);

  const startArcAnimation = () => {
    setTimeout(() => animateArc(0), 100);
  };

  const animateArc = (coef: number) => {
    if (coef < 1) {
      coef += 0.1;
      setArcAnimationCoef(coef);
      setTimeout(() => {
        animateArc(coef);
      }, 50);
    }
  };

  const onProfileClick = (info: any) => {
    if (info.index >= 0) {
      let profile = mapData.data.profiles[info.index];
      setSelectedItem(profile);
    }
  };

  const layers = useArcMapLayers(
    mapData.data.profiles,
    filteredArcs,
    arcAnimationCoef,
    onProfileClick
  );

  const handleLeave = () => {
    setLeaving(true);
    setTimeout(() => {
      setSelectedItem(null);
      setLeaving(false);
    }, 350);
  };

  return (
    <ArcMapWrapper>
      <InteractiveMap
        title={title}
        loading={mapData.loading}
        layers={layers}
        renderTooltip={renderTooltip}
        canSelectCountry={false}
        selectedCountry={null}
        setSelectedCountry={() => {}}
        mapView="hex3d"
      ></InteractiveMap>
      <MapSidebar
        {...filters}
        dashboardMode={false}
        toggleDashboardMode={() => {}}
        dashboardModeCanBeEnabled={dashboardModeCanBeEnabled}
      />
      <MapKey>
        <div className="map-key-item">Prayer Request Submitted:</div>
        <div className={`item-color orange`} />
        <div className="map-key-item">Prayed For a Request:</div>
        <div className={`item-color white`} />
      </MapKey>

      {!!selectedItem && (
        <PrayerRequestWrapper>
          <PrayerWallSeekerProfile
            selectedProfile={selectedItem as unknown as PrayerWallEntry}
            dashboardMode={false}
            setDashboardMode={() => {}}
            leaving={leaving}
            handleLeave={handleLeave}
          />
        </PrayerRequestWrapper>
      )}
    </ArcMapWrapper>
  );
};

export default ArcMap;
