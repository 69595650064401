import { Header } from "./styles";
import PageFilter from "../pageFilter";
import { FilterOptionCategory, Filters } from "hooks/globalContext";
import { CategoryUpdateData } from "hooks/usePageFilters";

type PageHeaderProps = {
  title: string;
  name: string;
  logoutFunction: () => void;
  filterUpdateFunction: (updates: CategoryUpdateData[]) => void;
  filterOptionCategories: FilterOptionCategory[];
  currentFilters: Filters;
};

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  name,
  logoutFunction,
  filterOptionCategories,
  filterUpdateFunction,
  currentFilters,
}) => {
  return (
    <Header>
      <h1>{title}</h1>
      <h3>
        Logged in as {name} (<span onClick={logoutFunction}>Log Out</span>)
      </h3>
      {filterOptionCategories?.length > 0 && (
        <PageFilter
          categories={filterOptionCategories}
          currentFilters={currentFilters}
          onUpdate={(updates) => {
            filterUpdateFunction(updates);
          }}
        />
      )}
    </Header>
  );
};
// const mergeFiltersIntoCategories = (
//   filters: Filters,
//   categories: { [key: string]: string[] }
// ) => {
//   const accumulator = {
//     ...categories,
//   };
//   Object.keys(filters).forEach((key) => {
//     accumulator[key] = [
//       ...new Set([...(filters?.[key] || []), ...(categories?.[key] || [])]),
//     ];
//   });
//   return accumulator;
// };

export default PageHeader;
