import React, { useEffect, useRef } from "react";
import { GraphWrapper } from "./style";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
} from "recharts";
export type AreaGraphProps = {
  /**
   * the height of the area graph
   */
  height?: number;
  /**
   * the number of pixels per data point that will get the necessary
   * width of the graph
   */
  graphWidthScale?: number;
  /**
   * the width of the wrapper component
   */
  width: number;
  /**
   * the data to display from
   */
  data: { [key: string]: number | string }[];
  /**
   * the key to access from each object in the data array
   */
  activeDataKey: string;
  /**
   *  the label to use in the tooltip on mouse hover
   */
  tooltipLabel: string;

  formatTooltipValue: (value: string) => string;
};
const AreaGraph: React.FC<AreaGraphProps> = (props) => {
  /**
   * the requested width is the scale times the number of data points to display
   */
  const requestedAreaChartWidth =
    (props.graphWidthScale || 80) * props.data.length;
  /** however we do not want data width less than it's wrapper */
  const minimumAreaChartWidth = props.width - 60;
  //so fallback to minimum if requested does not fill this criteria
  const areaChartWidth =
    requestedAreaChartWidth >= minimumAreaChartWidth
      ? requestedAreaChartWidth
      : minimumAreaChartWidth;

  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
    //eslint-disable-next-line
  }, [props.data, containerRef]);

  return (
    <GraphWrapper width={props.width} height={props.height}>
      <ScrollContainer
        className="scroll-container"
        hideScrollbars={false}
        innerRef={containerRef}
      >
        <AreaChart
          width={areaChartWidth}
          height={props.height}
          data={props.data}
          margin={{
            top: 10,
            right: 30,
            left: 30,
            bottom: 0,
          }}
        >
          <CartesianGrid />
          <XAxis
            dataKey="key"
            interval="preserveStart"
            tickCount={1 * props.data.length}
            height={50}
            tick={<AxisTick />}
          />
          <YAxis
            domain={["auto", "auto"]}
            tickCount={4}
            tickFormatter={(value) => props.formatTooltipValue(value)}
          />
          <Tooltip
            formatter={(value: string) => [
              props.formatTooltipValue(value),
              props.tooltipLabel,
            ]}
          />
          <Area
            type="linear"
            dataKey={props.activeDataKey}
            stroke="#2957CC"
            strokeWidth={2}
            fill="#2957CC25"
            dot={{ stroke: "#2957CC", strokeWidth: 4 }}
          />
        </AreaChart>
      </ScrollContainer>
    </GraphWrapper>
  );
};
const AxisTick = ({
  x,
  y,
  payload,
}: {
  x?: number;
  y?: number;
  payload?: { value: number };
}) => (
  <g transform={`translate(${x},${(y || 0) + 10})`}>
    <text x={0} y={0} dy={16} textAnchor="middle" fill="#1f356e">
      {payload.value}
    </text>
  </g>
);
export default AreaGraph;
