import "mapbox-gl/dist/mapbox-gl.css";
import DeckGL, { FlyToInterpolator } from "deck.gl";
import { StaticMap, ViewportProps } from "react-map-gl";
import { useEffect, useRef, useState } from "react";
import ZoomControl from "./zoom-control";
import { MapLoading, MapLoadingSmall, MapTitle, MapWrapper } from "./styles";
import useMapControls from "./hooks/useMapControls";
import { useView } from "hooks/globalContext";
import Spinner from "components/spinner";

type InteractiveMapProps = {
  title: string;
  loading: boolean;
  continueLoading?: boolean;
  layers: any[];
  selectedCountry: string;
  canSelectCountry?: boolean;
  setSelectedCountry: (country: string) => void;
  hasZoomControl?: boolean;
  selectedItem?: any;
  mapView?: string;
  renderTooltip: ({ object }: { object: any }) => any;
  dashboardMode?: boolean;
  onViewStateChange?: (viewState: ViewportProps) => void;
  mapType?: string;
  zoomedCountry?: string;
};

const InteractiveMap: React.FC<InteractiveMapProps> = ({
  title,
  loading,
  continueLoading,
  layers,
  selectedCountry,
  setSelectedCountry,
  canSelectCountry = true,
  hasZoomControl = true,
  selectedItem = null,
  mapView = "default",
  renderTooltip = () => {},
  dashboardMode,
  children,
  onViewStateChange,
  mapType,
  zoomedCountry = null,
}) => {
  const mapRef = useRef();

  const [highlightedCountry, setHighlightedCountry] = useState(null);
  const [mapLayers, setMapLayers] = useState([]);

  const { currentView } = useView();

  const {
    viewState,
    setViewState,
    userViewState,
    setUserViewState,
    moveToSelectedItem,
    moveToCountry,
    onItemHover,
    countryLayer,
  } = useMapControls(
    highlightedCountry,
    setHighlightedCountry,
    selectedCountry,
    setSelectedCountry,
    canSelectCountry
  );

  // If parther have country in component config by default move to the country only when mapType is profile.
  useEffect(() => {
    if (
      !loading &&
      (zoomedCountry ||
        (currentView?.filters?.country?.length && mapType === "profile"))
    ) {
      setTimeout(() => {
        moveToCountry(zoomedCountry || currentView?.filters?.country[0]);
      }, 3000);
    }
    //eslint-disable-next-line
  }, [loading, currentView]);

  useEffect(() => {
    setMapLayers(
      layers.map(
        ({ LayerClass, hoverTypeName, ...layerConfig }) =>
          new LayerClass({
            ...layerConfig,
            onHover: ({ object }) => onItemHover(object, hoverTypeName),
          })
      )
    );
    //eslint-disable-next-line
  }, [layers]);

  useEffect(() => {
    if (selectedCountry) {
      moveToCountry(selectedCountry);
    }
    //eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedItem) {
      setUserViewState(viewState);
      moveToSelectedItem(selectedItem);
    } else {
      setViewState(zoomOutViewState());
    }
    //eslint-disable-next-line
  }, [selectedItem]);

  useEffect(() => {
    if (mapView !== "hex3d") {
      setViewState({
        ...viewState,
        bearing: 0,
        pitch: 0,
        minPitch: 0,
        maxPitch: 0,
      });
    } else {
      setViewState({
        ...viewState,
        pitch: 60,
        minPitch: 60,
        maxPitch: 60,
      });
    }
    //eslint-disable-next-line
  }, [mapView]);

  const zoomOutViewState = () => {
    if (userViewState !== null && !dashboardMode) {
      return {
        ...userViewState,
        width: window.innerWidth,
        height: window.innerHeight,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1500,
      };
    } else {
      return {
        minZoom: 1.75,
        latitude: 25,
        longitude: 0,
        zoom: 1.75,
        width: window.innerWidth,
        height: window.innerHeight,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1500,
      };
    }
  };

  const handleViewStateChange = (viewState: any) => {
    onViewStateChange && onViewStateChange(viewState);
    setViewState(viewState);
  };

  return (
    <MapWrapper>
      <MapTitle>
        <h2>{title}</h2>
      </MapTitle>
      <DeckGL
        viewState={viewState}
        onViewStateChange={(e: any) => handleViewStateChange(e.viewState)}
        controller={true}
        layers={[countryLayer, ...mapLayers]}
        ref={mapRef}
        getCursor={({ isDragging, isHovering }) =>
          isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
        }
        getTooltip={renderTooltip}
      >
        <StaticMap
          reuseMaps
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          mapStyle={process.env.REACT_APP_MAPBOX_STYLE_URL}
          preventStyleDiffing={true}
        />
      </DeckGL>
      {hasZoomControl && (
        <ZoomControl viewport={viewState} setViewport={handleViewStateChange} />
      )}
      {children}
      {loading && (
        <MapLoading>
          <Spinner />
        </MapLoading>
      )}
      {continueLoading && (
        <MapLoadingSmall>
          <Spinner />
        </MapLoadingSmall>
      )}
    </MapWrapper>
  );
};

export default InteractiveMap;
