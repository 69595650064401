import styled from "styled-components";

export const ListWrapper = styled.div`
  width: 100%;
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  padding-bottom: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  border-bottom: 1px solid #1f356e20;
`;

export const ListPageControl = styled.div`
  display: flex;
  align-items: center;
  .page-btn {
    background-color: ${({ theme }) => theme?.colors["secondary-default"]};
    margin-left: ${({ theme }) => theme?.utilities.spacing["space-xs"]};
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2px;
    border: 2px solid #f29a27;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &:not(.disabled):hover {
      filter: brightness(0.9);
    }

    &.disabled {
      opacity: 0.75;
      cursor: default;
    }

    svg {
      width: 50%;
      height: 50%;
    }
  }
`;

export const List = styled.div``;

export const ListItem = styled.div`
  padding: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  border-bottom: 1px solid #1f356e20;

  button {
    width: 100%;
    margin-top: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
    font-size: 0.9rem;
    color: #eff3fc;
  }
`;

export const ListItemHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  border-bottom: 1px solid #1f356e20;
  margin-bottom: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  letter-spacing: 2px;
`;

export const ListItemIndex = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme?.colors["secondary-default"]};
  border: 2px solid #f29a27;
  font-size: 0.75rem;
  font-weight: bold;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  text-align: center;
  margin-right: ${({ theme }) => theme?.utilities.spacing["space-xs"]};
`;

export const ListItemInfoRow = styled.div`
  display: flex;
  padding: ${({ theme }) => theme?.utilities.spacing["space-xs"]} 0;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;

  span {
    opacity: 0.5;
    letter-spacing: 2px;
  }

  .divider {
    flex: 1;
    margin: 0 0.25rem;
    border-bottom: 1px dashed #1f356e20;
  }
`;
