import styled from "styled-components";
const dotWidth = "50px";
const lineWidth = "3px";
const animationTime = "3s";
const animationTiming = "cubic-bezier(0.175, 0.885, 0.32, 1.275)";
export const SpinnerWrapper = styled.div`
  width: 150px;
  height: 150px;
  position: relative;

  .dot {
    position: absolute;
    width: ${dotWidth};
    height: ${dotWidth};
    border-radius: 50%;
    background-color: #2957cc;

    &.first {
      top: ${dotWidth};
      left: ${dotWidth};
      animation: ${animationTime} ${animationTiming} 0s infinite
        center-pulse-first alternate;
    }
    &.second {
      top: ${dotWidth};
      right: ${dotWidth};
      animation: ${animationTime} ${animationTiming} 0s infinite
        center-pulse-second alternate;
    }
    &.third {
      bottom: ${dotWidth};
      right: ${dotWidth};
      animation: ${animationTime} ${animationTiming} 0s infinite
        center-pulse-third alternate;
    }
    &.fourth {
      bottom: ${dotWidth};
      left: ${dotWidth};
      background-color: #fda533;
      z-index: 5;
      animation: ${animationTime} ${animationTiming} 0s infinite
        center-pulse-fourth alternate;
    }
    .line {
      position: absolute;
      background-color: #2957cc;
      bottom: 25px;
      left: 25px;

      &.horizontal {
        height: ${lineWidth};
        width: 100px;
        animation: ${animationTime} ${animationTiming} 0s infinite
          draw-horizontal alternate;
      }
      &.vertical {
        width: ${lineWidth};
        height: 100px;
        animation: ${animationTime} ${animationTiming} 0s infinite draw-vertical
          alternate;
      }
      &.diagonal {
        width: ${lineWidth};
        height: 125px;
        transform: rotate(45deg);
        transform-origin: right bottom;
        animation: ${animationTime} ${animationTiming} 0s infinite draw-diagonal
          alternate;
      }
    }
  }

  @keyframes center-pulse-first {
    0%,
    10% {
      top: ${dotWidth};
      left: ${dotWidth};
    }
    25%,
    100% {
      top: 0;
      left: 0;
    }
  }

  @keyframes center-pulse-second {
    0%,
    25% {
      top: ${dotWidth};
      right: ${dotWidth};
    }
    40%,
    100% {
      top: 0;
      right: 0;
    }
  }

  @keyframes center-pulse-third {
    0%,
    40% {
      bottom: ${dotWidth};
      right: ${dotWidth};
    }
    55%,
    100% {
      bottom: 0;
      right: 0;
    }
  }

  @keyframes center-pulse-fourth {
    0%,
    55% {
      bottom: ${dotWidth};
      left: ${dotWidth};
    }
    70%,
    100% {
      bottom: 0;
      left: 0;
    }
  }

  @keyframes draw-vertical {
    0%,
    70% {
      height: 0;
    }
    80% {
      height: 100px;
    }
  }

  @keyframes draw-horizontal {
    0%,
    70% {
      width: 0;
    }
    80% {
      width: 100px;
    }
  }

  @keyframes draw-diagonal {
    0%,
    70% {
      height: 0;
    }
    80% {
      height: 125px;
    }
  }
`;
