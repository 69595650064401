import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stream } from "@cloudflare/stream-react";
import { Testimony } from "../types";
import {
  TestimonyBackButton,
  TestimonyContainer,
  TestimonyWrapper,
} from "./styles";
import { CloudflareVideo } from "@unitelife/cloudflare";

type SeekerTestimonyProps = {
  testimonyType: "html" | "video";
  testimony: Testimony;
  handleBack: () => void;
};

const SeekerTestimony: React.FC<SeekerTestimonyProps> = ({
  testimonyType,
  testimony,
  handleBack,
}) => {
  console.log(testimony);
  return (
    <TestimonyWrapper>
      <TestimonyBackButton>
        <FontAwesomeIcon
          onClick={handleBack}
          icon={faArrowLeft as IconProp}
          size="lg"
        />
      </TestimonyBackButton>
      {testimonyType === "html" ? (
        <TestimonyContainer
          dangerouslySetInnerHTML={{ __html: testimony.text }}
        />
      ) : (
        <TestimonyContainer className="video">
          <CloudflareVideo id={testimony.testimony_video_id} />
        </TestimonyContainer>
      )}
    </TestimonyWrapper>
  );
};

export default SeekerTestimony;
