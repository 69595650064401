import styled, { StyledProps } from "styled-components";
// import { Link } from "react-router-dom";
export type ContianerProps = {
  className?: string;
};
export const Container = styled.article`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  &.messages {
    .message-container {
      margin-bottom: 1.5rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
export interface WrapperProps {
  /**
   * the css flex direction to apply. defaults to column
   */
  flexDirection?: "row" | "column";
  /**
   * the optional width of the container
   */
  width?: string;
}
export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: WrapperProps) =>
    flexDirection === "row" ? "row" : "column"};
  align-items: ${({ flexDirection }: WrapperProps) =>
    flexDirection === "row" ? "center" : null};
  width: ${({ width }: WrapperProps) => width};

  &.control-group {
    flex-direction: column;
    @media screen and (min-width: 576px) {
      flex-direction: ${({ flexDirection }) =>
        flexDirection === "row" ? "row" : "column"};
    }

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme?.utilities?.spacing?.["space-sm"]};
`;

export const SubHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    margin-left: ${({ theme }) => theme?.utilities?.spacing?.["space-xs"]};
    color: ${({ theme }) => theme?.colors?.["secondary-default"]};
  }
`;

export const Title = styled.h2`
  white-space: nowrap;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 4px;
    margin-left: ${({ theme }) => theme?.utilities?.spacing?.["space-xs"]};
  }
`;

export const TitleDecoration = styled.div`
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme?.colors?.["table-line"]};
  opacity: 0.5;
  margin-top: 4px;
  margin-left: ${({ theme }) => theme?.utilities?.spacing?.["space-sm"]};
`;

export const SubTitle = styled.h3``;

export const Text = styled.p``;

export const Label = styled.label``;

// export const ViewLink = styled(Link)`
//   color:${({ theme }) => theme?.colors?.["text-link"]};
// `;

export const IconWrapper = styled.div`
  display: inline;
  margin-left: ${({ theme }) => theme?.utilities?.spacing?.["space-xs"]};
`;

export type BodyProps = {
  /**
   * optional specifier to set css padding
   */
  padding?: "none";
  className?: string;
};
export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 1px;
  // margin:${({ theme }) => theme?.colors?.["space-sm"]} 0;
  background-color: ${({ theme }) => theme?.colors?.["ui-gray-lighter"]};
  box-shadow: ${({ theme }) =>
    theme?.utilities?.layout?.["box-shadow"] +
    " " +
    theme?.colors?.["box-shadow-color"]};
  border-radius: ${({ theme }) =>
    theme?.utilities?.borders?.["border-radius-lg"]};

  overflow: hidden;
  padding: ${({ padding }: BodyProps) => (padding === "none" ? "0" : "1.5rem")};

  @media screen and (min-width: 576px) and (max-width: 1200px) {
    padding: ${({ padding, theme }) =>
      padding === "none" ? "0" : theme.utilities.spacing["space-md"]};
  }

  @media screen and (min-width: 1200px) {
    padding: ${({ padding, theme }) =>
      padding === "none" ? "0" : theme.utilities.spacing["space-md"]};
  }
`;

export const MetricsWrapper = styled(Body)`
  @media screen and (min-width: 2800px) {
    flex-direction: row;
    justify-content: space-between;
    .metric {
      width: 100%;
      margin: 0;
      margin-right: ${({ theme }) => theme?.colors?.["space-md"]};
      .title,
      .secondary-value {
        font-size: 1rem;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
`;

export const BodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme?.utilities?.spacing?.["space-md"]};
  flex-wrap: wrap;

  .control-group + .control-group {
    margin-top: ${({ theme }) => theme?.utilities?.spacing?.["space-sm"]};
  }

  @media screen and (min-width: 576px) {
    flex-direction: row;
    margin-bottom: ${({ theme }) => theme?.utilities?.spacing?.["space-md"]};
    .control-group + .control-group {
      margin-top: 0;
    }
  }
`;

export const BodyContent = styled.div`
  > * + * {
    margin-top: ${({ theme }) => theme?.utilities?.spacing?.["space-sm"]};
    @media screen and (min-width: 1200px) {
      margin-top: 1.5rem;
    }
  }
`;

export const BodyFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  margin-top: auto;
`;

export type InnerCardProps = {
  backgroundColor?: string;
};
export const InnerCard = styled.div`
  background-color: ${({
    theme,
    backgroundColor,
  }: StyledProps<InnerCardProps>) =>
    backgroundColor || theme?.colors?.["ui-blue"]};
  padding: ${({ theme }) => theme?.utilities?.spacing?.["space-md"]} 1.5rem;
  /* border-radius: ${({ theme }) =>
    theme?.utilities?.borders?.["border-radius-md"]};
  box-shadow: ${({ theme }) =>
    theme?.utilities?.layout?.["box-shadow-sm"] +
    " " +
    theme?.colors?.["box-shadow-color-sm"]}; */

  @media screen and (min-width: 1200px) {
    padding: ${({ theme }) => theme?.utilities?.spacing?.["space-md"]};
  }

  &.subgroup {
    padding: 0;
    box-shadow: none;
    @media screen and (min-width: 992px) {
      padding: ${({ theme }) => theme?.utilities?.spacing?.["space-md"]} 1.5rem;
      box-shadow: ${({ theme }) =>
        theme?.utilities?.layout?.["box-shadow-sm"] +
        " " +
        theme?.colors?.["box-shadow-color-sm"]};
    }
    .subgroup__header {
      padding: ${({ theme }) => theme?.utilities?.spacing?.["space-sm"]} 1.5rem;
      box-shadow: ${({ theme }) =>
        theme?.utilities?.layout?.["box-shadow-sm"] +
        " " +
        theme?.colors?.["box-shadow-color-sm"]};
      border-top-left-radius: ${({ theme }) =>
        theme?.utilities?.layout?.["border-radius-md"]};
      border-top-right-radius: ${({ theme }) =>
        theme?.utilities?.layout?.["border-radius-md"]};
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 400;
      font-size: 1rem;
      z-index: 1;
      position: relative;
      margin-bottom: 0;

      @media screen and (min-width: 992px) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 0;
        box-shadow: none;
        padding-bottom: ${({ theme }) =>
          theme?.utilities?.spacing?.["space-sm"]};
        border-bottom: 1px solid ${({ theme }) => theme?.colors?.["table-line"]};
      }
    }
    .subgroup__body {
      padding: ${({ theme }) => theme?.utilities?.spacing?.["space-md"]} 1.5rem;
      background-color: ${({ theme }) => theme?.colors?.["ui-blue-dark"]};
      box-shadow: ${({ theme }) =>
        theme?.utilities?.layout?.["box-shadow-sm"] +
        " " +
        theme?.colors?.["box-shadow-color-sm"]};
      border-bottom-left-radius: ${({ theme }) =>
        theme?.utilities?.borders?.["border-radius-md"]};
      border-bottom-right-radius: ${({ theme }) =>
        theme?.utilities?.borders?.["border-radius-md"]};

      @media screen and (min-width: 992px) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0;
        margin-top: 1.5rem;
        background-color: ${({ theme }) => theme?.colors?.["ui-blue"]};
        box-shadow: none;
      }

      > .subgroup-item:last-child {
        margin-bottom: 0;
      }
      .subgroup-item {
        &:not(:last-of-type) {
          margin-bottom: 1.5rem;
          border-bottom: 1px solid
            ${({ theme }) => theme?.colors?.["table-line"]};
          padding-bottom: ${({ theme }) =>
            theme?.utilities?.spacing?.["space-xs"]};

          @media screen and (min-width: 1200px) {
            // margin-bottom: 1.5rem;
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        .subgroup-item__heading {
          text-transform: uppercase;
          opacity: 60%;
          letter-spacing: 2px;
          margin-bottom: 6px;
        }
        .subgroup-item__content {
          display: block;
          font-weight: bold;
          line-height: 1.5;
        }
        @media screen and (min-width: 576px) and (max-width: 1200px) {
          justify-content: space-between;
          flex-direction: row;
          align-items: flex-end;
          margin-bottom: ${({ theme }) =>
            theme?.utilities?.spacing?.["space-xs"]};
          width: 100%;

          .subgroup-item__heading {
            // margin-bottom: 0;
            margin-right: ${({ theme }) =>
              theme?.utilities?.spacing?.["space-sm"]};
          }

          .subgroup-item__content {
            font-size: 1.125rem;
            margin-bottom: 7px;
            text-align: right;
          }
        }
        @media screen and (min-width: 768px) and (max-width: 1200px) {
          .subgroup-item__heading {
            margin-right: ${({ theme }) =>
              theme?.utilities?.spacing?.["space-lg"]};
          }
          .subgroup-item__content {
            font-size: 1.25rem;
            margin-bottom: 6px;
          }
        }
      }
      .subgroup-group {
        @media screen and (max-width: 576px) {
          display: flex;
          flex-direction: row;
          .subgroup-item {
            &:not(:last-of-type) {
              padding-right: ${({ theme }) =>
                theme?.utilities?.spacing?.["space-sm"]};
              width: 50%;
            }
          }
        }
        @media screen and (min-width: 1200px) {
          display: flex;
          flex-direction: row;
          .subgroup-item {
            &:not(:last-of-type) {
              padding-right: ${({ theme }) =>
                theme?.utilities?.spacing?.["space-sm"]};
              width: 50%;
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  cursor: pointer;
  height: 100%;
  object-fit: cover;
  padding: 0;
  margin: 0;
`;

export const Button = styled.button`
  display: inline-block;
  padding: 0.6rem 1.8rem;
  color: ${({ theme }) => theme?.colors?.["text-default"]};
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.5rem;
  outline: none;
  border: none;
  border-radius: 50px;
  transition: all 0.2s ease-in;
  text-align: center;
  box-sizing: border-box;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  margin: ${({ theme }) => {
    const margin = theme?.utilities?.spacing?.["space-md"];
    return `${margin * -1} 0 0 ${margin * -1}`;
  }};
  width: 100%;
  height: 100%;
  background-color: #ffffff90;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme?.utilities?.spacing?.["space-md"]} 0;
  z-index: 50;
`;
