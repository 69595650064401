import { Wrapper } from "./googleForm.styles";

const GoogleForms: React.FC = () => {
  return (
    <Wrapper>
      {/*eslint-disable-next-line*/}
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfvGXLjI9VYjPKWOZ78eREd2LtXtEA2anTpKLxohsge3pm1iQ/viewform?embedded=true"></iframe>
    </Wrapper>
  );
};
export default GoogleForms;
