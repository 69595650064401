import ChangeLogFilter from "./changeLogFilter";
import { useEffect, useState } from "react";
import ChangeList from "./changeList";
import ChangeLogGraph from "./changeLogGraph";
import { getChangelog } from "services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { graphColumns, graphTypes, toGraphKey } from "./changeLogConfig";
import { getChangeLogAnalytics } from "./getChangeLogAnalytics";
import { Header, Wrapper } from "./changeLog.styles";
import ChangeLogForm from "./changeLogForm";
import { parseDateOption } from "utils/dateRange";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Analytics = {
  [type: string]: {
    [date: string]: number;
  };
};

type TransformedAnalytic = {
  key: string;
  [type: string]: number | string;
};

const transformAnalytics = (analytics: Analytics): TransformedAnalytic[] => {
  let transformed = {};
  for (let [type, analytic] of Object.entries(analytics)) {
    for (let [date, value] of Object.entries(analytic)) {
      transformed[date] = {
        ...(transformed[date] ?? { key: date }),
        [type]: value,
      };
    }
  }

  return Object.values(transformed);
};

type AnalyticsResponse = {
  analytics: Analytics;
};

const defaultFilters = {
  change_group: "Showing All",
  kpi_type: "Showing All",
  start_date: null,
  end_date: null,
  search: "",
};

type ChangeLogProps = {
  config: any;
};

const ChangeLog: React.FC<ChangeLogProps> = () => {
  let [filters, setFilters] = useState(defaultFilters);
  let [entries, setEntries] = useState([]);
  let [changeListFilters, setChangeListFilters] = useState([]);
  let [graphData, setGraphData] = useState([]);
  let [graphType, setGraphType] = useState("Confirmed Connected");
  let [graphDate, setGraphDate] = useState("Day");
  let [loading, setLoading] = useState(true);

  const [showForm, setShowForm] = useState(false);

  const binWidthMapper = {
    Day: "daily",
    Week: "weekly",
    Month: "monthly",
  };

  const getAnalytics = async (dateRange) => {
    setLoading(true);
    let analyticsPromise = getChangeLogAnalytics(
      dateRange,
      graphTypes,
      {}
    ) as unknown as Promise<AnalyticsResponse>;
    let entriesPromise = getChangelog({ ...filters }) as Promise<any[]>;

    let [{ analytics }, entries] = await Promise.all([
      analyticsPromise,
      entriesPromise,
    ]);
    const ratingProperties = [
      "profileCreatedRate",
      "autoConnectedRate",
      "manualConnectionRate",
      "gospelPresentationRate",
      "vettingTextRate",
      "uniqueConnectionRate",
    ];
    const setMaxRatingValue = (analyticsRow: any) => {
      ratingProperties.forEach((element) => {
        analyticsRow[element] =
          analyticsRow[element] > 100 ? 100 : analyticsRow[element];
      });
      return analyticsRow;
    };

    let transformed = transformAnalytics(analytics);

    setEntries(entries);
    setGraphData(
      transformed.map((row) => {
        row = setMaxRatingValue(row);
        return {
          ...row,
          changes: entries.filter((x) => toGraphKey(x.created_at) === row.key)
            .length,
        };
      })
    );

    setLoading(false);
  };

  useEffect(() => {
    let range;
    if (filters.start_date) {
      range = {
        startDate: filters.start_date,
        endDate: filters.end_date ?? new Date(),
        binWidth: binWidthMapper[graphDate] ?? "daily",
      };
    } else {
      range = {
        startDate: parseDateOption("30-days-ago"),
        endDate: new Date(),
        binWidth: binWidthMapper[graphDate] ?? "daily",
      };
    }
    getAnalytics(range);

    //eslint-disable-next-line
  }, [filters, graphDate]);

  const onClearFiltersClick = () => {
    setFilters({ ...defaultFilters });
  };

  const onFilterRemove = (filter) => {
    setFilters({ ...filters, [filter]: defaultFilters[filter] });
  };

  const onGraphChangeClick = (payload) => {
    if (!changeListFilters.includes(payload.key)) {
      changeListFilters.push(payload.key);
      setChangeListFilters([...changeListFilters]);
    } else {
      onChangeFilterRemove(payload.key);
    }
  };

  const onChangeFilterRemove = (filter) => {
    changeListFilters = changeListFilters.filter((x) => x !== filter);
    setChangeListFilters([...changeListFilters]);
  };

  const onFormClose = (entry?: any) => {
    if (entry) {
      setEntries([entry, ...entries]);
    }
    setShowForm(false);
  };

  let graphColumn = graphColumns.find((x) => x.name === graphType);

  return (
    <Wrapper>
      <Header>
        Change Log
        <FontAwesomeIcon
          icon={faPlusCircle as IconProp}
          onClick={() => setShowForm(true)}
        />
      </Header>
      <ChangeLogFilter
        activeFilters={filters}
        defaultFilters={defaultFilters}
        onFilterRemove={onFilterRemove}
        onClearFiltersClick={onClearFiltersClick}
        onUpdateFiltersClick={setFilters}
      />
      <ChangeLogGraph
        data={loading ? [] : graphData}
        filterColumn={graphColumn}
        graphType={graphType}
        setGraphType={setGraphType}
        graphDate={graphDate}
        setGraphDate={setGraphDate}
        onItemClick={onGraphChangeClick}
        changeListFilters={changeListFilters}
      />

      <ChangeList
        changes={entries}
        filters={changeListFilters}
        onFilterRemove={onChangeFilterRemove}
        setLoading={setLoading}
        setEntries={setEntries}
      />
      {showForm && <ChangeLogForm onClose={onFormClose} />}
    </Wrapper>
  );
};

export default ChangeLog;
