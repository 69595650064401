import {
  faBible,
  faChurch,
  faCross,
  faFileAlt,
  faFileImport,
  faSitemap,
  faUserFriends,
  faWater,
  faDollarSign,
  faFire,
  faUsers,
  faPrayingHands,
  faHandHoldingHeart,
} from "@fortawesome/free-solid-svg-icons";

export const recurringOptionsConfig = {
  default: ["2W", "2M", "6M", "12M"],
  trinity: ["GT101", "GT201", "GT301", "GT401"],
};

export const impactMetricConfig = {
  default: {
    inPerson: {
      icon: faUserFriends,
      helpText: "Seeker has connected in-person with a host",
      businessMappingKey: "inPerson",
      type: "single",
    },
    salvation: {
      icon: faCross,
      helpText: "Seeker has prayed for salvation",
      businessMappingKey: "salvation",
      type: "single",
    },
    baptized: {
      icon: faWater,
      helpText: "Seeker has been baptized",
      businessMappingKey: "baptized",
      type: "single",
    },
    readingBible: {
      icon: faBible,
      helpText: "Seeker is reading the bible at least twice per week",
      businessMappingKey: "readingBible",
      type: "ongoing",
    },
    attending: {
      icon: faChurch,
      helpText: "Seeker is attending church at least twice per month",
      businessMappingKey: "attending",
      type: "ongoing",
    },
    multiplying: {
      icon: faSitemap,
      helpText: "Seeker is sharing Jesus with others",
      businessMappingKey: "multiplying",
      type: "ongoing",
    },
  },
  trinity: {
    formCompleted: {
      icon: faFileAlt,
      helpText: "Seeker has completed a form",
      businessMappingKey: "trinityFormCompleted",
      type: "single",
    },
    membership: {
      icon: faUserFriends,
      helpText: "Seeker has completed the membership process",
      businessMappingKey: "trinityMembership",
      type: "single",
    },
    giving: {
      icon: faDollarSign,
      helpText: "Seeker has given",
      businessMappingKey: "trinityGiving",
      type: "single",
    },
    baptism: {
      icon: faWater,
      helpText: "Seeker has been baptized",
      businessMappingKey: "trinityBaptism",
      type: "single",
    },
    holySpiritFilled: {
      icon: faFire,
      helpText: "Seeker has completed the membership process",
      businessMappingKey: "trinityHolySpiritFilled",
      type: "single",
    },
    inCommunity: {
      icon: faUsers,
      helpText: "Seeker has community",
      businessMappingKey: "trinityInCommunity",
      type: "single",
    },
    selfDeveloping: {
      icon: faPrayingHands,
      helpText: "Seeker has been in continuous self development",
      businessMappingKey: "trinitySelfDeveloping",
      type: "single",
    },
    reproducing: {
      icon: faHandHoldingHeart,
      helpText: "Seeker is discipling others",
      businessMappingKey: "trinityReproducing",
      type: "single",
    },
    nextStepsCompleted: {
      icon: faFileImport,
      helpText: "Seeker has completed these growth tracks",
      businessMappingKey: "trinityNextSteps",
      type: "ongoing",
    },
  },
};
