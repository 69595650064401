import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MetricWrapper } from "./metric.styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
interface MetricProps {
  /**
   * What icon should we display
   */
  icon?: Parameters<typeof FontAwesomeIcon>[0]["icon"];
  /**
   * The title Of the metric we are displaying
   */
  title?: string;
  /**
   * the value associated with this metric
   */
  value?: string | number;
  /**
   * the secondary value associated with this metric
   * eg, a rate related to the metric
   */
  secondaryValue?: string | number;
  /**
   * the fill color behind the metric icon
   */
  color?: string | null;
  /**
   * an optional onclose event handler
   */
  onClose?: null | React.MouseEventHandler<SVGSVGElement>;
}

const Metric = ({
  icon,
  title,
  value,
  secondaryValue,
  color = null,
  onClose = null,
}: MetricProps) => {
  return (
    <MetricWrapper
      className={`metric ${!secondaryValue ? "no-secondary" : ""}`}
    >
      <div className="metric-icon">
        <svg
          width="44"
          height="50"
          viewBox="0 0 44 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color ?? "#3251A6"}
            d="M22 0L43.6506 12.5V37.5L22 50L0.349365 37.5V12.5L22 0Z"
          />
        </svg>
        <FontAwesomeIcon icon={icon} />
      </div>
      <h4 className="title">{title}</h4>
      <h3 className="value">{value}</h3>
      {secondaryValue && <h5 className="secondary-value">{secondaryValue}</h5>}
      {onClose && (
        <FontAwesomeIcon
          icon={faTimes as IconProp}
          size="lg"
          className="close-btn"
          onClick={onClose}
        />
      )}
    </MetricWrapper>
  );
};

export default Metric;
