import styled from "styled-components";

export const ProfileInfo = styled.div`
  padding: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  padding-right: calc(
    ${({ theme }) => theme?.utilities.spacing["space-md"]} + 20px
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme?.colors["ui-gray"]};
  border-right: 2px solid #1f356e20;

  .btn {
    font-size: 1rem;
    width: 100%;
    position: relative;
    margin-bottom: ${({ theme }) => theme?.utilities.spacing["space-sm"]};

    svg {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }

  .btn-disabled {
    cursor: not-allowed;
    filter: brightness(0.7);
    background-color: #c1c4c8;

    &:hover {
      filter: brightness(0.7);
      background-color: #c1c4c8;
    }
  }
`;

export const ProfilePicture = styled.div`
  position: relative;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme?.colors["ui-gray-dark"]};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  border: 4px solid ${({ theme }) => theme?.colors["ui-gray-darker"]};

  .photo-alt {
    width: 80%;
    height: 80%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ClosedCountryOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  span {
    color: ${({ theme }) => theme?.colors["secondary-default"]};
    font-size: 1.5em;
    font-weight: bolder;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
  }
`;

export const ProfileName = styled.h2`
  text-align: center;
`;

export const ProfileDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: ${({ theme }) => theme?.utilities.spacing["space-xs"]} 0
    ${({ theme }) => theme?.utilities.spacing["space-md"]};
`;

export const ProfileDetailItem = styled.div`
  line-height: 25px;
  display: flex;
  align-items: center;

  h5 {
    font-weight: 700;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  svg {
    color: ${({ theme }) => theme?.colors["secondary-default"]};
    margin-right: 5px;
  }
`;

export const RockProfileLink = styled.a`
  padding-top: 10px;
  color: ${({ theme }) => theme?.colors["text-default"]};
  &:hover {
    text-decoration: underline;
  }
`;

export const LogoContainer = styled.div`
  margin-top: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #33333390;
  img {
    margin-top: 2.5px;
    width: 60%;
  }
`;
