import styled, { keyframes } from "styled-components";
import { PrayerWallProfile } from "../seekerProfile/prayerWallSeekerProfile/prayerWallSeekerProfile.styles";

export const ArcMapWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  .profile-tooltip {
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};

    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-sm"]};
    padding: ${({ theme }) => theme?.utilities.spacing["space-xs"]}
      ${({ theme }) => theme?.utilities.spacing["space-sm"]};
    h5 {
      color: ${({ theme }) => theme?.colors["primary-dark"]};
      white-space: nowrap;
    }
    p {
      font-size: 0.65rem;
      opacity: 0.8;
      &.blue {
        color: ${({ theme }) => theme?.colors["primary-dark"]};
      }

      &.orange {
        color: ${({ theme }) => theme?.colors["secondary-default"]};
      }

      &.green {
        color: #22814f;
      }
    }
  }
`;

export const ModalEnter = keyframes`
  0% {
    transform: translateX(-50vw);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ModalLeave = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateX(-50vw);
  }
`;

export const PrayerRequestWrapper = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  left: ${({ theme }) => theme?.utilities.spacing["space-lg"]};

  ${PrayerWallProfile} {
    position: relative;

    &:not(.leaving) {
      animation: 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s 1
        ${ModalEnter};
    }

    &.leaving {
      animation: 0.35s cubic-bezier(0.68, 0, 0.83, -0.275) 0s 1 ${ModalLeave};
    }
  }
`;
