import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconName,
  findIconDefinition,
} from "@fortawesome/fontawesome-svg-core";
import { NavItem, NavItemTitle } from "./styles";

// @ts-ignore
type NavBarItemProps = {
  title: string;
  route: string;
  icon: string;
  active: boolean;
  small: boolean;
};

const NavBarItem = ({ title, route, icon, active, small }: NavBarItemProps) => {
  return (
    <NavItem to={route} className={`${active && "active"} ${small && "small"}`}>
      <FontAwesomeIcon
        icon={findIconDefinition({
          prefix: "fas",
          iconName: icon as IconName,
        })}
        className="icon"
      />
      <NavItemTitle>
        <p>{title}</p>
      </NavItemTitle>
    </NavItem>
  );
};

export default NavBarItem;
