import styled from "styled-components";
import { Placeholder } from "../componentPlaceholder/styles";
import ComponentPlaceholder from "../componentPlaceholder";

type AreaGraphPlaceholderProps = {
  height: number;
};

const Wrapper = styled.div`
  ${Placeholder} {
    margin-bottom: ${({ theme }) => theme.utilities.spacing["space-md"]};
  }
`;

const AreaGraphPlaceholder: React.FC<AreaGraphPlaceholderProps> = ({
  height,
}) => {
  return (
    <Wrapper>
      <ComponentPlaceholder height={height} />
    </Wrapper>
  );
};

export default AreaGraphPlaceholder;
