import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const FilterToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.["primary-light"]};
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-md"]};
  box-shadow: 0px 2px 4px rgba(22, 38, 77, 0.2);
  width: 60px;
  height: 60px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors?.["primary-dark"]};
    transition: 0.2s;
  }

  .icon {
    width: 50%;
    height: 50%;
    color: white;
  }
`;

type PageFilterToggleProps = {
  onClick: () => void;
};

const PageFilterToggle = ({ onClick }: PageFilterToggleProps) => {
  return (
    <FilterToggle onClick={onClick}>
      <FontAwesomeIcon icon={faSlidersH as IconProp} className="icon" />
    </FilterToggle>
  );
};

export default PageFilterToggle;
