import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat"; // load on demand
dayjs.extend(AdvancedFormat);
export type SerializedDateRange =
  `${DateOption}::${DateOption}::${DateRangeBinWidthKey}`;
/** the bin widths inside the analytics engine */
export type DateRangeBinWidthKey =
  | "daily"
  | "weekly"
  | "monthly"
  | "yearly"
  | "total";
/** a helper type for enumerable options */
export type DateBinOption = "month" | "day" | "week" | "year";
/** helper type for enumerable options inside the DateOption template literals */
export type ThisOrLast = "this" | "last";
export type BeginningOrEnd = "beginning" | "end";
export type NormalDateOption = "string";
// | "today" // commented out because it makes typescript crashy
// | "yesterday"
// | `1-${DateBinOption}-ago`
// | `${number}-${DateBinOption}s-ago`
// | `${BeginningOrEnd}-of-${ThisOrLast}-${"month" | "year"}`;
export type BeginningDateOption = `${BeginningOrEnd}-of-${NormalDateOption}`;
export type DateOption = string; //NormalDateOption | BeginningDateOption;
export type DateRange = {
  startDate: { date: Date; dateOption: DateOption };
  endDate: { date: Date; dateOption: DateOption };
  binWidth: DateRangeBinWidthKey;
};
export type DateRangeConfig = {
  startDate: DateOption;
  endDate: DateOption;
  binWidth: DateRangeBinWidthKey;
};
export const dateRangeFromConfig: (config: DateRangeConfig) => DateRange = (
  config
) => {
  const range = {
    ...config,
    startDate: {
      date: parseDateOption(config.startDate),
      dateOption: config.startDate,
    },
    endDate: {
      date: parseDateOption(config.endDate),
      dateOption: config.endDate,
    },
  };
  return range;
};
export const DateRangesAreEqual: (
  range1: DateRange,
  range2: DateRange
) => boolean = (range1, range2) => {
  const binsEqual = range1?.binWidth === range2?.binWidth;
  const startEqual =
    range1?.startDate?.dateOption === range2?.startDate?.dateOption;
  const endEqual = range1?.endDate?.dateOption === range2?.endDate?.dateOption;
  return binsEqual && startEqual && endEqual;
};

const formatDate = (date: Date) => {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");
  let hour = date.getHours().toString().padStart(2, "0");
  let minutes = date.getMinutes().toString().padStart(2, "0");
  let seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}Z`;
};

export const parseDateOption = (dateOption: DateOption): Date => {
  let parsedDate: Date;
  switch (dateOption) {
    /** if today or yesterday, the answer is easy */
    case "today":
      parsedDate = today();
      break;
    case "yesterday":
      parsedDate = yesterday();
      break;
    /** otherwise, additional data is needed */
    default:
      parsedDate = parseComplexDateOption(dateOption);
      break;
  }
  return new Date(formatDate(parsedDate));
};

export type BeginningOrEndOptions = "beginning" | "end";

//** all the date options other than today or yesterday */
type ExcludeTodayOrYesterday<T> = T extends DateOption
  ? T extends "today" | "yesterday"
    ? never
    : T
  : never;
const parseComplexDateOption = (
  complexDateOption: ExcludeTodayOrYesterday<DateOption>,
  options?: BeginningOrEndOptions
) => {
  const dateOptionParts = complexDateOption.split("-");
  if (dateOptionParts[0] === "beginning" && dateOptionParts[1] === "of") {
    /** in this case, the date option is `beginning-of-${ThisOrLast}-${"month" | "year"}` */
    const [, , ...rest] = dateOptionParts;
    //@ts-ignore
    const withoutBeginningOf = rest.join("-");
    //@ts-ignore
    return parseComplexDateOption(withoutBeginningOf, "beginning");
  } else if (dateOptionParts[0] === "end" && dateOptionParts[1] === "of") {
    /** in this case, the date option is `end-of-${ThisOrLast}-${"month" | "year"}` */
    const [, , ...rest] = dateOptionParts;
    //@ts-ignore
    const withoutBeginningOf = rest.join("-");
    //@ts-ignore
    return parseComplexDateOption(withoutBeginningOf, "end");
  }
  const [bins, binType] = complexDateOption.split("-");
  if (bins.match(/[0-9].*/)) {
    /** in this case, its `1-${DateBinOption}-ago`| `${number}-${DateBinOption}s-ago` */
    /** normalize by removing plurals */
    const bin: DateBinOption = (
      binType.charAt(binType.length - 1) === "s"
        ? binType.substring(0, binType.length - 1)
        : binType
    ) as DateBinOption;
    switch (bin) {
      case "day":
        return nDaysAgo(Number(bins));
      case "month":
        return nMonthsAgo(Number(bins), options);
      case "week":
        return nWeeksAgo(Number(bins));
      case "year":
        return nYearsAgo(Number(bins), options);
      default:
        throw new Error("unknown input received");
    }
  } else {
    throw new Error("unknown input received");
  }
};
/** date parsing functions */
/** today at midnight */
const today = () => new Date(new Date().setHours(0, 0, 0, 0));

/**yesterday at midnight */
const yesterday = () => nDaysAgo(1);

/** n days ago at midnight */
const nDaysAgo = (nDays: number) =>
  new Date(today().setDate(today().getDate() - nDays));

/** n months ago on this day of the month at midnight */
const nMonthsAgo = (nMonths: number, options?: BeginningOrEndOptions) => {
  const beginningOfMonth = dayjs(today())
    .subtract(nMonths, "months")
    .startOf("month");

  return (
    options === "end"
      ? beginningOfMonth.endOf("month")
      : beginningOfMonth.startOf("month")
  ).toDate();
};

/** n weeks ago at midnight  */
const nWeeksAgo = (nWeeks: number) => {
  return nDaysAgo(nWeeks * 7);
};

/** n years ago on this day at midnight */
const nYearsAgo = (nYears: number, options?: BeginningOrEndOptions) => {
  const date = new Date(today().setFullYear(today().getFullYear() - nYears));
  if (options === "beginning") {
    date.setMonth(0, 1);
  } else if (options === "end") {
    date.setMonth(11, 31);
  }
  return date;
};

/**create date range from date options and a bin width */
export const createDateRange: (
  startDate: DateOption,
  endDate: DateOption,
  binWidth: DateRangeBinWidthKey
) => DateRange = (startDate, endDate, binWidth) => {
  const dateRange = {
    startDate: { date: parseDateOption(startDate), dateOption: startDate },
    endDate: { date: parseDateOption(endDate), dateOption: endDate },
    binWidth,
  };
  return dateRange;
};
