import { usePartner } from "./globalContext";

export type BusinessTermCategory =
  | "analytic"
  | "language"
  | "country"
  | "gender";
export const useBusinessMappings = () => {
  const { partner } = usePartner();
  return (termToMap: string, category: BusinessTermCategory = "analytic") => {
    return (
      partner?.businessDomainMappings?.[category]?.[termToMap] ||
      camelCaseToLabel(termToMap)
    );
  };
};

const camelCaseToLabel = (str: string) => {
  let spaced = str.replace(/[A-Z]/g, (letter) => ` ${letter}`);
  return spaced[0].toUpperCase() + spaced.substring(1);
};
