import { IconLayer } from "@deck.gl/layers";
import { iconMapping } from "../yearToDateMapConfig";

const useYearToDateMapLayers = (data: any[]) => {
  const pinLayer = new IconLayer({
    id: "data",
    data: data,
    pickable: true,
    billboard: false,
    // iconAtlas and iconMapping are required
    // getIcon: return a string
    iconAtlas: "ytd-atlas.png",
    iconMapping,
    getIcon: (d) => d.type,
    sizeScale: 15,
    getPosition: (d) => d.coords,
    getSize: () => 2.5,
  });

  return pinLayer;
};

export default useYearToDateMapLayers;
