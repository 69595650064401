import Card from "components/card";
import Form from "components/form/legacy";
import { useState } from "react";
import { useAuth } from "context/authContext";
import usePartnerImage from "./usePartnerImage";
import { LoginPage } from "./login.styles";

const Login = () => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");

  const auth = useAuth();
  const handleLogin = async () => {
    await auth.login(email, password);
  };

  const partnerImage = usePartnerImage();

  return (
    <LoginPage>
      <Card>
        <Card.Body className="login-content">
          <div className={`logo-wrapper ${!!partnerImage && "global-partner"}`}>
            <Card.Image
              src={`${process.env.REACT_APP_S3_URL}logo/logo.png`}
              alt={"logo"}
              className="ul-logo"
            />
            {!!partnerImage && (
              <Card.Image src={partnerImage} alt="" className="partner-logo" />
            )}
          </div>
          <Form.InputContainer className="input-container">
            <Form.Label>Email</Form.Label>
            <Form.Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="login-email"
              autoFocus
            />
          </Form.InputContainer>
          <Form.InputContainer className="input-container">
            <Form.Label>Password</Form.Label>
            <Form.Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="login-password"
              type="password"
            />
          </Form.InputContainer>
          <button
            className="btn btn-primary"
            onClick={handleLogin}
            disabled={email.length === 0 || password.length === 0}
          >
            Log In
          </button>
          {auth.loginError && <p className="login-error">{auth.loginError}</p>}
        </Card.Body>
      </Card>
    </LoginPage>
  );
};

export default Login;
