import {
  faFileAlt,
  faGlobe,
  faMapMarkerAlt,
  faPlayCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  AdditionalProfileInfo,
  SeekerProfile,
  SeekerTestimonyType,
} from "../types";
import {
  LogoContainer,
  ProfileDetailItem,
  ProfileDetails,
  ProfileInfo,
  ProfileName,
  ProfilePicture,
  ClosedCountryOverlay,
  RockProfileLink,
} from "./styles";
import CountryService from "services/country-service";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type SeekerProfileInfoProps = {
  profile: SeekerProfile;
  testimony?: AdditionalProfileInfo["testimony"];
  campaignName?: AdditionalProfileInfo["campaignName"];
  handleShowMoreInfo: (show: boolean) => void;
  handleShowTestimony: (type: SeekerTestimonyType) => void;
};

const SeekerProfileInfo: React.FC<SeekerProfileInfoProps> = ({
  profile,
  testimony,
  campaignName,
  handleShowMoreInfo,
  handleShowTestimony,
}) => {
  const { country, language } = profile;

  const hasHtmlTestimony = !!testimony?.text;
  const hasVideoTestimony = !!testimony?.testimony_video_id;
  const isClosedCountry = CountryService.isClosedCountry(country);
  const showMoreInfoButton = campaignName;

  return (
    <ProfileInfo>
      <ProfilePicture>
        {testimony?.profile_photo_url ? (
          <img src={testimony.profile_photo_url} alt="" />
        ) : (
          <FontAwesomeIcon icon={faUser as IconProp} className="photo-alt" />
        )}
        {isClosedCountry && (
          <ClosedCountryOverlay>
            <span>Closed Country</span>
          </ClosedCountryOverlay>
        )}
      </ProfilePicture>
      <ProfileName>
        {profile.profileType === "trinity" && profile.nickname
          ? profile.nickname
          : profile.first_name}
      </ProfileName>
      <ProfileDetails>
        <ProfileDetailItem>
          <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} size="lg" />
          <h5>{country}</h5>
        </ProfileDetailItem>
        {language && (
          <ProfileDetailItem>
            <FontAwesomeIcon icon={faGlobe as IconProp} size="lg" />
            <h5>{language}</h5>
          </ProfileDetailItem>
        )}
      </ProfileDetails>
      <button
        style={{
          cursor: showMoreInfoButton ? "pointer" : "not-allowed",
        }}
        className={`btn btn-primary ${!showMoreInfoButton ? "btn-disabled" : ""}`}
        onClick={() => showMoreInfoButton && handleShowMoreInfo(true)}
      >
        <FontAwesomeIcon icon={faFileAlt as IconProp} /> More Info
      </button>
      <button
        style={{
          cursor: hasHtmlTestimony ? "pointer" : "not-allowed",
        }}
        className={`btn btn-primary ${!hasHtmlTestimony ? "btn-disabled" : ""}`}
        onClick={() => hasHtmlTestimony && handleShowTestimony("html")}
      >
        <FontAwesomeIcon icon={faFileAlt as IconProp} /> Read Story
      </button>
      <button
        className={`btn btn-primary ${
          !hasVideoTestimony ? "btn-disabled" : ""
        }`}
        onClick={() => hasVideoTestimony && handleShowTestimony("video")}
      >
        <FontAwesomeIcon icon={faPlayCircle as IconProp} />
        Watch Video
      </button>
      {profile.profileType === "trinity" && (
        <RockProfileLink
          href={`${process.env.REACT_APP_TRINITY_ROCK_URL}/Person/${profile.rock_id}`}
          target="_blank"
          rel="noreferrer"
        >
          <h5>ROCK PROFILE: {profile.rock_id}</h5>
        </RockProfileLink>
      )}
      <LogoContainer>
        Powered By
        <img
          src={`${process.env.REACT_APP_S3_URL}logo/logo.png`}
          alt="UniteLife"
        />
      </LogoContainer>
    </ProfileInfo>
  );
};

export default SeekerProfileInfo;
