import { useBusinessMappings } from "hooks/useBusinessMappings";
import { useEffect, useState } from "react";
import { getSeekerCampaignId, getSeekerTestimony } from "services/apiService";
import { AdditionalProfileInfo, SeekerProfile, Testimony } from "./types";

const useAdditionalProfileInfo = (
  profile: SeekerProfile
): AdditionalProfileInfo => {
  const [testimony, setTestimony] = useState<Testimony>();
  const [campaignName, setCampaignName] = useState<string>();
  const mapTerms = useBusinessMappings();

  useEffect(() => {
    fetchProfileTestimony();
    fetchProfileCampaignName();
    //eslint-disable-next-line
  }, []);

  const fetchProfileTestimony = async () => {
    const result = (await getSeekerTestimony(
      profile.uuid
    )) as unknown as Testimony;

    setTestimony(result);
  };

  const fetchProfileCampaignName = async () => {
    const campaignId = await getSeekerCampaignId(profile.uuid) as unknown as string;
    let name = "---";
    if (campaignId) name = mapTerms(campaignId.toString().trim());

    setCampaignName(name);
  };

  return {
    testimony,
    campaignName,
  };
};

export default useAdditionalProfileInfo;
