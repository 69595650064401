import React from "react";
import { SwitcherItem, SwitcherItems } from "./styles";
import { ViewConfig } from "../../hooks/globalContext";

type ViewSwitcherItemsProps = {
  viewConfig: ViewConfig;
  views: ViewConfig[];
  onClick: (title: string) => void;
};

const ViewSwitcherItems = ({
  viewConfig,
  views,
  onClick,
}: ViewSwitcherItemsProps) => {
  const filteredViews = views.filter(({ title }) => viewConfig.title !== title);

  return (
    <SwitcherItems>
      {filteredViews.map(({ title, iconUrl }) => (
        <React.Fragment key={title}>
          <SwitcherItem key={title} onClick={() => onClick(title)}>
            <img src={iconUrl} alt="" /> {title}
          </SwitcherItem>
        </React.Fragment>
      ))}
    </SwitcherItems>
  );
};

export default ViewSwitcherItems;
