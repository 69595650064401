import {
  faChevronDown,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Form from "components/form/legacy";
import DatePicker from "react-datepicker";
import { filterItems } from "../changeLogConfig";
import {
  FilterContent,
  FilterHeader,
  FilterWrapper,
} from "../changeLog.styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const now = new Date();
const tomorrow = new Date(now.setUTCDate(now.getUTCDate() + 1));

const ChangeLogFilter = ({
  activeFilters,
  defaultFilters,
  onFilterRemove,
  onClearFiltersClick,
  onUpdateFiltersClick,
}) => {
  let [expanded, setExpanded] = useState(true);
  let [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    setFilters(activeFilters);
    //eslint-disable-next-line
  }, [activeFilters]);

  const onFilterUpdate = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  };

  const currentActiveFilters = Object.keys(activeFilters).filter(
    (key) => defaultFilters[key] !== activeFilters[key]
  );

  const handleUpdateFilters = () => {
    onUpdateFiltersClick(filters);
  };

  const renderFilter = (filterItem) => {
    if (filterItem.type === "date") {
      let { label, values } = filterItem;
      return (
        <Form.InputContainer className="date-select" key={label}>
          <Form.Label>{label}</Form.Label>
          <div className="date-select-inputs">
            <DatePicker
              selected={filters[values[0]]}
              onChange={(date) => onFilterUpdate(values[0], date)}
              maxDate={new Date()}
            />
            <span>to</span>
            <DatePicker
              selected={filters[values[1]]}
              onChange={(date) => onFilterUpdate(values[1], date)}
              maxDate={tomorrow}
            />
          </div>
        </Form.InputContainer>
      );
    } else if (filterItem.type === "search") {
      let { label, value } = filterItem;

      return (
        <Form.InputContainer key={label}>
          <Form.Label>{label}</Form.Label>
          <Form.Input
            value={filters[value]}
            onChange={(e) => onFilterUpdate(value, e.target.value)}
          />
        </Form.InputContainer>
      );
    } else {
      let { label, value, options } = filterItem;
      return (
        <Form.InputContainer key={label}>
          <Form.Label>{label}</Form.Label>
          <Form.Dropdown
            dropdownClassName="filter-dropdown"
            options={options}
            value={filters[value]}
            onChange={(val) => onFilterUpdate(value, val)}
          />
        </Form.InputContainer>
      );
    }
  };

  let firstRowFilters = filterItems.slice(0, 3);
  let secondRowFilters = filterItems.slice(3);

  return (
    <FilterWrapper className={expanded ? "expanded" : ""}>
      <FilterHeader onClick={() => setExpanded(!expanded)}>
        <FontAwesomeIcon
          icon={(expanded ? faChevronDown : faChevronRight) as IconProp}
          className="action-icon"
        />
        <h3>Filtering By: </h3>
        {currentActiveFilters.length === 0 ? (
          <i className="empty">No filters selected</i>
        ) : (
          currentActiveFilters.map((filter) => (
            <ActiveFilter
              name={filter}
              key={filter}
              onRemove={() => onFilterRemove(filter)}
            />
          ))
        )}
      </FilterHeader>
      {expanded && (
        <FilterContent>
          <Form.Row className="form-row">
            {firstRowFilters.map((item) => renderFilter(item))}
          </Form.Row>
          <Form.Row className="form-row">
            {secondRowFilters.map((item) => renderFilter(item))}
            <div className="button-container">
              <button
                className="btn btn-alert clear-filters"
                onClick={onClearFiltersClick}
              >
                Clear Filters
              </button>
              <button
                className="btn btn-secondary"
                onClick={handleUpdateFilters}
              >
                Update Filters
              </button>
            </div>
          </Form.Row>
        </FilterContent>
      )}
    </FilterWrapper>
  );
};

const ActiveFilter = ({ name, onRemove }) => {
  const onRemoveClick = (e) => {
    e.stopPropagation();
    onRemove();
  };

  let filterItem = filterItems.find(
    (item) => item.value === name || (item.values && item.values.includes(name))
  );

  let label =
    filterItem.type !== "date"
      ? filterItem.label
      : filterItem.valueLabels[filterItem.values.indexOf(name)];

  return (
    <div className="active-filter">
      {label}
      <FontAwesomeIcon
        icon={faTimes as IconProp}
        className="action-icon"
        onClick={onRemoveClick}
      />
    </div>
  );
};

export default ChangeLogFilter;
