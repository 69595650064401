import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActiveView } from "./styles";
import { ViewConfig } from "../../hooks/globalContext";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type ActiveSwitcherViewProps = {
  viewConfig: ViewConfig;
  switcherOpen: boolean;
  toggleSwitcher: () => void;
};

const ActiveSwitcherView = ({
  viewConfig,
  switcherOpen,
  toggleSwitcher,
}: ActiveSwitcherViewProps) => (
  <ActiveView onClick={toggleSwitcher}>
    <img src={viewConfig.iconUrl} alt="" />
    <p className="label">Current View:</p>
    <h5 className="title">{viewConfig.title}</h5>
    <FontAwesomeIcon
      icon={faChevronDown as IconProp}
      className={switcherOpen ? "reversed" : ""}
    />
  </ActiveView>
);

export default ActiveSwitcherView;
