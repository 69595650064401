import styled from "styled-components";
import { Theme } from "../../theme";

export const cellHeight = "50px";
export const cellDoubleHeight = "80px";
export const cellHeightWithSubData = "75px";
export const scrollableCellWidth = "150px";

export const Table = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-md"]};
  overflow: hidden;
  background-color: ${({ theme }) => theme?.colors["ui-gray"]};

  &.show-border {
    border: 1px solid ${({ theme }) => theme?.colors["primary-dark"]};
  }

  &.no-scroll {
    width: max-content;
  }
  .scroll-container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    max-height: 80vh;

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: ${({ theme }) =>
        theme?.utilities.borders["border-radius-md"]};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: ${({ theme }) => theme?.colors?.["ui-gray-dark"]};
      // background: transparent;
      opacity: 0.4;
      box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0) inset;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${({ theme }) =>
        theme?.utilities.borders["border-radius-md"]};
      background: ${({ theme }) => theme?.colors?.["scrollbar"]};

      &:hover {
        background: ${({ theme }) => theme?.colors?.["table-line"]};
      }
    }
  }
`;

const orangeTheme = {
  "text-dark": "#333333",
  "primary-dark": "#fda533",
  "primary-darker": "#E48C1A",
};

const purpleTheme = {
  "text-dark": "#333333",
  "primary-dark": "#8d52ff",
  "primary-darker": "#7439E6",
};

const lightBlueTheme = {
  "text-dark": "#333333",
  "primary-dark": "#2eb0fe",
  "primary-darker": "#1597E5",
};

const defaultTracking = {
  "text-dark": "#333333",
};

const overrideThemes = {
  orange: orangeTheme,
  purple: purpleTheme,
  lightblue: lightBlueTheme,
  defaultTracking: defaultTracking,
};

export const overrideColorTheme = (
  theme: Theme,
  colorOverride?: string
): Theme => {
  if (!colorOverride || !overrideThemes[colorOverride]) return theme;

  return {
    ...theme,
    colors: { ...theme.colors, ...overrideThemes[colorOverride] },
  };
};
