import { useBusinessMappings } from "hooks/useBusinessMappings";
import { impactMetricConfig } from "../seekerProfileConfig";
import { SeekerProfile } from "../types";
import SeekerProfileMetricItem, {
  SeekerProfileMetricItemProps,
} from "./seekerProfileMetricItem";
import {
  MetricGroup,
  Metrics,
  RecurringMetricItem,
  SingleMetricItem,
} from "./styles";

type SeekerProfileMetricsProps = {
  profile: SeekerProfile;
};

type MetricData = Omit<SeekerProfileMetricItemProps, "profile">;

const SeekerProfileMetrics: React.FC<SeekerProfileMetricsProps> = ({
  profile,
}) => {
  const mapTerm = useBusinessMappings();
  const singleMetrics = [] as MetricData[];
  const ongoingMetrics = [] as MetricData[];

  const getValue = (profile: SeekerProfile, key: string) => {
    if (profile?.impactMetrics?.[key]) {
      if (profile.profileType === "trinity") {
        return profile?.impactMetrics?.[key]?.value
          ? profile?.impactMetrics?.[key]?.value
          : [];
      } else {
        return profile?.impactMetrics?.[key]?.value;
      }
    }
    return [];
  };

  if (profile) {
    for (let [
      key,
      // @ts-ignore
      { icon, helpText, businessMappingKey, type },
    ] of Object.entries(impactMetricConfig[profile.profileType])) {
      const metricData = {
        icon,
        title: mapTerm(businessMappingKey),
        type: type as MetricData["type"],
        value: getValue(profile, key) ?? "",
        helpText,
      };
      if (type === "single") {
        singleMetrics.push(metricData);
      } else {
        ongoingMetrics.push(metricData);
      }
    }
  }

  return (
    <Metrics>
      <h2>Journey of Faith</h2>
      <MetricGroup>
        <SingleMetricItem>One Time Impact Metrics</SingleMetricItem>
        {singleMetrics.map((metric) => (
          <SeekerProfileMetricItem
            {...metric}
            profile={profile}
            key={metric.title}
          />
        ))}
      </MetricGroup>
      <MetricGroup>
        <RecurringMetricItem>Ongoing Impact Metrics</RecurringMetricItem>
        {ongoingMetrics.map((metric) => (
          <SeekerProfileMetricItem
            {...metric}
            profile={profile}
            key={metric.title}
          />
        ))}
      </MetricGroup>
    </Metrics>
  );
};

export default SeekerProfileMetrics;
