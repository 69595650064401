import styled from "styled-components";
import Card from "components/card/card";
import { GraphWrapper } from "../areaGraph/style";

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-lg"]};

  .react-datepicker-wrapper {
    height: 100%;

    .react-datepicker__input-container {
      height: 100%;
      width: 100%;
      input {
        border-radius: ${({ theme }) =>
          theme.utilities?.borders?.["border-radius-md"]};
        border: 0;
        background-color: ${({ theme }) => theme.colors?.["ui-blue"]};
        box-shadow: inset 0 1px 2px 0px 4px 8px rgba(0, 0, 0, 0.05);
        font-family: inherit;
        font-size: 1rem;
        padding: 0.6rem ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
        color: ${({ theme }) => theme.colors?.["text-default"]};
        width: 100%;

        background-color: white;
        border: 1px solid ${({ theme }) => theme.colors?.["text-default"]};
        font-size: 1.125rem;

        &::placeholder {
          color: ${({ theme }) => theme.colors?.["text-default"]};
          opacity: 0.6;
        }
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 3rem;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

  svg {
    color: ${({ theme }) => theme.colors?.["secondary-default"]};
    cursor: pointer;
    transition: 0.2s;
    font-size: 2rem;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const FilterHeader = styled.div`
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]}
    ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-lg"]};
  background-color: ${({ theme }) => theme.colors?.["ui-gray-lighter"]};
  box-shadow: 0px 2px 4px rgba(22, 38, 77, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  h3 {
    margin: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]}
      ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  }

  .empty {
    font-size: 1.25rem;
    opacity: 0.6;
  }

  .action-icon {
    cursor: pointer;
  }

  .active-filter {
    padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]}
      ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    background-color: ${({ theme }) => theme.colors?.["secondary-default"]};
    border-radius: 100px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    color: #22293b;
    font-weight: bold;
    margin-right: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

    svg {
      margin-left: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    }
  }
`;

export const FilterContent = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  background-color: #ebeff5;
  box-shadow: 0px 2px 4px rgba(22, 38, 77, 0.2);
  border-bottom-left-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-lg"]};
  border-bottom-right-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-lg"]};

  .form-row {
    margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    &:last-child {
      margin-bottom: 0;
    }
  }

  input {
    background-color: white;
    width: 360px;
    border: 1px solid ${({ theme }) => theme.colors?.["text-default"]};
    font-size: 1.125rem;
    height: 100%;
  }
  .filter-dropdown,
  select {
    width: 100%;
  }

  .button-container {
    display: flex;
    flex-direction: row-reverse;
    margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};

    .clear-filters {
      margin-left: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
    }
  }

  .date-select {
    .date-select-inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      > * + * {
        margin-left: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
      }

      .react-datepicker-wrapper {
        width: 150px;
      }
    }
  }
`;

export const FilterWrapper = styled.div`
  &.expanded {
    ${FilterHeader} {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

export const ActiveFilter = styled.div`
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]}
    ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  background-color: ${({ theme }) => theme.colors?.["secondary-default"]};
  border-radius: 100px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  color: #22293b;
  font-weight: bold;
  margin-left: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

  svg {
    cursor: pointer;
    margin-left: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  }
`;

export const List = styled(Card.Body)`
  padding: 0 !important;
  height: 750px;
  display: grid !important;
  grid-template-columns: 500px 3fr;
`;

export const Changes = styled.div`
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) =>
      theme.utilities?.borders?.["border-radius-md"]}
    background: ${({ theme }) => theme.colors?.["ui-gray-dark"]};
    opacity: 0.4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) =>
      theme.utilities?.borders?.["border-radius-md"]}
    background: ${({ theme }) => theme.colors?.["scrollbar"]};

    &:hover {
      background: ${({ theme }) => theme.colors?.["table-line"]};
    }
  }
  overflow-y: scroll;
  height: 100%;

  .change-list-item {
    width: 100%;
    padding: ${({ theme }) => theme.utilities?.spacing?.["space-md"]} ${({
  theme,
}) => theme.utilities?.spacing?.["space-lg"]};
    border-bottom: 1px solid #c4c4c4;
    cursor: pointer;
    .name {
      font-size: 1.2rem;
      line-height: 1.4;
      margin-top: 4px;
    }
    .date {
      font-size: 1rem;
      opacity: 0.6;
    }
    &:not(.active):hover {
      background-color: ${({ theme }) => theme.colors?.["ui-gray"]};
    }

    &.active {
      background-color: ${({ theme }) => theme.colors?.["primary-light"]};

      .date {
        color: ${({ theme }) => theme.colors?.["ui-gray-lighter"]};
      }
      .name {
        color: white;
      }
    }
  }
`;

export const ChangeDetails = styled.div`
  height: 100%;
  padding: 3rem ${({ theme }) => theme.utilities?.spacing?.["space-lg"]};

  .name {
    font-size: 2rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.["table-line"]};
    padding-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
    margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  }
  .date {
    font-size: 1.2rem;
    opacity: 0.6;
  }

  .detail-row {
    &:not(.notes) {
      font-weight: 700;
    }

    span {
      &.detail-row__heading {
        opacity: 0.5;
      }
      & + span {
        margin-left: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
      }
    }
  }
  .delete-button {
    margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  }
`;

export const Graph = styled(Card.Body)`
  width: calc(
    100vw - (2 * ${({ theme }) => theme.utilities?.spacing?.["space-xl"]}) -
      100px
  );
  overflow: hidden;
  padding: 0 !important;
  .select-container {
    display: flex;
  }
  .kpi-select {
    padding: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
    padding-bottom: 0;
    display: flex;
    align-items: center;
    h3 {
      margin-right: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    }
  }
`;

export const GraphScrollWrapper = styled(GraphWrapper)`
  background-color: transparent;
  width: 100%;

  .changes-dot {
    fill: ${({ theme }) => theme.colors?.["secondary-default"]};
    cursor: pointer;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));

    &:hover {
      fill: ${({ theme }) => theme.colors?.["secondary-dark"]};
    }

    .changes-number {
      fill: ${({ theme }) => theme.colors?.["text-dark"]};
    }

    &.active {
      fill: ${({ theme }) => theme.colors?.["primary-light"]};
      .changes-number {
        fill: ${({ theme }) => theme.colors?.["ui-gray-lighter"]};
      }
    }
  }
`;
