import React from "react";
import { Bubble, BubbleProps } from "./styles";
export type DataBubbleProps = {
  value: string;
  title: string;
} & BubbleProps;

export const DataBubble: React.FC<DataBubbleProps> = (props) => (
  <Bubble {...props}>
    <div className="text-wrapper">
      <p className="large">{props.value}</p>
      <p className="small uppercase">{props.title}</p>
    </div>
  </Bubble>
);
