import { useEffect, useState } from "react";
import {
  DateOption,
  DateRange,
  DateRangesAreEqual,
  createDateRange,
} from "../../utils/dateRange";

type RangePreset = {
  name: string;
  getDateRange: (options?: {
    startDateOption: DateOption;
    endDateOption: DateOption;
  }) => DateRange;
};

export const dateRangePresetsTotal = {
  "30d": {
    name: "Last 30 Days",
    getDateRange: () => createDateRange("30-days-ago", "today", "total"),
  } as RangePreset,
  "12w": {
    name: "Last 12 Weeks",
    getDateRange: () => createDateRange("12-weeks-ago", "today", "total"),
  } as RangePreset,
  "12m": {
    name: "Last 12 Months",
    getDateRange: () => {
      return createDateRange("beginning-of-11-months-ago", "today", "total");
    },
  } as RangePreset,
  "this-month": {
    name: "This Month",
    getDateRange: () =>
      createDateRange("beginning-of-0-months-ago", "today", "total"),
  } as RangePreset,
  "last-month": {
    name: "Last Month",
    getDateRange: () =>
      createDateRange(
        "beginning-of-1-months-ago",
        "beginning-of-0-months-ago",
        "daily"
      ),
  },
  "two-month-ago": {
    name: "Two Months Ago",
    getDateRange: () =>
      createDateRange("beginning-of-2-months-ago", "today", "total"),
  } as RangePreset,
  "this-year": {
    name: "This Year",
    getDateRange: () =>
      createDateRange("beginning-of-0-years-ago", "today", "total"),
  } as RangePreset,
  "last-year": {
    name: "Last Year",
    getDateRange: () =>
      createDateRange(
        "beginning-of-1-years-ago",
        "beginning-of-0-years-ago",
        "monthly"
      ),
  } as RangePreset,
  "all-time": {
    name: "All Time",
    getDateRange: () => {
      return createDateRange("beginning-of-100-months-ago", "today", "total");
    },
  } as RangePreset,
  custom: {
    name: "Custom",
    getDateRange: (
      { startDateOption, endDateOption } = {
        startDateOption: "today",
        endDateOption: "30-days-ago",
      }
    ) => {
      return createDateRange(startDateOption, endDateOption, "total");
    },
  } as RangePreset,
};

export type RangeKey = keyof typeof dateRangePresetsTotal;

const useDateRangesTotal = (initialDateRangeKey: RangeKey) => {
  const [activeRangeKey, setActiveRangeKey] =
    useState<RangeKey>(initialDateRangeKey);

  const [customDateRangeValues, setCustomDateRangeValues] = useState(
    dateRangePresetsTotal[activeRangeKey].getDateRange
  );

  const [currentRange, setCurrentRange] = useState<DateRange>();
  const [isCustom, setIsCustom] = useState(false);
  const handleCustomDateRangeChange = (data) => {
    setCustomDateRangeValues(data);
    setCurrentRange(
      dateRangePresetsTotal[activeRangeKey].getDateRange({
        startDateOption: data.startDate.dateOption,
        endDateOption: data.endDate.dateOption,
      })
    );
  };
  // when the range key updates, update the current date range
  useEffect(() => {
    if (!(activeRangeKey === "custom")) {
      const newRange = dateRangePresetsTotal[activeRangeKey].getDateRange();
      const newRangeIsDifferent = !DateRangesAreEqual(newRange, currentRange);
      if (newRangeIsDifferent) {
        setCurrentRange(newRange);
      }
    }
    //eslint-disable-next-line
  }, [activeRangeKey]);
  /** keep isCustom in sync with the current range key */
  useEffect(() => {
    if (activeRangeKey === "custom") {
      if (isCustom !== true) setIsCustom(true);
    } else {
      if (isCustom !== false) setIsCustom(false);
    }
  }, [activeRangeKey, isCustom]);
  const rangeKeyUpdateHandler = (optionName) => {
    //eslint-disable-next-line
    const [newKey, value] = Object.entries(dateRangePresetsTotal).find(
      ([key, { name }]) => name === optionName
    ) as unknown as [RangeKey, any];
    setActiveRangeKey(newKey);
  };

  return {
    options: Object.fromEntries(
      Object.entries(dateRangePresetsTotal).map(([key, { name }]) => [
        key,
        name,
      ])
    ),
    activeRangeKey,
    rangeKeyUpdateHandler,
    handleCustomDateRangeChange,
    currentRange,
    isCustom,
    customDateRangeValues,
  };
};

export default useDateRangesTotal;
