import React from "react";
import { DataBubble } from "../dataBubble";
import { DataBubbleProps } from "../dataBubble/dataBubble";
import {
  BubbleRow,
  DataBubbleRowWrapper,
  DataBubbleRowWrapperProps,
  DataBubbleTitle,
} from "./styles";

export type DataBubbleRowProps = {
  /**
   * the large text at the top of the visualization
   */
  rowTitle: string;
  /**
   * smaller text to display under the title.
   * a description of some sort to help tell the story about your data
   */
  subTitle: string;
  /**
   * the dataBubbles to render. will display flex in one row with content centered
   */
  bubbles: DataBubbleProps[];
} & DataBubbleRowWrapperProps;
export const DataBubbleRow: React.FC<DataBubbleRowProps> = (props) => (
  <DataBubbleRowWrapper {...props}>
    <DataBubbleTitle>
      <h1 className="title text">{props.rowTitle}</h1>
      <h3 className="subtitle text">{props.subTitle}</h3>
    </DataBubbleTitle>
    <BubbleRow>
      {props.bubbles.map((bubble, i) => {
        //@ts-ignore
        return <DataBubble key={i} {...bubble} className="bubble" />;
      })}
    </BubbleRow>
  </DataBubbleRowWrapper>
);
