import styled, { keyframes } from "styled-components";

export const ModalEnter = keyframes`
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ModalLeave = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
`;

export const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #33333340;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

export const ProfileWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.leaving) {
    animation: 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s 1 ${ModalEnter};
  }

  &.leaving {
    transform: translateY(-100vh);
    animation: 0.35s cubic-bezier(0.68, 0, 0.83, -0.275) 0s 1 ${ModalLeave};
  }
  pointer-events: none;
`;

export const Profile = styled.div`
  pointer-events: auto;

  position: relative;
  width: 850px;
  background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-lg"]};
  box-shadow: 0px 2px 6px #000000;
  display: grid;
  grid-template-columns: 2fr 3fr;
  overflow: hidden;

  &.show-testimony {
    grid-template-columns: 1.49fr 3fr;
  }
`;

export const ProfileClose = styled.div`
  position: absolute;
  top: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  right: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  margin-right: 10px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ProfileHeader = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 30px;
`;

export const ProfileHeaderText = styled.div`
  background-color: ${({ theme }) => theme?.colors["primary-dark"]};
  color: white;
  text-transform: uppercase;
  padding: 0 ${({ theme }) => theme?.utilities.spacing["space-xl"]};
  font-size: 0.75rem;
  letter-spacing: 1px;
  line-height: 30px;
  position: relative;

  &::before {
    position: absolute;
    top: 0px;
    left: -1px;
    content: "";
    border-bottom: 15px solid ${({ theme }) => theme?.colors["ui-gray-lighter"]};
    border-left: 15px solid ${({ theme }) => theme?.colors["ui-gray-lighter"]};
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
  }
  &::after {
    position: absolute;
    top: 0px;
    right: -1px;
    content: "";
    border-bottom: 15px solid ${({ theme }) => theme?.colors["ui-gray-lighter"]};
    border-right: 15px solid ${({ theme }) => theme?.colors["ui-gray-lighter"]};
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
  }
`;
