import styled from "styled-components";

export const Footer = styled.div`
  grid-area: footer;
  background-color: ${({ theme }) => theme.colors?.["primary-dark"]};
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 0.9rem;
    font-weight: 700;
    display: block;
    text-transform: uppercase;
    color: white;
    margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    letter-spacing: 1.3125px;
  }

  img {
    width: 160px;
  }
`;
