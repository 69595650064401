import { iconMapping } from "../arcMapConfig";
import { IconLayer } from "@deck.gl/layers";
import AnimatedArcLayer from "../animatedArcLayer";

const useArcMapLayers = (
  profiles: any[],
  filteredArcs: any[],
  arcAnimationCoef: number,
  onProfileClick: Function
) => {
  const profileLayer = {
    id: "hosts",
    data: profiles,
    pickable: true,
    billboard: false,
    iconAtlas: "reporting-atlas.png",
    iconMapping,
    sizeScale: 15,
    getPosition: (d) => d.coords,
    getSize: 1.5,
    getIcon: () => 1,
    onClick: onProfileClick,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    loadOptions: {
      imagebitmap: {
        premultiplyAlpha: "premultiply",
      },
    },
    LayerClass: IconLayer,
    hoverTypeName: "pin",
  };

  const arcLayer = {
    id: "arc-layer",
    pickable: true,
    data: filteredArcs,
    getWidth: () => 3,
    getSourcePosition: (d) => d.arc.coords,
    getTargetPosition: (d) => d.profile.coords,
    getSourceColor: () => [255, 255, 255, 255],
    getTargetColor: () => [85, 123, 212, 255],
    coef: arcAnimationCoef, // [0, 1]
    LayerClass: AnimatedArcLayer,
    hoverTypeName: "arc",
  };

  return [profileLayer, arcLayer];
};

export default useArcMapLayers;
