import MapSidebar from "../mapSidebar";
import { ProfileMapKey, ProfileMapWrapper } from "./styles";
import InteractiveMap from "../interactive-map";
import React, { useState } from "react";
import useDashboardMode from "./hooks/useDashboardMode";
import useProfileMapData, { ProfileType } from "./hooks/useProfileMapData";
import useProfileMapLayers from "./hooks/useProfileMapLayers";
import { keyColorMapping, keyMapping } from "./profileMapConfig";
import Metric from "components/metric";
import useProfileMapFilters from "./hooks/useProfileMapFilters";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import ProfileList from "./profileList";
import { SeekerProfile as SeekerProfileType } from "../seekerProfile/types";
import SeekerProfile from "../seekerProfile";
import { Filters } from "hooks/globalContext";
import { useBusinessMappings } from "hooks/useBusinessMappings";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type ProfileMapProps = {
  config: {
    title: string;
    profileType?: ProfileType;
    sidebar?: {
      defaultDateRange?: string;
      defaultDropdownKey: string;
      availableTypes: string[];
    };
    mapType: string;
    country: string;
  };
  pageFilters: Filters;
  loading: boolean;
  dashboardModeCanBeEnabled: boolean;
};

const ProfileMap: React.FC<ProfileMapProps> = ({
  config,
  pageFilters,
  loading,
  dashboardModeCanBeEnabled,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProfile, setSelectedProfile] =
    useState<SeekerProfileType>(null);

  const filters = useProfileMapFilters(config.sidebar);
  const mapData = useProfileMapData(
    selectedCountry,
    filters,
    pageFilters,
    config.profileType ?? "default",
    loading
  );
  const {
    dashboardMode,
    toggleDashboardMode,
    setDashboardMode,
    leaving,
    setLeaving,
  } = useDashboardMode(mapData.profiles, selectedCountry, setSelectedProfile);

  const renderTooltip = ({ object }) => {
    if (!object || !object.uuid) {
      return null;
    }

    const { profileType, typeIndex, first_name, nickname } = object;

    const index = keyMapping[profileType].length - 1 - typeIndex;
    const type = keyMapping[profileType][index];
    const typeClass = keyColorMapping[index];

    return {
      html: `<div class="profile-tooltip">
               <h5>${
                 profileType !== "default" && nickname ? nickname : first_name
               }</h5>
               <p class="${typeClass}">${type}</p>
             </div>`,
      style: {
        backgroundColor: "transparent",
      },
    };
  };

  const onProfileClick = (info: any) => {
    if (info.index >= 0) {
      let profile = mapData.profiles[info.index];
      setSelectedProfile(profile);
    }
  };

  const layers = useProfileMapLayers(mapData.profiles, onProfileClick);
  const mapTerm = useBusinessMappings();

  const profileType = mapData.profiles[0]?.profileType;
  const countrySelected = !!selectedCountry;

  const metricLabel =
    filters.currentFilters["KPI Type"] !== "Showing All"
      ? mapTerm(filters.currentFilters["KPI Type"])
      : "Discipled";

  return (
    <ProfileMapWrapper>
      <InteractiveMap
        title={config.title}
        loading={mapData.loading}
        layers={[layers]}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        renderTooltip={renderTooltip}
        selectedItem={selectedProfile}
        dashboardMode={dashboardMode}
        mapType={config?.mapType}
        zoomedCountry={config?.country}
      >
        <Metric
          icon={faChartBar as IconProp}
          title={`${
            !countrySelected ? "World" : selectedCountry
          }: ${metricLabel}`}
          value={mapData.filteredProfiles.length}
          secondaryValue=""
          onClose={countrySelected ? () => setSelectedCountry(null) : null}
        />
        {profileType && (
          <ProfileMapKey>
            {keyMapping[profileType].map((text: string, i: number) => (
              <React.Fragment key={text}>
                <div className="map-key-item">{text}:</div>
                <div className={`item-color ${keyColorMapping[i]}`} />
              </React.Fragment>
            ))}
          </ProfileMapKey>
        )}
      </InteractiveMap>
      <MapSidebar
        {...filters}
        dashboardMode={dashboardMode}
        toggleDashboardMode={toggleDashboardMode}
        dashboardModeCanBeEnabled={dashboardModeCanBeEnabled}
      >
        <ProfileList
          filteredProfiles={mapData.filteredProfiles}
          setSelectedProfile={setSelectedProfile}
        />
      </MapSidebar>

      {!!selectedProfile && (
        <SeekerProfile
          dashboardMode={dashboardMode}
          setDashboardMode={setDashboardMode}
          leaving={leaving}
          setLeaving={setLeaving}
          selectedProfile={selectedProfile}
          setSelectedProfile={setSelectedProfile}
        />
      )}
    </ProfileMapWrapper>
  );
};

export default ProfileMap;
