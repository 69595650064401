import React from "react";
import { SecondaryValue, TableCell } from "./styles";

interface DataTableDataCellProps {
  value: string | number;
  secondaryValue?: string | number;
}

const DataTableDataCell = ({
  value,
  secondaryValue,
}: DataTableDataCellProps) => (
  <TableCell>
    {value}
    {secondaryValue && <SecondaryValue>{secondaryValue}</SecondaryValue>}
  </TableCell>
);

export default DataTableDataCell;
