import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Control = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme?.utilities.spacing["border-radius-sm"]};
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(22, 38, 77, 0.5);
`;

const ControlItem = styled.div`
  cursor: pointer;
  padding: 0.25rem ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};

  &.disabled {
    cursor: not-allowed;
    filter: brightness(0.9);
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

const ZoomControl = ({ viewport, setViewport }) => {
  let { zoom, minZoom, maxZoom } = viewport;
  const zoomIn = () => {
    zoom++;
    if (zoom > maxZoom) {
      zoom = maxZoom;
    }
    setViewport({
      ...viewport,
      zoom,
    });
  };

  const zoomOut = () => {
    zoom--;
    if (zoom < minZoom) {
      zoom = minZoom;
    }
    setViewport({
      ...viewport,
      zoom,
    });
  };

  return (
    <Control>
      <ControlItem
        className={`control-item zoom-out ${zoom === minZoom && "disabled"}`}
        onClick={zoomOut}
      >
        <FontAwesomeIcon icon={faMinus as IconProp} />
      </ControlItem>
      <ControlItem
        className={`control-item zoom-in ${zoom === maxZoom && "disabled"}`}
        onClick={zoomIn}
      >
        <FontAwesomeIcon icon={faPlus as IconProp} />
      </ControlItem>
    </Control>
  );
};

export default ZoomControl;
