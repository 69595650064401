import { usePartner, useView } from "./hooks/globalContext";
import React, { Fragment, useEffect, useMemo } from "react";
import { useAuth } from "context/authContext";
import { Switch, Route } from "react-router-dom";

import Login from "components/login";
import { Layout } from "configDrivenComponents/layout";
import { setToken } from "services/apiService";
import Page from "configDrivenComponents/page";

const Main = React.memo(() => {
  let auth = useAuth();
  useEffect(() => {
    if (localStorage.getItem("ul-pto-user")) {
      // check localStorage version
      const localStorageVersion = localStorage.getItem(
        process.env.REACT_APP_LOCAL_STORAGE_VERSION_KEY
      );
      //see if it matches the version this build of the app uses
      if (localStorageVersion !== process.env.REACT_APP_LOCAL_STORAGE_VERSION) {
        //if not, lets clear localStorage, and update the local storage version
        localStorage.clear();
        localStorage.setItem(
          process.env.REACT_APP_LOCAL_STORAGE_VERSION_KEY,
          process.env.REACT_APP_LOCAL_STORAGE_VERSION
        );
      }
      let result = JSON.parse(localStorage.getItem("ul-pto-user"));
      setToken(result?.bearer);
      auth.setCurrentUser(result);
    }
    // eslint-disable-next-line
  }, []);
  const getPossiblyOverriddenFilters = (pageFilters, parentFilters) =>
    pageFilters ?? parentFilters ?? {};
  const { setActiveView, currentView, views } = useView();
  const { partner, fetchPartner, fetchConfigForPartner } = usePartner();
  const pages = useMemo(() => {
    return currentView?.pages?.map((pageConfig) => {
      return (
        <Route
          key={pageConfig.route}
          exact
          path={pageConfig.route}
          /**create a closure to pass in props here */
          component={() => (
            <Page
              viewFilters={currentView.filters}
              config={{
                ...pageConfig,
                filters: getPossiblyOverriddenFilters(
                  pageConfig.filters,
                  currentView.filters
                ),
                filterOptionCategories: currentView?.filterOptionCategories,
              }}
            />
          )}
        ></Route>
      );
    });
  }, [currentView]);
  useEffect(() => {
    if (auth.currentUser?.bearerData) {
      fetchPartner(auth.currentUser.bearerData?.partner_uuid || "unitelife");
    }
    //eslint-disable-next-line
  }, [auth.currentUser]);
  useEffect(() => {
    if (partner?.uuid) fetchConfigForPartner(partner.uuid);
    //eslint-disable-next-line
  }, [partner]);

  useEffect(() => {}, [partner]);
  // const location = useLocation();
  //Uncomment this line to test auth
  if (!auth.currentUser) {
    return <Login />;
  }
  return (
    <Fragment>
      {currentView && (
        <Layout
          viewConfig={currentView}
          views={views}
          onViewChange={setActiveView}
          partnerData={partner}
        >
          <Switch>{pages}</Switch>
        </Layout>
      )}
    </Fragment>
  );
});

export default Main;
