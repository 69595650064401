import { Filters } from "hooks/globalContext";
import { useEffect, useState } from "react";
import { getHostNetworkData } from "services/apiService";
import CountryService from "services/country-service";

type Hosts = {
  coords: number[];
  country: string;
};
type Seekers = {
  coords: number[];
  isConnected: boolean;
  country: string;
};

export type HostNetworkData = {
  hosts: Hosts[];
  seekers: Seekers[];
};

const useHostNetworkMapData = (
  filters: Filters,
  loadingFilters: boolean,
  startDate?: string,
  endDate?: string,
  countries?: string[],
) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<HostNetworkData>();

  const fetchMapData = async () => {
    setData(undefined);
    setLoading(true);
    //API request goes here
    let data = (await getHostNetworkData(
      startDate,
      endDate,
      {
        ...filters,
        country: countries
      }
    )) as HostNetworkData;
    let seekers = CountryService.parseLocationData(
      data.seekers,
      true
    ) as unknown as Seekers[];
    let hosts = CountryService.parseLocationData(
      data.hosts,
      true
    ) as unknown as Hosts[];
    setData({ hosts, seekers });
    setLoading(false);
  };

  useEffect(() => {
    if (
      !loadingFilters &&
      countries &&
      countries.length > 0 &&
      startDate &&
      endDate &&
      startDate < endDate
    ) {
      fetchMapData();
    }
    //eslint-disable-next-line
  }, [startDate, endDate, loadingFilters, filters, countries]);
  

  return {
    data,
    loading,
  };
};

export default useHostNetworkMapData;
